import { make } from 'src/utils/reactives.util';

import { makeBooleanVar } from '../utils/makeVar.utils';

export enum Content {
  Comments = 'Comments',
  Activity = 'Activity',
  Insights = 'Insights',
}

interface DocPanelState {
  content: Content;
  isExpanded: boolean;
  autoFocus: boolean;
  isHovered: boolean;
}

const {
  hookValue,
  getValue,
  setValue,
} = make<DocPanelState>({
  defaultState: {
    content: Content.Comments,
    isExpanded: false,
    autoFocus: true,
    isHovered: false,
  },
});

export const useGetDocPanel = hookValue;
export const getDocPanel = getValue;
export const setDocPanel = (...params: Parameters<typeof setValue>) => {
  if (getVerifyQuotesOpen() && params[0].isExpanded) {
    closeVerifyQuotes();
  }
  if (getFeatureReleaseNoteOpen() && params[0].isExpanded) {
    closeFeatureReleaseNote();
  }
  setValue(...params);
};

export const closePanel = () => setDocPanel({ isExpanded: false });

export const openCommentsPanel = () => setDocPanel({
  isExpanded: true,
  content: Content.Comments,
});

/* Opening state of the verify quotes panel in a feedback doc */
export const {
  hook: useVerifyQuotesOpen,
  get: getVerifyQuotesOpen,
  setTrue: openVerifyQuotes,
  setFalse: closeVerifyQuotes,
} = makeBooleanVar(false);

/* Opening state of the release note panel in a feature doc */
export const {
  hook: useFeatureReleaseNoteOpen,
  get: getFeatureReleaseNoteOpen,
  setTrue: openFeatureReleaseNote,
  setFalse: closeFeatureReleaseNote,
  toggle: toggleFeatureReleaseNote,
} = makeBooleanVar(false);
