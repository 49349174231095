import { StatusCategory } from '@cycle-app/graphql-codegen';

import { CommandSection } from 'src/types/commandbar.types';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

import { makeVar } from '../utils/makeVar.utils';

export interface CommandbarResult {
  section: CommandSection | null;
  boardId: string | null;
  extendTrialDays: number;
}

export const {
  hookState: useCommandbar,
  hookValue: useGetCommandbar,
  getValue: getCommandbar,
  setValue: setCommandbar,
  resetValue: resetCommandbar,
} = make<CommandbarResult>({
  defaultState: {
    section: null,
    boardId: null,
    extendTrialDays: 7,
  },
});

export type SectionFilter = 'feedback' | 'quotes' | 'features';

export const {
  hookValue: useSectionFilter,
  getValue: getSectionFilter,
  setValue: setSectionFilter,
} = make<Record<SectionFilter, boolean>>({
  localKey: LocalKey.SectionFilter,
  crossTabSync: true,
  defaultState: {
    feedback: true,
    quotes: true,
    features: true,
  },
});

export const {
  hookValue: useStatusFilter,
  getValue: getStatusFilter,
  setValue: setStatusFilter,
} = make({
  localKey: LocalKey.StatusFilter,
  crossTabSync: true,
  defaultState: {
    [StatusCategory.NotStarted]: true,
    [StatusCategory.Started]: true,
    [StatusCategory.Completed]: true,
    [StatusCategory.Canceled]: true,
  },
});

export const commandProductAreaFilter = makeVar<{ ids: string[] }>({ ids: [] });

export const {
  hookValue: useGetSearchPreview,
  getValue: getSearchPreview,
  setValue: setSearchPreview,
} = make<{
  isPreviewEnabled: boolean;
  docIdHover: string | null;
  docIdPreview: string | null;
}>({
  defaultState: {
    isPreviewEnabled: false,
    docIdHover: null,
    docIdPreview: null,
  },
});
