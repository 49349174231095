import {
  typo, Skeleton, Button, ShyScrollbar, InfiniteScroll, truncate,
  ActionButton, InsightCard,
} from '@cycle-app/ui';
import { ContextBlock, ShowMore } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { mobile } from '@cycle-app/utilities';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { CompanyLabel, Label, Trigger } from '../DocCompanyCustomer/DocCompanyCustomer.styles';

export const ButtonWrapper = styled.div`
  background-color: ${p => p.theme.colors.docPanel.bg};
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.docPanel.rightPanel.border};
`;

export const OptionContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const CustomerContainer = styled.div`
  display: flex;

  > [data-popover] {
    max-width: 100%;
    overflow: hidden;
  }

  ${Trigger} {
    padding: 0;
  }

  ${Label} {
    ${truncate}
  }

  ${CompanyLabel} {
    ${truncate}
    flex-shrink: 0;
  }
`;

export const Container = styled(ShyScrollbar)`
  max-height: 100%;
  overflow-x: hidden;

  > *:first-child {
    position: sticky;
    top: 0;
    z-index: 2;
    margin-top: 0;
  }
`;

export const QuoteListContainer = styled.div`
  ${ContextBlock} {
    background: transparent;
    padding: 0 0 0 14px;
    .ProseMirror {
      padding: 0 !important;
    }
  }

  ${ShowMore} {
    bottom: 1px;
    background-color: ${p => p.theme.colors.components.InsightCard.quote.bg};
    :before {
      box-shadow: -20px 0px 15px 0px ${p => p.theme.colors.components.InsightCard.quote.bg};
    }
  }
`;

export const ScrollableContainer = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  width: 100%;
  overflow-x: hidden;

  > div {
    border-radius: 8px;
    border: 1px solid ${p => p.theme.colors.components.InsightCard.card.border};
    box-shadow: 0px 1px 2px 0px #0000000F;

    ${mobile} {
      border-left: none;
    }
  }

  ${ContextBlock} {
    background: transparent;
    padding: 0 0 0 14px;
    .ProseMirror {
      padding: 0 !important;
    }
  }

  ${ShowMore} {
    bottom: 1px;
    background-color: ${p => p.theme.colors.components.InsightCard.quote.bg};
    :before {
      box-shadow: -20px 0px 15px 0px ${p => p.theme.colors.components.InsightCard.quote.bg};
    }
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 48px 0;
  position: relative;
`;

export const Title = styled.h4`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Paragraph = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
`;

export const TitleSkeleton = styled(Skeleton)`
  width: 92px;
  height: 20px;
  height: 16px;
  margin: 5px 0;
`;

export const ParagraphSkeleton = styled(Skeleton)`
  width: 160px;
  height: 12px;
  margin: 2px 0;
`;

type AddInsightButtonProps = { $isFullWidth: boolean; $isFocus: boolean };

export const AddInsightButton = styled(Button).attrs({
  useUserColor: false,
  variant: 'outlined',
  size: 'M',
}) <AddInsightButtonProps>`
  ${typo.body500}
  padding: 3px 12px;
  width: ${p => (p.$isFullWidth ? '100%' : 'auto')};
  margin-top: ${p => (p.$isFullWidth ? 0 : '6px')};

  background: ${p => p.theme.colors.background.primary};
  color: ${p => p.theme.colors.text.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};

  :hover, :active, :focus {
    background-color: ${p => (
    p.theme.colors.docPanel.insightButton.enableUserColor
      ? 'hsla(var(--cycle), 0.15)'
      : darken(0.1, p.theme.colors.button.ternary.bgHover))};
  }

  ${p => p.theme.isDark && `
    border-color: ${Color.Grey850};
    background: ${Color.Grey850};
  `};
`;

export const PropertiesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 12px;
`;

export const ButtonEmptyWrapper = styled.div`
  margin-top: 8px;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const Action = styled(ActionButton).attrs({ size: 'L' })<{ $isDisabled?: boolean }>`
  width: 28px;
  height: 28px;
  padding: 0;
  ${p => p.$isDisabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const AddButton = styled(Button).attrs({
  variant: 'light',
  size: 'S',
})`
  --bg: transparent;
  ${p => p.forceFocus && `
    --bg: ${p.theme.colors.button.light.bgHover};
    --color: ${p.theme.colors.text.primary};
  `}
`;

export const StyledInsightCard = styled(InsightCard)<{ $isDisabled?: boolean }>`
  ${p => p.$isDisabled && css`
    cursor: default !important;
    .ProseMirror * {
      cursor: default;
    }
  `}
`;

export const FeatureQuoteCard = styled(InsightCard)<{ $isDisabled?: boolean }>`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.components.InsightCard.card.border};
  box-shadow: 0px 1px 2px 0px #0000000F;

  ${p => p.$isDisabled && css`
    cursor: default !important;
    .ProseMirror * {
      cursor: default;
    }
  `}
`;
