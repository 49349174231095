import { MutationOptions } from '@apollo/client';
import {
  FilterPropertyRuleFragment,
  PropertyFragment,
  PublishedBoardConfigFullFragment,
  OperatorIsEmptyOrNot,
  OperatorIsInOrNot,
  SwimlaneFilterPropertyRuleFragment,
  DraftBoardConfigFragment,
  FilterPropertyRuleEdgesFragment,
  FilterPropertyRuleDoctypeFragment,
  OperatorAiState,
  namedOperations,
  BoardWithFullConfigFragmentDoc,
} from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import {
  CheckboxIcon,
  CalendarIcon,
  EnvelopeIcon,
  SingleSelectIcon,
  ChecklistIcon,
  IdIcon,
  PhoneIcon,
  LetterAIcon,
  LinkIcon,
  GeneralIcon,
  ParentIcon,
  StatusIcon,
  AiIcon,
  ArrowRightDownIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import React, { ReactNode } from 'react';
import { hasValueAtKey } from 'ts-is-present';

import client from 'src/services/apollo/client';
import { FilterRule } from 'src/types/filters.types';
import { ATTRIBUTE_ICON_MAPPING } from 'src/utils/attributes.util';
import { getParams } from 'src/utils/routing.utils';
import { assigneeLabel } from 'src/utils/users.util';

import { isBuiltIn, isInsight } from '../docType.util';
import { CompanyFilterIcon } from './boardConfig.util.styles';

export const ASSIGNEE_LABEL = 'Assignee';
export const CREATOR_LABEL = 'Creator';
export const PARENT_LABEL = 'Parent';
export const DOCTYPE_LABEL = 'Feature type';
export const CLAP_LABEL = 'Claps';
export const COVER_LABEL = 'Cover';
export const DOCID_LABEL = 'ID';
export const DOCTITLE_LABEL = 'Title';
export const CREATEDAT_LABEL = 'Creation date';
export const CHILDREN_LABEL = 'Children';
export const CUSTOMER = 'Customer';
export const STATUS = 'Status';
export const INSIGHT = 'Insight';
export const COMPANY = 'Company';
export const SOURCE = 'Source';
export const COMMENT = 'Comment';
export const RELEASE = 'Release';
export const LINEAR = 'Linear';
export const AISTATE = 'AI tag';
export const PRODUCT_AREA = 'Product Area';

// TODO: remove reference to claps once backend is ready
export const FILTER_RULE_ICON_MAPPING: Record<FilterRule['__typename'], ReactNode> = {
  FilterPropertyRuleDoctype: <GeneralIcon />,
  FilterPropertyRuleCreator: <Icon name="user" />,
  SwimlaneByFilterPropertyRuleCreator: <Icon name="user" />,
  FilterPropertyRuleAssignee: <Icon name="user" />,
  SwimlaneByFilterPropertyRuleAssignee: <Icon name="user" />,
  FilterPropertyRuleCheckbox: <CheckboxIcon />,
  SwimlaneByFilterPropertyRuleCheckbox: <CheckboxIcon />,
  FilterPropertyRuleDate: <CalendarIcon />,
  SwimlaneByFilterPropertyRuleDate: <CalendarIcon />,
  FilterPropertyRuleDocParent: <ParentIcon />,
  SwimlaneByFilterPropertyRuleDocParent: <ParentIcon />,
  FilterPropertyRuleEmail: <EnvelopeIcon />,
  SwimlaneByFilterPropertyRuleEmail: <EnvelopeIcon />,
  FilterPropertyRuleSingleSelect: <SingleSelectIcon />,
  SwimlaneByFilterPropertyRuleSingleSelect: <SingleSelectIcon />,
  FilterPropertyRuleMultiSelect: <ChecklistIcon />,
  SwimlaneByFilterPropertyRuleMultiSelect: <ChecklistIcon />,
  FilterPropertyRuleNumber: <IdIcon />,
  SwimlaneByFilterPropertyRuleNumber: <IdIcon />,
  FilterPropertyRulePhone: <PhoneIcon />,
  SwimlaneByFilterPropertyRulePhone: <PhoneIcon />,
  FilterPropertyRuleText: <LetterAIcon />,
  SwimlaneByFilterPropertyRuleText: <LetterAIcon />,
  FilterPropertyRuleUrl: <LinkIcon />,
  SwimlaneByFilterPropertyRuleUrl: <LinkIcon />,
  SwimlaneByFilterPropertyRuleDocChildren: null,
  FilterPropertyRuleCustomer: <Icon name="user" />,
  FilterPropertyRuleCompany: <CompanyFilterIcon />,
  FilterPropertyRuleStatus: <StatusIcon />,
  FilterPropertyRuleAiState: <AiIcon />,
  SwimlaneByFilterPropertyRuleAiState: <AiIcon />,
  FilterPropertyRuleSource: <ArrowRightDownIcon />,
  SwimlaneByFilterPropertyRuleSource: <ArrowRightDownIcon />,
  FilterPropertyRuleProductArea: <Icon name="tri-shapes" />,
  SwimlaneByFilterPropertyRuleProductArea: <Icon name="tri-shapes" />,
};

export function getFilterRuleName(rule: FilterPropertyRuleFragment | SwimlaneFilterPropertyRuleFragment, isFeedback = false): string {
  switch (rule.__typename) {
    case 'FilterPropertyRuleCustomer':
      return CUSTOMER;

    case 'FilterPropertyRuleCompany':
      return COMPANY;

    case 'FilterPropertyRuleAssignee':
    case 'SwimlaneByFilterPropertyRuleAssignee': {
      return assigneeLabel({
        isFeedback,
        uppercase: true,
      });
    }

    case 'FilterPropertyRuleCreator':
    case 'SwimlaneByFilterPropertyRuleCreator':
      return CREATOR_LABEL;

    case 'FilterPropertyRuleDocParent':
    case 'SwimlaneByFilterPropertyRuleDocParent':
      return PARENT_LABEL;

    case 'FilterPropertyRuleStatus':
      return STATUS;

    case 'FilterPropertyRuleAiState':
    case 'SwimlaneByFilterPropertyRuleAiState':
      return AISTATE;

    case 'FilterPropertyRuleSource':
    case 'SwimlaneByFilterPropertyRuleSource':
      return SOURCE;

    case 'SwimlaneByFilterPropertyRuleProductArea':
      return PRODUCT_AREA;
    case 'FilterPropertyRuleProductArea':
      return rule.attribute.name;

    case 'FilterPropertyRuleUrl':
    case 'SwimlaneByFilterPropertyRuleUrl':
    case 'FilterPropertyRuleText':
    case 'SwimlaneByFilterPropertyRuleText':
    case 'FilterPropertyRuleNumber':
    case 'SwimlaneByFilterPropertyRuleNumber':
    case 'FilterPropertyRuleDate':
    case 'SwimlaneByFilterPropertyRuleDate':
    case 'FilterPropertyRuleEmail':
    case 'SwimlaneByFilterPropertyRuleEmail':
    case 'FilterPropertyRulePhone':
    case 'SwimlaneByFilterPropertyRulePhone':
    case 'FilterPropertyRuleCheckbox':
    case 'SwimlaneByFilterPropertyRuleCheckbox':
    case 'FilterPropertyRuleSingleSelect':
    case 'SwimlaneByFilterPropertyRuleSingleSelect':
    case 'FilterPropertyRuleMultiSelect':
    case 'SwimlaneByFilterPropertyRuleMultiSelect':
      return rule.attribute.name;

    default:
      return '';
  }
}

export function getAttributeOptionLabel(property: PropertyFragment, isFeedback = false): string {
  if (property.__typename === 'AssigneeDefinition') {
    return assigneeLabel({
      isFeedback,
      uppercase: true,
    });
  }
  if (property.__typename === 'CreatorDefinition') {
    return CREATOR_LABEL;
  }
  if (property.__typename === 'DoctypeDefinition') {
    return DOCTYPE_LABEL;
  }
  if (property.__typename === 'ClapDefinition') {
    return CLAP_LABEL;
  }
  if (property.__typename === 'CoverDefinition') {
    return COVER_LABEL;
  }
  if (property.__typename === 'CreatedatDefinition') {
    return CREATEDAT_LABEL;
  }
  if (property.__typename === 'DocidDefinition') {
    return DOCID_LABEL;
  }
  if (property.__typename === 'DoctitleDefinition') {
    return DOCTITLE_LABEL;
  }
  if (property.__typename === 'ParentDefinition') {
    return PARENT_LABEL;
  }
  if (property.__typename === 'ChildrenDefinition') {
    return CHILDREN_LABEL;
  }
  if (property.__typename === 'BuiltInCustomerDefinition') {
    return CUSTOMER;
  }
  if (property.__typename === 'SourceDefinition') {
    return SOURCE;
  }
  if (property.__typename === 'CommentDefinition') {
    return COMMENT;
  }
  if (property.__typename === 'BuiltInCompanyDefinition') {
    return COMPANY;
  }
  if (property.__typename === 'StatusDefinition') {
    return STATUS;
  }
  if (property.__typename === 'BuiltInInsightDefinition') {
    return INSIGHT;
  }
  if (property.__typename === 'BuiltInReleaseDefinition') {
    return RELEASE;
  }
  if (property.__typename === 'LinearAutomationDefinition') {
    return LINEAR;
  }
  if (property.__typename === 'BuiltInAiStateDefinition') {
    return AISTATE;
  }
  return property.name;
}

export const getFilterAttributeOption = (property: PropertyFragment, isFeedback = false) => ({
  value: property.id,
  label: getAttributeOptionLabel(property, isFeedback),
  icon: ATTRIBUTE_ICON_MAPPING[property.__typename],
});

type HasFilterPreventingNewDoc = (boardConfig: PublishedBoardConfigFullFragment) => boolean;

// The default values for the other cases are set in useAttributesFromBoardConfig.ts
export const getHasFilterPreventingNewDoc: HasFilterPreventingNewDoc = (boardConfig) => boardConfig?.filterProperties.edges
  .some(filterProperty => {
    // Text
    if (filterProperty.node.__typename === 'FilterPropertyRuleText') {
      const { textRule } = filterProperty.node;
      if (textRule.__typename === 'RuleIsEmptyOrNot' && textRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
        return true;
      }
    }
    // Email
    if (filterProperty.node.__typename === 'FilterPropertyRuleEmail') {
      const { emailRule } = filterProperty.node;
      if (emailRule.__typename === 'RuleIsEmptyOrNot' && emailRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
        return true;
      }
    }
    // Phone
    if (filterProperty.node.__typename === 'FilterPropertyRulePhone') {
      const { phoneRule } = filterProperty.node;
      if (phoneRule.__typename === 'RuleIsEmptyOrNot' && phoneRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
        return true;
      }
    }
    // Url
    if (filterProperty.node.__typename === 'FilterPropertyRuleUrl') {
      const { urlRule } = filterProperty.node;
      if (urlRule.__typename === 'RuleIsEmptyOrNot' && urlRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
        return true;
      }
    }
    // DocParents
    if (filterProperty.node.__typename === 'FilterPropertyRuleDocParent') {
      const { docParentRule } = filterProperty.node;
      if (docParentRule.__typename === 'RuleIsEmptyOrNot' && docParentRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
        return true;
      }
      if (docParentRule.__typename === 'RuleDocParentMultipleValues') {
        const {
          operator, selectedValues,
        } = docParentRule;
        if (operator === OperatorIsInOrNot.Is && nodeToArray(selectedValues).length > 1) {
          return false;
        }
      }
    }
    // Companies
    if (filterProperty.node.__typename === 'FilterPropertyRuleCompany') {
      const { companyRule } = filterProperty.node;
      if (companyRule.__typename === 'RuleCompanyMultipleValues' && (
        companyRule.operator === OperatorIsInOrNot.IsNot ||
        // At least one company
        nodeToArray(companyRule.selectedValues).length
      )) {
        return true;
      }
    }
    // Customers
    if (filterProperty.node.__typename === 'FilterPropertyRuleCustomer') {
      const { customerRule } = filterProperty.node;
      if (customerRule.__typename === 'RuleIsEmptyOrNot' && customerRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
        return true;
      }
      if (customerRule.__typename === 'RuleCustomerMultipleValues' && (
        customerRule.operator === OperatorIsInOrNot.IsNot ||
        nodeToArray(customerRule.selectedValues).length > 1
      )) {
        return true;
      }
      if (
        customerRule.__typename === 'RuleIsEmptyOrNot' && customerRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty &&
        boardConfig.filterProperties.edges.find(({ node }) => (
          node.__typename === 'FilterPropertyRuleDoctype' &&
          nodeToArray(node.doctypeRule.values).find(({ value }) => isBuiltIn(value))))
      ) {
        return true;
      }
    }
    // AI state
    if (filterProperty.node.__typename === 'FilterPropertyRuleAiState') {
      return [
        OperatorAiState.IsAiCreated,
        OperatorAiState.IsUserValidated,
      ].includes(filterProperty.node.aiStateOperator);
    }
    return false;
  });

export function getBoardConfigGroupBy(boardConfig: PublishedBoardConfigFullFragment | DraftBoardConfigFragment) {
  return boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' || boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
    ? boardConfig.docQuery.groupbyConfig
    : null;
}

type FilterPropertyRuleEdge = FilterPropertyRuleEdgesFragment['edges'][0];

type IsFilterDoctypePropertyRuleReturn = FilterPropertyRuleEdge & { node: FilterPropertyRuleDoctypeFragment };

export function isFilterDoctypePropertyRule(edge: FilterPropertyRuleEdge): edge is IsFilterDoctypePropertyRuleReturn {
  return edge.node.__typename === 'FilterPropertyRuleDoctype';
}

export function isFilterDoctypePropertyRuleByInsight(filterProperties?: FilterPropertyRuleEdgesFragment) {
  const filterDoctypePropertyRule = filterProperties?.edges.find(isFilterDoctypePropertyRule)?.node;
  if (filterDoctypePropertyRule) {
    const values = nodeToArray(filterDoctypePropertyRule.doctypeRule.values);
    const isInsightSelected = !!values.find(({
      selected, value,
    }) => selected && isInsight(value));
    const isAllUnselected = !values.find(({ selected }) => selected);
    return {
      isInsightSelected: isInsightSelected || isAllUnselected,
      isOnlyInsightSelected: isInsightSelected && values.filter(v => v.selected).length === 1,
    };
  }
  return null;
}

export function findFilterDoctypePropertyRule(boardConfig: PublishedBoardConfigFullFragment | DraftBoardConfigFragment) {
  return boardConfig?.filterProperties.edges.find(isFilterDoctypePropertyRule)?.node;
}

export const getOptimisticDraftBoardConfig = (boardConfig: PublishedBoardConfigFullFragment | null): DraftBoardConfigFragment | null => {
  if (!boardConfig) return null;
  return {
    filterableProperties: {
      __typename: 'PropertiesConnection',
      edges: [],
    },
    groupableProperties: {
      __typename: 'PropertiesConnection',
      edges: [],
    },
    availableSwimlaneByDoctypes: null,
    ...boardConfig,
    id: 'temp-drafBoardConfigId',
  };
};

export const refetchBoardWithConfigQuery: MutationOptions['refetchQueries'] = () => {
  if (!getParams().boardId) return [];
  return [namedOperations.Query.boardWithConfig];
};

export const getPublishedBoardConfig = (boardId: string | undefined) => {
  if (!boardId) return undefined;
  const boardConfig = client.cache.readFragment({
    fragment: BoardWithFullConfigFragmentDoc,
    fragmentName: 'BoardWithFullConfig',
    id: boardId,
  });
  return boardConfig?.publishedBoardConfig;
};

export const getFilterPropertyRuleAiState = (boardConfig: PublishedBoardConfigFullFragment | DraftBoardConfigFragment) => {
  return nodeToArray(boardConfig?.filterProperties)
    .find(hasValueAtKey('__typename', 'FilterPropertyRuleAiState' as const));
};

export const getDocGroup = (docId: string, boardConfig: PublishedBoardConfigFullFragment) => {
  if (boardConfig.docQuery.__typename !== 'BoardQueryWithGroupBy') return;
  const groups = boardConfig.docQuery.docGroups.edges;
  return groups.find(groupEdge => groupEdge.node.docs.edges.some(docEdge => docEdge.node.id === docId));
};