import { ForgotPasswordDocument, UserJourney } from '@cycle-app/graphql-codegen';
import { Button, Input, Spinner, Icon } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { emailRegex, ERROR_CODE } from '@cycle-app/utilities';
import { delay } from '@cycle-app/utilities/src/utils/async.utils';
import { useState, useEffect } from 'react';
import { UnpackNestedValue, useForm, UseFormRegisterReturn } from 'react-hook-form';

import { ErrorMessage } from 'src/constants/errors.constants';
import { PageId, routing } from 'src/constants/routing.constant';
import { GOOGLE_SSO_URL } from 'src/constants/sso.constants';
import { navigate, useConfirmEmail, useLocationSelector, useVerifyEmail } from 'src/hooks';
import { useLogin } from 'src/hooks/user/useLogin';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setAuth } from 'src/reactives/auth.reactive';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { getThemeConfig } from 'src/reactives/theme.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { isInIframe } from 'src/utils/iframe';
import { defaultPagination } from 'src/utils/pagination.util';
import { getSearchParams } from 'src/utils/routing.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { AuthLayout } from '../AuthLayout';
import {
  Form,
  Actions,
  ResetPwdHeading,
  ResetPwdHint,
  ResetPwdActions,
  TextButtonStyled,
  Footer,
  Anchor,
  Heading,
  SigInChoice,
  ButtonOutline,
  BackButton,
  Hr,
  CodeButton,
  Title,
  ConfirmDescription,
  CodeInputContainer,
  StyledCodeInput,
  ConfirmFooter,
  ConfirmError,
  VerifySpinner,
  NospaceButton,
} from './Login.styles';

interface LoginFormData {
  email: string;
  password: string;
}

interface ResetPasswordFormData {
  email: string;
}

interface OneTimeCodeFormData {
  email: string;
}

type LoginContent = 'signIn' | 'resetPwd' | 'resetPwdSuccessfull' | 'emailSignIn' | 'oneTimeCodeVerify' | 'oneTimeCodeConfirm';

const emailValidationOptions = {
  required: 'Email is required',
  pattern: {
    value: emailRegex,
    message: 'Email format is incorrect',
  },
};

const Login = () => {
  const {
    login,
    isLoading: loginLoading,
  } = useLogin();
  const [forgotPassword, { loading: forgotPwdLoading }] = useSafeMutation(ForgotPasswordDocument);

  const {
    verifyEmail, isLoading: isVerifyLoading,
  } = useVerifyEmail();

  const {
    confirmEmail, isLoading: isConfirmLoading,
  } = useConfirmEmail();

  const [confirmError, setConfirmError] = useState<string | null>(null);

  const {
    handleSubmit: handleLoginFormSubmit,
    register: loginRegister,
    formState: loginFormState,
    setError: setLoginError,
    watch: watchLogin,
  } = useForm<LoginFormData>();

  const {
    handleSubmit: handleResetPwdSubmit,
    register: resetPwdRegister,
    formState: resetPwdFormState,
    reset: resetForgotPwdForm,
  } = useForm<ResetPasswordFormData>();

  const {
    handleSubmit: handleOneTimeCodeSubmit,
    register: oneTimeCodeRegister,
    formState: oneTimeCodeState,
    watch: watchOneTimeCode,
    getValues: getOneTimeCodeValues,
  } = useForm<OneTimeCodeFormData>({
    values: {
      email: watchLogin('email'),
    },
  });

  const isFromNewDocPage = useLocationSelector(() => getSearchParams().get('from') === routing[PageId.NewDoc]);
  const initialContent = isFromNewDocPage ? 'emailSignIn' : 'signIn';
  const [content, setContent] = useState<LoginContent>(isFromNewDocPage ? 'emailSignIn' : initialContent);

  useEffect(() => {
    if (content !== 'resetPwd') {
      resetForgotPwdForm();
    }
  }, [content]);

  return (
    <AuthLayout>
      <div>
        {content === 'signIn' && renderSignIn()}
        {content === 'emailSignIn' && renderEmailSignIn()}
        {content === 'oneTimeCodeVerify' && renderOneTimeCodeVerify()}
        {content === 'oneTimeCodeConfirm' && renderOneTimeCodeConfirm()}
        {content === 'resetPwd' && renderResetPwdForm()}
        {content === 'resetPwdSuccessfull' && renderResetPwdSent()}
      </div>

      {isFromNewDocPage && content !== 'emailSignIn' && (
        <BackButton
          iconStart={<CaretIcon direction="left" />}
          onClick={() => setContent('emailSignIn')}
        >
          Back
        </BackButton>
      )}
    </AuthLayout>
  );

  function renderEmailSignIn() {
    return (
      <>
        {isFromNewDocPage && (<Title>Log in</Title>)}

        <Form onSubmit={handleLoginFormSubmit(onSubmitLogin)}>
          {renderEmailInput({
            error: loginFormState.errors.email?.message,
            registerProps: loginRegister('email', emailValidationOptions),
          })}

          <Input
            id="password"
            label="Password"
            type="password"
            placeholder="Type your password"
            error={loginFormState.errors.password?.message}
            autoComplete="current-password"
            helperSize="S"
            {...loginRegister('password', { required: 'Password is required' })}
          />

          <Actions>
            <TextButtonStyled
              type="button"
              size="M"
              onClick={() => setContent('resetPwd')}
              variant="nospace"
            >
              Forgot password ?
            </TextButtonStyled>

            <Button
              type="submit"
              size="M"
              isLoading={loginLoading}
            >
              Sign in
            </Button>
          </Actions>

          {!isFromNewDocPage && (
            <Button
              onClick={() => setContent(initialContent)}
              variant="nospace"
              style={{ margin: '24px auto 0' }}
            >
              Back to login
            </Button>
          )}

          {isFromNewDocPage && (
            <>
              <Hr />
              <CodeButton
                onClick={() => setContent('oneTimeCodeVerify')}
              >
                Receive a one-time code to login
              </CodeButton>
            </>
          )}
        </Form>
      </>
    );
  }

  function renderOneTimeCodeVerify() {
    return (
      <>
        <Title>Log in</Title>
        <Form onSubmit={handleOneTimeCodeSubmit(onSubmitOneTimeCodeVerify)}>
          {renderEmailInput({
            error: oneTimeCodeState.errors.email?.message,
            registerProps: oneTimeCodeRegister('email', emailValidationOptions),
          })}

          <Actions style={{ justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              size="M"
              isLoading={loginLoading}
            >
              Send
            </Button>
          </Actions>
        </Form>
      </>
    );
  }

  function renderOneTimeCodeConfirm() {
    const email = watchOneTimeCode('email');
    return (
      <>
        <Title>Log in</Title>
        <ConfirmDescription>
          {`We've sent an email with a code to ${email}, please enter it below to create your Cycle account.`}
        </ConfirmDescription>
        {confirmError && <ConfirmError>{confirmError}</ConfirmError>}
        <CodeInputContainer>
          <StyledCodeInput
            autoFocus
            disabled={isConfirmLoading}
            onChange={onCodeChange}
          />
          {isConfirmLoading && <Spinner />}
        </CodeInputContainer>
        <ConfirmFooter>
          {'Didn\'t receive a code? '}
          <NospaceButton
            size="M"
            variant="nospace"
            onClick={async () => {
              if (isVerifyLoading) return;
              const verify = await verifyEmail(email);
              if (verify.data?.verifyEmail) {
                addToaster({
                  message: `We've sent an email with a code to ${email}`,
                });
              }
            }}
          >
            Send new code
            {isVerifyLoading && <VerifySpinner />}
          </NospaceButton>
        </ConfirmFooter>
      </>
    );
  }

  function renderSignIn() {
    return (
      <>
        <Heading>Log in</Heading>
        <SigInChoice>
          {!isInIframe() && (
            <ButtonOutline
              size="L"
              variant="outlined-alt"
              onClick={() => {
                window.location.href = GOOGLE_SSO_URL;
              }}
            >
              <Icon name="brand/google" className="size-4" />
              Continue with Google
            </ButtonOutline>
          )}
          <Button
            type="submit"
            size="L"
            onClick={() => setContent('emailSignIn')}
          >
            Continue with work email
          </Button>
          <Footer>
            Don’t have an account ?
            {' '}
            <Anchor
              onClick={() => setOnboarding({ theme: getThemeConfig().colorTheme })}
              to={routing[PageId.GetStarted]}
            >
              Join Cycle now
            </Anchor>
          </Footer>
        </SigInChoice>
      </>
    );
  }

  function renderResetPwdForm() {
    return (
      <>
        <ResetPwdHeading>Reset password</ResetPwdHeading>
        <ResetPwdHint>Please enter your email address and we will send you a password reset link.</ResetPwdHint>
        <Form onSubmit={handleResetPwdSubmit(onSubmitResetPwd)}>
          {renderEmailInput({
            error: resetPwdFormState.errors.email?.message,
            registerProps: resetPwdRegister('email', emailValidationOptions),
          })}

          <ResetPwdActions>
            <Button
              type="button"
              size="M"
              variant="secondary"
              onClick={() => setContent(initialContent)}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              size="M"
              isLoading={forgotPwdLoading}
            >
              Reset password
            </Button>
          </ResetPwdActions>
        </Form>
      </>
    );
  }

  function renderResetPwdSent() {
    return (
      <>
        <ResetPwdHeading>Reset password</ResetPwdHeading>
        <ResetPwdHint>
          Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
        </ResetPwdHint>
        <ResetPwdActions>
          <Button
            type="submit"
            size="M"
            onClick={() => setContent(initialContent)}
          >
            Sign in
          </Button>
        </ResetPwdActions>
      </>
    );
  }

  function renderEmailInput({
    error, registerProps,
  }: { error: string | undefined; registerProps: UseFormRegisterReturn }) {
    return (
      <Input
        autoFocus
        id="email"
        label="Email"
        type="text"
        placeholder="Type your email"
        error={error}
        autoComplete="username"
        helperSize="S"
        {...registerProps}
      />
    );
  }

  async function onSubmitLogin(formData: UnpackNestedValue<LoginFormData>) {
    const {
      data, errors,
    } = await login({
      email: formData.email,
      password: formData.password,
      ...defaultPagination,
    });

    if (!data?.login) {
      if (errors?.find(error => error.message === ERROR_CODE.WRONG_CREDENTIALS)) {
        setLoginError('password', {
          message: 'The email or password you entered is incorrect. Please try again.',
        }, {
          shouldFocus: true,
        });
      }
      return;
    }

    if (import.meta.env.VITE_QUERIES_MUTATIONS_OVER_WEBSOCKET === 'on') {
      // TODO: find a better way to handle this
      await delay(100);
    }

    setAuth({
      token: data.login.token,
      userId: data.login.me.id,
    });
  }

  async function onSubmitResetPwd(formData: UnpackNestedValue<ResetPasswordFormData>) {
    const { data } = await forgotPassword({
      variables: {
        email: formData.email,
      },
    });

    if (data?.forgotPassword) {
      setContent('resetPwdSuccessfull');
    }
  }

  async function onSubmitOneTimeCodeVerify(formData: UnpackNestedValue<OneTimeCodeFormData>) {
    if (isVerifyLoading) return;
    const result = await verifyEmail(formData.email);
    if (!result.data?.verifyEmail) return;
    setContent('oneTimeCodeConfirm');
    addToaster({ message: `We've sent an email with a code to ${formData.email}` });
  }

  async function onCodeChange(code: string) {
    setConfirmError(null);
    const email = getOneTimeCodeValues('email');
    const result = await confirmEmail(email, code);

    if (result.errors) {
      setConfirmError(
        result.errors.find(err => err.message === ERROR_CODE.WRONG_CODE)
          ? 'Looks like you entered the wrong code, please try again.'
          : ErrorMessage._GENERIC,
      );
      return;
    }

    if (!result.data?.confirmEmail) return;

    const {
      me, token,
    } = result.data.confirmEmail;

    if (me.userJourney !== UserJourney.Done) {
      setOnboarding({
        screen: LightOnboardingScreen.AccountInfosPasswordless,
      });
      navigate(PageId.GetStarted, undefined, {
        logoutRedirectPageId: PageId.NewDoc,
      });
    }

    setAuth({
      token,
      userId: me.id,
    });
  }
};

export default Login;
