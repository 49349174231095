import { AiState, OperatorAiState, UpdateDocAiStateDocument } from '@cycle-app/graphql-codegen';

import { useRemoveDocFromBoardDocsList } from 'src/hooks/api/cache/cacheBoardDocsList';
import { useUpdateDocsGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { clearSelection } from 'src/reactives/selection.reactive';
import { getDocGroup, getFilterPropertyRuleAiState, getPublishedBoardConfig } from 'src/utils/boardConfig/boardConfig.util';
import { getPathParams } from 'src/utils/routing.utils';


type Options = {
  optimistic?: boolean;
};

export const useUpdateDocAiState = ({ optimistic }: Options = { optimistic: true }) => {
  const [mutate] = useSafeMutation(UpdateDocAiStateDocument);
  const removeVerifiedQuoteFromView = useRemoveVerifiedQuoteFromView();

  const updateDocAiState = (docId: string) => mutate({
    variables: {
      docId,
      aiState: AiState.UserValidated,
    },
    ...optimistic && {
      optimisticResponse: {
        updateDocAiState: {
          id: docId,
          aiState: AiState.UserValidated,
        },
      },
    },
    update: (_, { data }) => {
      removeVerifiedQuoteFromView(data?.updateDocAiState?.id);
    },
  });

  return {
    updateDocAiState,
  };
};

const useRemoveVerifiedQuoteFromView = () => {
  const removeDocFromBoard = useRemoveDocFromBoardDocsList();
  const updateDocsGroup = useUpdateDocsGroup();
  
  return (docId: string | undefined) => {
    if (!docId) return;
    const { boardId } = getPathParams(location.pathname);
    const boardConfig = getPublishedBoardConfig(boardId);
    if (!boardConfig) return;

    // Remove the quote from the view if it is filtered by "AI generated, not verified"
    const filterProperty = getFilterPropertyRuleAiState(boardConfig);
    if (filterProperty?.aiStateOperator !== OperatorAiState.IsAiCreated) return;
              
    if (boardConfig.docQuery.__typename === 'BoardQuery') {
      removeDocFromBoard(boardConfig.id, docId);
    }

    if (boardConfig.docQuery.__typename === 'BoardQueryWithGroupBy') {
      const group = getDocGroup(docId, boardConfig);
      if (!group) return;
      updateDocsGroup({
        groupData: group,
        updatedDocs: group.node.docs.edges
          .filter(edge => docId !== edge.node.id)
          .map(edge => edge.node),
        boardConfigId: boardConfig.id,
      });
    }
  };
};

export const useRemoveVerifiedQuotesFromView = () => {
  const removeQuote = useRemoveVerifiedQuoteFromView();

  return (docIds: string[]) => {
    const { boardId } = getPathParams(location.pathname);
    const boardConfig = getPublishedBoardConfig(boardId);
    if (!boardConfig) return;

    const filterProperty = getFilterPropertyRuleAiState(boardConfig);
    if (filterProperty?.aiStateOperator !== OperatorAiState.IsAiCreated) return;

    clearSelection();
    
    for (const docId of docIds) {
      removeQuote(docId);
    }
  };
};
