import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;

  /** Fix the inner div size but avoid targeting the tippy div */
  > div:not([data-tippy-root]) {
    width: 14px;
    height: 14px;
  }
`;
