import { Shortcut } from '@cycle-app/ui';
import { RightIcon, DoubleCaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { StyledButton } from 'src/components/ViewHeader/ViewHeader.styles';

export const Container = styled.div`
  flex: 1;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: 4px;
  color: ${p => p.theme.colors.text.secondary};
  svg {
    flex: none;
  }
`;

export const InlineShortcut = styled(Shortcut)`
  display: inline-flex;
  margin: 0 4px;
`;

export const Caret = styled(RightIcon).attrs({ size: 12 })`
  color: ${p => p.theme.colors.text.disabled};
`;

export const DoubleCaret = styled(DoubleCaretIcon).attrs({ size: 12 })`
  color: ${p => p.theme.colors.text.secondary};
`;

export const SectionButton = styled(StyledButton)<{ $isSmall?: boolean }>`
  padding-right: 4px;
  ${p => p.$isSmall && `
    padding: 0;
  `}
`;

export const ViewButton = styled(SectionButton)`
  color: ${p => p.theme.colors.text.primary};
  overflow: hidden;
  > span:not([role="img"]) {
    overflow: hidden;
  }
`;
