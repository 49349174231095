import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProduct } from './useProduct';

export const useAttributes = () => {
  const { product } = useProduct();

  return useMemo(
    () => [...nodeToArray(product?.attributeDefinitions)],
    [product?.attributeDefinitions],
  );
};

export const useBuiltIntSpecificAttributes = () => {
  const { product } = useProduct();
  const builtIntSpecificAttributes = useMemo(
    () => nodeToArray(product?.builtInSpecificAttributeDefinitions),
    [product],
  );
  const customerAttribute = useMemo(() => builtIntSpecificAttributes.find(attribute => (
    attribute.__typename === 'BuiltInCustomerDefinition')), [builtIntSpecificAttributes]);
  return {
    builtIntSpecificAttributes,
    customerAttribute,
  };
};

export const useBuiltinAttributes = () => {
  const { product } = useProduct();

  return useMemo(
    () => nodeToArray(product?.builtInAttributeDefinitions),
    [product?.builtInAttributeDefinitions],
  );
};

export const useProductAreaAttribute = () => {
  const builtInAttributes = useBuiltinAttributes();
  const attribute = builtInAttributes?.find(a => a.__typename === 'ProductAreaDefinition');
  const categoryAttribute = builtInAttributes?.find(a => a.__typename === 'ProductAreaCategoryDefinition');
  const attributeName = attribute?.name || 'Product area';
  return {
    attributeName,
    attributeNamePlural: attributeName.endsWith('s') ? attributeName : `${attributeName}s`,
    attribute,
    categoryAttribute,
    categoryName: categoryAttribute?.name || 'Product area category',
  };
};
