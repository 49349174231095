import { DocAttributes } from 'src/components/DocAttributes';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';

export const QuoteProperties = ({ docId }: { docId: string }) => {
  const { doc } = useDocBase(docId);
  const count = doc ? doc.attributes.edges.length + doc.productAreas.edges.length : 0;
  if (!doc || count === 0) return null;
  return (
    <DocAttributes
      doc={doc}
      layer={Layer.DropdownModalZ3}
      showDocId={false}
      showDocType={false}
      showStatus={false}
      showLinear={false}
      showAiState={false}
      showRelease={false}
      context="doc-panel"
    />
  );
};
