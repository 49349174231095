import { Icon, Input, Tooltip, Badge } from '@cycle-app/ui';
import { formatCount } from '@cycle-app/utilities';
import { Tabs as ReachTabs, TabList, Tab } from '@reach/tabs';
import { useAtomValue, useSetAtom, useAtom } from 'jotai';
import { twJoin } from 'tailwind-merge';

import { CreateDocModalWithBoardConfig } from 'src/app/Main/Board/CreateDocModal';
import { CompanyAddModal } from 'src/components/CustomersList/CompanyAddModal';
import { CustomersAddCustomerModal } from 'src/components/CustomersList/CustomersAddCustomerModal';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCompaniesCount } from 'src/hooks/api/queries/customers/useCompaniesCount';
import { useCustomersCount } from 'src/hooks/api/queries/customers/useCustomersCount';

import { inputAtom, modalAtom, searchAtom, tabAtom } from './customers.atoms';
import { TableCompanies } from './TableCompanies';
import { TablePeople } from './TablePeople';

export const CustomersPage = () => (
  <>
    <div className="relative flex h-full flex-col overflow-hidden bg-white dark:bg-black">
      <div className="flex items-center justify-between px-6 py-4">
        <h1 className="text-header-small font-medium">
          Customers
        </h1>
        <div className="items flex gap-2"> 
          <Search />
          <Add />
        </div>
      </div>
      <Tabs />
      <Table />
    </div>
    <NewModal />
    <CreateDocModalWithBoardConfig />
  </>
);

const Search = () => {
  const currentValue = useAtomValue(inputAtom);
  const setDebouncedValue = useSetAtom(searchAtom);
  return (
    <Input
      inputSize="S"
      iconBefore={<Icon name="search" />}
      placeholder="Search…"
      value={currentValue}
      onChange={e => setDebouncedValue(e.target.value)}
    />
  );
};

const Add = () => {
  const tab = useAtomValue(tabAtom);
  const setModal = useSetAtom(modalAtom);
  return (
    <Tooltip
      withWrapper={false}
      placement="bottom"
      content={tab === 'people' ? 'New customer' : 'New company'}
    >
      <button
        className="btn-tertiary btn-square text-disabled hover:text-secondary"
        onClick={() => setModal(tab === 'people' ? 'customer' : 'company')}
      >
        <Icon name="plus" className="size-[22px]" />
      </button>
    </Tooltip>
  );
};

const Tabs = ({ className }: { className?: string }) => {
  const [tab, setTab] = useAtom(tabAtom);
  const setSearch = useSetAtom(searchAtom);
  const { count: customerCount } = useCustomersCount();
  const { count: companyCount } = useCompaniesCount();
  return (
    <ReachTabs
      className={twJoin('border-b border-primary px-6', className)}
      index={tab === 'people' ? 1 : 0}
      onChange={index => {
        setSearch('');
        setTab(index === 1 ? 'people' : 'companies');
      }}
    >
      <TabList className="flex gap-3">
        <Tab className="tab">
          Companies
          {companyCount !== null && (
            <Badge>
              {formatCount(companyCount)}
            </Badge>
          )}
        </Tab>
        <Tab className="tab">
          People
          {customerCount !== null && (
            <Badge>
              {formatCount(customerCount)}
            </Badge>
          )}
        </Tab>
      </TabList>
    </ReachTabs>
  );
}; 

const Table = () => {
  const tab = useAtomValue(tabAtom);
  return tab === 'people' ? <TablePeople /> : <TableCompanies />;
}; 

const NewModal = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [modal, setModal] = useAtom(modalAtom);
  const onClose = () => setModal(null);

  if (modal === 'customer') {
    return (
      <CustomersAddCustomerModal
        productId={productId}
        onClose={onClose}
      />
    );
  }
  
  if (modal === 'company') {
    return (
      <CompanyAddModal
        productId={productId}
        onClose={onClose}
      />
    );
  }
  
  return null;
};

export const Customers = {
  Search,
  Add,
  Tabs,
  Table,
  NewModal,
};