type Options = {
  locale?: string;
  currency?: string;
  trimSymbol?: boolean;
};

export const currencyFormat = (value: number, options?: Options) => {
  const formatted = new Intl.NumberFormat(options?.locale || 'en-US', {
    style: 'currency',
    currency: options?.currency || 'USD',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
  return options?.trimSymbol ? formatted.replace(/[^0-9,.-]+/g, '') : formatted;
};
