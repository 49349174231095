import { useBoardConfig } from '../../contexts/boardConfigContext';
import { useRemoveDocFromBoardDocsList } from '../api/cache/cacheBoardDocsList';
import { useGetGroup, useUpdateDocsGroup } from '../api/cache/cacheGroupHooks';
import { useBoardGroups } from '../api/useBoardGroups';

export const useBoardRemoveDoc = () => {
  const { groups } = useBoardGroups();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const getGroup = useGetGroup();
  const removeDocFromBoard = useRemoveDocFromBoardDocsList();
  const updateDocsGroup = useUpdateDocsGroup();

  const remove = (docId: string) => {
    if (groups) {
      if (boardConfig?.docQuery.__typename === 'BoardQuery') {
        removeDocFromBoard(boardConfig.id, docId);
      } else if (
        boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
        boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
      ) {
        const groupIdOfPreviousValue = Object.keys(groups)
          .find(
            gId => Object.values(groups[gId]?.docs ?? {}).map(d => d.id).includes(docId),
          );
        if (!groupIdOfPreviousValue) return;

        const previousGroup = getGroup(groupIdOfPreviousValue);
        if (!previousGroup) return;

        updateDocsGroup({
          groupData: previousGroup,
          updatedDocs: previousGroup.node.docs.edges
            .filter(edge => docId !== edge.node.id)
            .map(edge => edge.node),
          boardConfigId: boardConfig.id,
        });
      }
    }
  };

  return { remove };
};
