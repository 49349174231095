import { ThemeType, Role } from '@cycle-app/graphql-codegen';
import { SelectLine } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import groupBy from 'lodash/groupBy';
import { useState } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import MyAvatar from 'src/components/MyAvatar/MyAvatar';
import { PageId } from 'src/constants/routing.constant';
import { Shortcut, shortcuts } from 'src/constants/shortcuts.constants';
import { useMe } from 'src/hooks/api/useMe';
import { openCommandBar } from 'src/hooks/modals/useCommandBarModal';
import { useLogout } from 'src/hooks/useLogout';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useProductAddOn } from 'src/hooks/useProductAddOns';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { toggleSettingsModal } from 'src/reactives/settingsModal.reactive';
import { toggleTheme, useGetThemeConfig } from 'src/reactives/theme.reactive';
import { Layer } from 'src/types/layers.types';

import { WorkspacesPanel } from './WorkspacesPanel';

export const ProductsMenu = ({ hide }: {
  hide: VoidFunction;
}) => {
  const { me } = useMe();
  const logout = useLogout();
  const isMobile = useIsMobile();
  const { colorTheme } = useGetThemeConfig();
  const unlimitedWorkspacesAddon = useProductAddOn('unlimited-workspaces');
  const { navigate } = useNavigateToSettings();
  const [workspacesOpen, setWorkspacesOpen] = useState(false);
  const {
    isPermissionInit, canReadSettings,
  } = useGetPermission();

  const menuItems = [{
    id: 'account',
    label: 'Account settings',
    onSelect: () => {
      toggleSettingsModal();
      hide();
    },
    skip: isMobile,
    section: 1,
  },
  {
    id: 'theme',
    label: colorTheme === ThemeType.Nightfall ? 'Switch to light mode' : 'Switch to dark mode',
    end: <ShortcutKeys keys={shortcuts[Shortcut.SwitchTheme]} />,
    onClick: toggleTheme,
    section: 1,
  },
  {
    id: 'workspace-settings',
    label: 'Workspace settings',
    end: <ShortcutKeys keys={shortcuts[Shortcut.GoToSettings]} />,
    section: 2,
    skip: isMobile || !isPermissionInit,
    onSelect: () => {
      if (!canReadSettings) {
        setLimitationsModal({ action: 'SETTINGS_READ' });
        return;
      }
      navigate(PageId.Settings);
    },
  },
  {
    id: 'switch-workspace',
    label: 'Switch workspace',
    end: <RightIcon className="text-disabled" />,
    submenu: <WorkspacesPanel />,
    onClick: isMobile ? () => setWorkspacesOpen(true) : undefined,
    skip: me.products.edges.length < 2,
    section: 2,
  },
  {
    id: 'add-new-workspace',
    label: 'Add workspace',
    section: 2,
    onSelect: () => {
      if (!unlimitedWorkspacesAddon.isEnabled && me?.role !== Role.SuperAdmin) {
        setLimitationsModal({
          action: 'USE_ADD_ON',
          brand: 'unlimited-workspaces',
        });
        return;
      }
      openCommandBar('create-workspace');
      hide();
    },
  },
  {
    id: 'logout',
    label: 'Log out',
    onSelect: logout,
    variant: 'danger' as const,
    section: 3,
  },
  ].filter(item => !item.skip);

  const optionsValues = menuItems.map(item => item.id);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    value: null,
    optionsValues,
    onSelect: (itemId) => {
      const item = menuItems.find(i => i.id === itemId);
      item?.onSelect?.();
    },
  });

  const groups = groupBy(menuItems, 'section');

  return (
    <div
      className="w-64"
      {...listProps}
    >
      <div className="border-b border-grey-200 py-2 dark:border-grey-800">
        <div className="flex items-center gap-2 py-1 pl-3 pr-4">
          <MyAvatar />
          <div className="flex flex-col gap-0.5 overflow-hidden">
            <div className="select-text truncate font-medium leading-tight">
              {`${me.firstName} ${me.lastName}`}
            </div>
            <div className="select-text truncate text-caption leading-tight text-secondary">
              {me.email}
            </div>
          </div>
        </div>
      </div>

      {Object.entries(groups).map(([section, items]) => (
        <div
          key={section}
          className="border-b border-grey-200 py-1 last:border-b-0 dark:border-grey-800"
        >
          {items.map(item => {
            const lineProps = itemProps(item.id);

            if (item.submenu) {
              return (
                <DropdownLayer
                  key={item.id}
                  placement="right-start"
                  width={200}
                  content={item.submenu}
                  className="!w-72"
                  layer={Layer.DropdownZ2}
                  {...isMobile ? {
                    visible: workspacesOpen,
                    hide: () => setWorkspacesOpen(false),
                    offset: [-5, -200],
                    animation: false,
                  } : {
                    appendTo: 'parent',
                    offset: [-5, 5],
                  }}
                >
                  <SelectLine
                    className="mx-1 rounded-[4px]"
                    isSelected={selected === item.id}
                    label={item.label}
                    variant={item.variant}
                    endSlot={item.end}
                    {...lineProps}
                    onClick={item.onClick ?? lineProps.onClick}
                  />
                </DropdownLayer>
              );
            }

            return (
              <SelectLine
                key={item.id}
                className="mx-1 rounded-[4px]"
                isSelected={selected === item.id}
                label={item.label}
                variant={item.variant}
                endSlot={item.end}
                {...lineProps}
                onClick={item.onClick ?? lineProps.onClick}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

const ShortcutKeys = ({ keys }: { keys: string[] }) => (
  <span className="text-[13px] text-disabled">
    {keys.map(key => key === ',' ? 'then' : key.toUpperCase()).join(' ')}
  </span>
);