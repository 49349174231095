import { Emoji, Tooltip, SelectPanel } from '@cycle-app/ui';
import { ArrowRightUpIcon, AddIcon, MoreHorizIcon, PenIcon, CloseIcon } from '@cycle-app/ui/icons'; 
import { getDocSlug } from '@cycle-app/utilities';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { EditQuoteFeatureTitle } from 'src/components/QuotePopover/EditQuoteFeatureTitle';
import { PageId } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocBase } from 'src/hooks/api/useDoc';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getUrl } from 'src/utils/routing.utils';

import { useQuoteId } from './QuoteContext';

export const QuotePanelFeature = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  const quoteDoctype = useGetDocType(doc?.doctype?.id);
  const featureDoctype = useGetDocType(doc?.parent?.doctype?.id);
  const mainBtnRef = useRef<HTMLButtonElement>(null);
  const optionsBtnRef = useRef<HTMLButtonElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const changeDocParent = useChangeDocParent();

  if (!doc || !quoteDoctype) return null;

  if (editTitle && doc.parent) {
    return (
      <EditQuoteFeatureTitle
        className="h-7"
        parentId={doc.parent.id}
        defaultValue={doc.parent.title}
        hide={() => setEditTitle(false)}
      />
    );
  }
  
  return (
    <>
      <div className="flex items-center gap-1.5">
        <button
          ref={mainBtnRef}
          onClick={() => setDropdownVisible(true)}
          className={twJoin(
            'btn-tertiary gap-1.5 overflow-hidden bg-grey-100 hover:bg-grey-200 hover:text-primary dark:bg-grey-850 dark:hover:bg-grey-800',
            doc.parent || dropdownVisible ? 'text-primary' : 'text-secondary',
            dropdownVisible && 'btn-hover',
          )}
        >
          {doc.parent ? (
            <>
              <Emoji emoji={featureDoctype?.emoji} />
              <span className="truncate">
                {doc.parent.title.trim() || 'Untitled'}
              </span>
            </>
          ) : (
            <>
              <AddIcon size={12} className="mx-0.5" />
              Link feature
            </>
          )}
        </button>

        {doc.parent && (
          <Tooltip
            content="Open"
            placement="top"
            withWrapper={false}
          >
            <Link
              to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc.parent) })}
              className="btn-tertiary btn-sm btn-square size-5 text-disabled hover:text-primary"
            >
              <ArrowRightUpIcon size={14} />
            </Link>
          </Tooltip>
        )}

        {doc.parent && (
          <button
            ref={optionsBtnRef}
            onClick={() => setOptionsVisible(true)}
            className={twJoin(
              'btn-tertiary btn-sm btn-square size-5 text-disabled hover:text-primary',
              optionsVisible && 'btn-hover text-primary',
            )}
          >
            <MoreHorizIcon />
          </button>
        )}
      </div>

      <Tooltip
        content="Change feature"
        placement="top"
        withPortal
        withWrapper={false}
        reference={mainBtnRef.current}
        disabled={!doc.parent}
      />

      <DropdownLayer
        placement={doc.parent ? 'bottom' : 'bottom-start'}
        layer={Layer.DropdownModalZ3}
        withWrapper={false}
        visible={dropdownVisible}
        hide={() => setDropdownVisible(false)}
        reference={mainBtnRef}
        content={(
          <BoardConfigContextProvider>
            <DocParentPanel
              docId={doc.id}
              docType={quoteDoctype}
              hide={() => setDropdownVisible(false)}
              showNoneOption={false}
              showLinearAutoCreate
            />
          </BoardConfigContextProvider>
        )}
      />

      {doc.parent && (
        <DropdownLayer
          placement="bottom"
          layer={Layer.DropdownModalZ3}
          withWrapper={false}
          visible={optionsVisible}
          hide={() => setOptionsVisible(false)}
          reference={optionsBtnRef.current}
          content={(
            <SelectPanel
              hideSearch
              onOptionChange={option => {
                setOptionsVisible(false);
                option.onSelect?.();
              }}
              options={[{
                value: 'edit-title',
                label: 'Edit title',
                icon: <PenIcon />,
                onSelect: () => setEditTitle(true),
              }, {
                value: 'remove-feature',
                label: 'Remove feature',
                icon: <CloseIcon size={12} className="mx-0.5" />,
                onSelect: () => changeDocParent({
                  docId: quoteId,
                  parentId: undefined, 
                }),
              }]}
            />
          )}
        />
      )}
    </>
  );
};