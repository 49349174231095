import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddButton = styled(Button).attrs({ variant: 'secondary' })`
  ${typo.body500};
`;

export const DoctypeInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body500};
`;

export const DoctypeLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
