import { isDark } from 'src/app/Changelog/ChangelogBuilder/ChangelogBuilderThemeProvider';
import { shipOptions } from 'src/app/Changelog/ChangelogBuilder/constants/shipOptions';
import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { Subtitle } from './ChangelogBuilderContent.styles';


export const ChangelogBuilderContentSubtitle = () => {
  const { watch } = useChangelogBuilderFormContext();
  const isDarkBackground = isDark(watch('backgroundColor'));
  const subtitle = watch('subtitle').trim() || 'What did you ship this week?';
  const shipBadgeColor = watch('shipBadgeColor');
  const heroSection = watch('heroSection');
  const shipIndex = subtitle.indexOf('ship');
  if (shipIndex >= 0) {
    // Replaces only the first "ship"
    return (
      <Subtitle $heroSection={heroSection}>
        {subtitle.substring(0, shipIndex)}
        <img
          src={isDarkBackground ? shipOptions[shipBadgeColor].darkBadge : shipOptions[shipBadgeColor].lightBadge}
          alt="Ship badge"
          className="mx-1 inline-block h-[2.35em] align-middle drop-shadow"
          loading="lazy"
        />
        {subtitle.substring(shipIndex).replace('ship', '')}
      </Subtitle>
    );
  }
  return <Subtitle $heroSection={heroSection}>{subtitle}</Subtitle>;
};
