import { ContentSection } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentReleaseDropdown({ children }: { children: ReactNode }) {
  return (
    <ChangelogDropdown
      dropdownId="release-note-dropdown"
      placement="left-start"
      content={({ hide }) => (
        <ChangelogDropdownContent title="Content" hide={hide}>
          <EditStylePopover />
        </ChangelogDropdownContent>
      )}
    >
      {children}
    </ChangelogDropdown>
  );
}

const contentOptions = [
  {
    value: ContentSection.Classic,
    label: 'Classic',
  },
  {
    value: ContentSection.Discreet,
    label: 'Discreet',
  },
  {
    value: ContentSection.ShowOff,
    label: 'Show off',
  },
  {
    value: ContentSection.Sober,
    label: 'Sober',
  },
  {
    value: ContentSection.Uniform,
    label: 'Uniform',
  },
  {
    value: ContentSection.Unique,
    label: 'Unique',
  },
];

function EditStylePopover() {
  const { control } = useChangelogBuilderForm();

  return (
    <>
      <div className="mb-2 font-medium">Content section</div>
      <Controller
        control={control}
        name="contentSection"
        render={({ field }) => (
          <ToggleDropdown
            withWrapper={false}
            placement="bottom-start"
            layer={Layer.DropdownModal}
            content={(contentProps) => (
              <SelectPanel
                hideSearch
                selectedValue={field.value}
                options={contentOptions}
                onOptionChange={(payload) => {
                  field.onChange(payload.value);
                  contentProps.hide();
                }}
                style={{ width: `${contentProps.buttonRect?.width}px` }}
              />
            )}
            button={(buttonProps) => (
              <button
                ref={buttonProps.ref}
                type="button"
                  // eslint-disable-next-line max-len
                className="group flex w-full cursor-pointer items-center justify-between rounded-lg border border-primary px-3 py-2 text-left hover:bg-grey-100 dark:hover:bg-grey-800"
                onClick={buttonProps.onClick}
              >
                {contentOptions.find((option) => option.value === field.value)?.label ?? 'Select'}
                <DownIcon className="text-secondary group-aria-expanded:rotate-180" />
              </button>
            )}
          />
        )}
      />
    </>
  );
}
