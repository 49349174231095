// Set combinaison in src/constants/shortcuts.constants.ts

export enum Shortcut { // Global shortcut
  GoToSettings = 'go-to-settings',
  GoToComments = 'go-to-comments',
  GoToHome = 'go-to-home',
  GoToFeedback = 'go-to-feedback',
  GoToRoadmap = 'go-to-roadmap',
  GoToReleases = 'go-to-releases',

  SwitchTheme = 'switch-theme',
  GoToWorkspace = 'go-to-workspace',
  ProcessDoc = 'process-doc',
  Undo = 'undo',
  GoToMyAccount = 'go-to-my-account',
}

export enum ShortcutSidebar {
  OpenNotifCenter = 'open-notif-center',
}

// Shortcuts still enabled when commandbar is visible
export enum ShortcutCommandbar {
  ToggleCommandBar = 'toggleCommandBar',
}

export enum ShortcutDocPanel {
  CloseDoc = 'close-doc',
  ExpandDoc = 'expand-doc',
  PrevDoc = 'prev-doc',
  NextDoc = 'next-doc',
  Status = 'status',
}

export enum ShortcutBoard {
  // Doc hover
  OpenDoc = 'open-doc',
  OpenDocFullPage = 'open-doc-expanded',
  CopyDocUrl = 'doc-copy-url',
  DuplicateDoc = 'doc-duplicate',
  DeleteDoc = 'doc-delete',
  SelectDoc = 'doc-select',
  SelectAllDocs = 'doc-select-all',
  ChangeDoctype = 'doc-changedoctype',
  Assign = 'doc-assign',
  AddComment = 'doc-add-comment',
  AddCover = 'doc-add-cover',
  EditProperty = 'doc-edit-property',

  // Board hover
  CreateDoc = 'create-doc',
  Update = 'update-board',
  Duplicate = 'duplicate-board',
  Delete = 'delete-board',
  Publish = 'publish-board',
}

export enum ShortcutGroupOptions {
  CreateDoc = 'group-create-doc',
}

export enum ShortcutDocDraft {
  Abort = 'doc-draft',
  Create = 'doc-create',
  CreateAndOpen = 'doc-create-and-open',
  CreateAndNewDraft = 'doc-create-and-new-draft',
  GoDown = 'go-down',
  GoUp = 'go-up',
}

export enum ShortcutCreateModal {
  Save = 'create-modal-save',
  SaveAndOpen = 'create-modal-save-and-open',
}

export enum ShortcutEditor {
  TurnIntoInsight = 'turn-into-insight',
  TurnGithubIssue = 'turn-into-github-issue',
  TurnLinearIssue = 'turn-into-linear-issue',
}

export enum ShortcutStatus {
  Status1 = 'Status1',
  Status2 = 'Status2',
  Status3 = 'Status3',
  Status4 = 'Status4',
  Status5 = 'Status5',
  Status6 = 'Status6',
  Status7 = 'Status7',
  Status8 = 'Status8',
  Status9 = 'Status9',
}

export type AnyShortcut =
  | Shortcut
  | ShortcutSidebar
  | ShortcutCommandbar
  | ShortcutDocPanel
  | ShortcutBoard
  | ShortcutGroupOptions
  | ShortcutDocDraft
  | ShortcutCreateModal
  | ShortcutEditor
  | ShortcutStatus;

type ShortcutKey =
  | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i'
  | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r'
  | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z'
  | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'
  | 'enter' | 'escape' | 'delete' | 'tab';

type ShortCutKeyMod = 'mod' | 'control' | 'command' | 'shift';

export type ShortcutsHotkeys = Record<AnyShortcut, ShortcutCombinaison>;

export type ShortcutCombinaison =
  | [ShortcutKey]
  | [ShortCutKeyMod, ShortcutKey]
  | [ShortCutKeyMod, ShortCutKeyMod, ShortcutKey]
  | [ShortcutKey, ',', ShortcutKey];

export type MappingShortcut<Shortcut extends AnyShortcut> = {
  sequential: {
    [K in string]: {
      [S in string]: Shortcut;
    }
  };
  default: {
    [S in string]: Shortcut;
  };
};
