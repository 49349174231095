import { useFragment } from '@apollo/client';
import { DocDocSourceFragmentDoc } from '@cycle-app/graphql-codegen';
import { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { BasicEditor, BasicEditorCallbacks } from 'src/components/Editor/Editors/BasicEditor';
import { useUpdateQuoteContent } from 'src/hooks/api/useUpdateQuoteContent';
import { useVerifyAiCreatedQuote } from 'src/hooks/doc/useVerifyAiCreatedQuote';

import { useQuoteId } from './QuoteContext';

export const QuoteContentEditor = () => {
  const quoteId = useQuoteId();
  
  const {
    data: doc, complete, 
  } = useFragment({
    fragment: DocDocSourceFragmentDoc,
    fragmentName: 'DocDocSource',
    from: quoteId,
  });

  const mutation = useUpdateQuoteContent();
  const updateQuoteContent = useDebouncedCallback(mutation.updateQuoteContent, 1000);
  const editorRef = useRef<BasicEditorCallbacks>();

  const verifyAiCreatedQuote = useVerifyAiCreatedQuote();
  const isUpdated = useRef(false);

  const sourceId = doc?.docSource?.doc?.id;
  const docSourceId = doc?.docSource?.id;
  if (!complete || !sourceId || !docSourceId) return null;
  
  return (
    <div className="-mx-6 -my-4 flex grow flex-col">
      <BasicEditor
        key={quoteId}
        ref={callbacks => {
          if (!callbacks) return;
          editorRef.current = callbacks;
        }}
        hideQuickActions
        emptyPlaceholder={'Type \'/\' for commands'}
        className="!flex-none"
        initialValue={doc.docSource?.content ?? ''}
        onUpdate={data => {
          if (data.html === undefined) return;

          if (!isUpdated.current) {
            isUpdated.current = true;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            verifyAiCreatedQuote(quoteId);
          }

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          updateQuoteContent({
            targetId: doc.id,
            sourceId,
            docSourceId,
            content: data.html,
          });
        }}
      />
      
      <div
        className="min-h-32 grow"
        onClick={() => editorRef.current?.focusEnd()}
      />
    </div>
  );
};
