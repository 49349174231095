import {
  Operator,
  FilterPropertyRuleSingleSelectFragment,
  FilterPropertyRuleUrlFragment,
  FilterPropertyRuleTextFragment,
  FilterPropertyRuleNumberFragment,
  FilterPropertyRuleDateFragment,
  FilterPropertyRuleEmailFragment,
  FilterPropertyRulePhoneFragment,
  FilterPropertyRuleCheckboxFragment,
  FilterPropertyRuleMultiSelectFragment,
  FilterPropertyRuleAssigneeFragment,
  FilterPropertyRuleCreatorFragment,
  FilterValueInput,
  FilterPropertyRuleDocParentFragment,
  FilterPropertyRuleStatusFragment,
  FilterPropertyRuleAiStateFragment,
  SwimlaneByFilterPropertyRuleUrlFragment,
  SwimlaneByFilterPropertyRuleTextFragment,
  SwimlaneByFilterPropertyRuleNumberFragment,
  SwimlaneByFilterPropertyRuleSingleSelectFragment,
  SwimlaneByFilterPropertyRuleAssigneeFragment,
  SwimlaneByFilterPropertyRuleCheckboxFragment,
  SwimlaneByFilterPropertyRuleCreatorFragment,
  SwimlaneByFilterPropertyRuleDateFragment,
  SwimlaneByFilterPropertyRulePhoneFragment,
  SwimlaneByFilterPropertyRuleEmailFragment,
  SwimlaneByFilterPropertyRuleMultiSelectFragment,
  SwimlaneByFilterPropertyRuleDocParentFragment,
  SwimlaneByFilterPropertyRuleAiStateFragment,
  FilterPropertyRuleSelectableValueUserFragment,
  FilterPropertyRuleSelectableValueDocFragment,
  FilterPropertyRuleSelectableValueCustomerFragment,
  FilterPropertyRuleCustomerFragment,
  FilterPropertyRuleSelectableValueCompanyFragment,
  FilterPropertyRuleCompanyFragment,
  FilterPropertyRuleSelectableValueStatusFragment,
  FilterPropertyRuleSourceFragment,
  FilterPropertyRuleSelectableValueSourceFragment,
  FilterPropertyRuleProductAreaFragment,
  FilterPropertyRuleSelectableValueProductAreaFragment,
} from '@cycle-app/graphql-codegen';
import { SelectOption, Avatar, StatusIcon, Icon } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';

import { SourceCategoryIcon } from 'src/components/SourceCategoryIcon';
import { FilterRule } from 'src/types/filters.types';
import { sourceCategoryLabel } from 'src/utils/sources.utils';

import { getCompanyOption } from '../companies.util';
import { getCustomerOption } from '../customers.util';
import { getUserLabel } from '../users.util';

const EMPTY_OPERATOR_OPTIONS: SelectOption[] = [
  {
    label: 'is empty',
    value: Operator.IsEmpty,
  },
  {
    label: 'is not empty',
    value: Operator.IsNotEmpty,
  },
];

const IS_OPERATOR_OPTIONS: SelectOption[] = [
  {
    label: 'is',
    value: Operator.Is,
  },
  {
    label: 'is not',
    value: Operator.IsNot,
  },
];

const CONTAINS_OPERATOR_OPTIONS: SelectOption[] = [
  {
    label: 'contains',
    value: Operator.Contains,
  },
  {
    label: 'does not contain',
    value: Operator.DoesNotContain,
  },
];

const COMPARISON_OPERATOR_OPTIONS: SelectOption[] = [
  {
    label: '>',
    tooltipContent: 'is greater than',
    value: Operator.IsGreaterThan,
  },
  {
    label: '<',
    tooltipContent: 'is lower than',
    value: Operator.IsLowerThan,
  },
  {
    label: '≥',
    tooltipContent: 'is greater than or equal to',
    value: Operator.IsGreaterThanOrEqualTo,
  },
  {
    label: '≤',
    tooltipContent: 'is lower than or equal to',
    value: Operator.IsLowerThanOrEqualTo,
  },
];

const CHECKBOX_OPERATOR_OPTIONS: SelectOption[] = [
  {
    label: 'is checked',
    value: Operator.IsTrue,
  },
  {
    label: 'is not checked',
    value: Operator.IsFalse,
  },
];

const AISTATE_OPERATOR_OPTIONS: SelectOption[] = [
  {
    label: 'is AI-generated, not verified',
    value: Operator.IsAiCreated,
  },
  {
    label: 'is AI-generated, user-verified',
    value: Operator.IsUserValidated,
  },
  {
    label: 'is empty',
    value: Operator.IsEmpty,
  },
  {
    label: 'is empty or AI-generated, user-verified',
    value: Operator.IsEmptyOrUserValidated,
  },
];

const LEAF_OPERATORS: string[] = [
  Operator.IsEmpty,
  Operator.IsNotEmpty,
  Operator.IsTrue,
  Operator.IsFalse,
];

export function isLeafOperator(operator: string): boolean {
  return LEAF_OPERATORS.includes(operator);
}

/**
 *
 * Methods that extract operator and values from the different types of filters
 * (data structure is different for each filter type)
 *
 */
interface CommonOperatorData {
  operator: string | undefined;
  operatorOptions: SelectOption[];
}
export type GetFilterMultiValuesInput = (newValues: string[], operator: string) => FilterValueInput;

export interface TextFilterData extends CommonOperatorData {
  textFilterValue?: FilterValue<string> | null;
  getFilterValueInput: (newValue: string, operator: string) => FilterValueInput;
}
export interface NumberFilterData extends CommonOperatorData {
  numberFilterValue?: FilterValue<number> | null;
  getFilterValueInput: (newValue: number, operator: string) => FilterValueInput;
}
export interface DateFilterData extends CommonOperatorData {
  dateFilterValue?: FilterValue<string> | null;
  getFilterValueInput: (newValue: number, operator: string) => FilterValueInput;
}
export interface MultiSelectFilterData extends CommonOperatorData {
  textOptions: SelectOption[];
  getFilterValueInput: GetFilterMultiValuesInput;
}
export interface UserFilterData extends CommonOperatorData {
  userOptions: FilterPropertyRuleSelectableValueUserFragment[];
  getFilterValueInput: GetFilterMultiValuesInput;
}

export interface CustomerFilterData extends CommonOperatorData {
  customerOptions: CustomerFromFilter[];
  selectedCustomers: CustomerFromFilter[];
  getFilterValueInput: GetFilterMultiValuesInput;
}

export interface CompanyFilterData extends CommonOperatorData {
  companyOptions: CompanyFromFilter[];
  selectedCompanies: CompanyFromFilter[];
  getFilterValueInput: GetFilterMultiValuesInput;
}

export interface StatusFilterData extends CommonOperatorData {
  statusOptions: StatusFromFilter[];
  getFilterValueInput: GetFilterMultiValuesInput;
}

export interface SourceFilterData extends CommonOperatorData {
  sourceOptions: SourceFromFilter[];
  getFilterValueInput: GetFilterMultiValuesInput;
}

export interface ProductAreaFilterData extends CommonOperatorData {
  productAreaOptions: ProductAreaFromFilter[];
  getFilterValueInput: GetFilterMultiValuesInput;
}
export interface BooleanFilterData {
  checkboxOperator: string;
  operatorOptions: SelectOption[];
}
export interface AiStateFilterData {
  aiStateOperator: string;
  operatorOptions: SelectOption[];
}

export type DocFromFilter = FilterPropertyRuleSelectableValueDocFragment;

export type CustomerFromFilter = FilterPropertyRuleSelectableValueCustomerFragment;

export type CompanyFromFilter = FilterPropertyRuleSelectableValueCompanyFragment;

export type StatusFromFilter = FilterPropertyRuleSelectableValueStatusFragment;

export type SourceFromFilter = FilterPropertyRuleSelectableValueSourceFragment;

export type ProductAreaFromFilter = FilterPropertyRuleSelectableValueProductAreaFragment;

export interface DocParentFilterData extends CommonOperatorData {
  selectedParents: DocFromFilter[];
  parentOptions: DocFromFilter[];
  getFilterValueInput: GetFilterMultiValuesInput;
}

export type FilterData =
  TextFilterData |
  NumberFilterData |
  DateFilterData |
  MultiSelectFilterData |
  UserFilterData |
  BooleanFilterData |
  DocParentFilterData |
  CustomerFilterData |
  CompanyFilterData |
  StatusFilterData |
  AiStateFilterData |
  SourceFilterData |
  ProductAreaFilterData;

export interface FilterValue<Value = string | number> {
  id: string;
  value: Value;
  selected?: boolean | null;
}

function getUrlFilterData(f: FilterPropertyRuleUrlFragment | SwimlaneByFilterPropertyRuleUrlFragment): TextFilterData {
  const isEmptyOperator = f.urlRule.__typename === 'RuleIsEmptyOrNot'
    ? f.urlRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.urlRule.__typename === 'RuleUrlMultipleValues'
    ? f.urlRule.operator
    : undefined;
  const operator = isEmptyOperator ?? isOrIsNotOperator;
  const values = f.urlRule.__typename === 'RuleUrlMultipleValues'
    ? nodeToArray(f.urlRule.values)
    : [];

  return {
    operator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    textFilterValue: values[0] ?? null,
    getFilterValueInput: (newValue: string, op: string): FilterValueInput => ({
      url: getFilterValueIsOperator(op, [newValue]),
    }),
  };
}

function getTextFilterData(f: FilterPropertyRuleTextFragment | SwimlaneByFilterPropertyRuleTextFragment): TextFilterData {
  const isEmptyOperator = f.textRule.__typename === 'RuleIsEmptyOrNot'
    ? f.textRule.isEmptyOperator
    : undefined;
  const otherOperators = f.textRule.__typename === 'RuleTextMultipleValues'
    ? f.textRule.operator
    : undefined;
  const values = f.textRule.__typename === 'RuleTextMultipleValues'
    ? nodeToArray(f.textRule.values)
    : [];
  const operator = isEmptyOperator ?? otherOperators;

  return {
    operator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
      ...CONTAINS_OPERATOR_OPTIONS,
    ],
    textFilterValue: values[0] ?? null,
    getFilterValueInput: (newValue: string, op: string): FilterValueInput => {
      if (op === 'IS' || op === 'IS_NOT') {
        return {
          text: getFilterValueIsOperator(op, [newValue]),
        };
      }
      return {
        text: getFilterValueContaisOperator(op, [newValue]),
      };
    },
  };
}

function getNumberFilterData(f: FilterPropertyRuleNumberFragment | SwimlaneByFilterPropertyRuleNumberFragment): NumberFilterData {
  const isEmptyOperator = f.numberRule.__typename === 'RuleIsEmptyOrNot'
    ? f.numberRule.isEmptyOperator
    : undefined;
  const otherOperators = f.numberRule.__typename === 'RuleNumberSingleValue'
    ? f.numberRule.operator
    : undefined;
  const value = f.numberRule.__typename === 'RuleNumberSingleValue'
    ? f.numberRule.value
    : null;
  const operator = isEmptyOperator ?? otherOperators;

  return {
    operator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
      ...COMPARISON_OPERATOR_OPTIONS,
    ],
    numberFilterValue: value,
    getFilterValueInput: (newValue: number, op: string): FilterValueInput => {
      if (op === 'IS' || op === 'IS_NOT') {
        return {
          number: getFilterValueIsOperator(op, [newValue]),
        };
      }
      if (op === 'IS_GREATER_THAN' || op === 'IS_LOWER_THAN') {
        return {
          number: getFilterValueStrictComparisonOperator(op, newValue),
        };
      }
      return {
        number: getFilterValueComparisonOperator(op, newValue),
      };
    },
  };
}

function getDateFilterData(f: FilterPropertyRuleDateFragment | SwimlaneByFilterPropertyRuleDateFragment): DateFilterData {
  const isEmptyOperator = f.dateRule?.__typename === 'RuleIsEmptyOrNot'
    ? f.dateRule.isEmptyOperator
    : undefined;
  const otherOperators = f.dateRule?.__typename === 'RuleDateSingleValue'
    ? f.dateRule.operator
    : undefined;
  const value = f.dateRule?.__typename === 'RuleDateSingleValue'
    ? f.dateRule.value
    : null;
  const operator = isEmptyOperator ?? otherOperators;

  return {
    operator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...COMPARISON_OPERATOR_OPTIONS,
    ],
    dateFilterValue: value,
    getFilterValueInput: (newValue: number, op: string): FilterValueInput => {
      if (op === 'IS' || op === 'IS_NOT') {
        return {
          date: getFilterValueIsOperator(op, [newValue]),
        };
      }
      if (op === 'IS_GREATER_THAN' || op === 'IS_LOWER_THAN') {
        return {
          date: getFilterValueStrictComparisonOperator(op, newValue),
        };
      }
      return {
        date: getFilterValueComparisonOperator(op, newValue),
      };
    },
  };
}

function getEmailFilterData(f: FilterPropertyRuleEmailFragment | SwimlaneByFilterPropertyRuleEmailFragment): TextFilterData {
  const isEmptyOperator = f.emailRule.__typename === 'RuleIsEmptyOrNot'
    ? f.emailRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.emailRule.__typename === 'RuleEmailMultipleValues'
    ? f.emailRule.operator
    : undefined;
  const values = f.emailRule.__typename === 'RuleEmailMultipleValues'
    ? nodeToArray(f.emailRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    textFilterValue: values[0] ?? null,
    getFilterValueInput: (newValue: string, op: string): FilterValueInput => ({
      email: getFilterValueIsOperator(op, [newValue]),
    }),
  };
}

function getPhoneFilterData(f: FilterPropertyRulePhoneFragment | SwimlaneByFilterPropertyRulePhoneFragment): TextFilterData {
  const isEmptyOperator = f.phoneRule.__typename === 'RuleIsEmptyOrNot'
    ? f.phoneRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.phoneRule.__typename === 'RulePhoneMultipleValues'
    ? f.phoneRule.operator
    : undefined;
  const values = f.phoneRule.__typename === 'RulePhoneMultipleValues'
    ? nodeToArray(f.phoneRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    textFilterValue: values[0] ?? null,
    getFilterValueInput: (newValue: string, op: string): FilterValueInput => ({
      phone: getFilterValueIsOperator(op, [newValue]),
    }),
  };
}

function getCheckboxFilterData(f: FilterPropertyRuleCheckboxFragment | SwimlaneByFilterPropertyRuleCheckboxFragment): BooleanFilterData {
  return {
    checkboxOperator: f.checkboxOperator,
    operatorOptions: CHECKBOX_OPERATOR_OPTIONS,
  };
}

function getSingleSelectFilterData(
  f: FilterPropertyRuleSingleSelectFragment | SwimlaneByFilterPropertyRuleSingleSelectFragment,
): MultiSelectFilterData {
  const isEmptyOperator = f.singleSelectRule.__typename === 'RuleIsEmptyOrNot'
    ? f.singleSelectRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues'
    ? f.singleSelectRule.operator
    : undefined;
  const textOptions = f.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues'
    ? nodeToArray(f.singleSelectRule.values).map(selectableFilterValueToOption)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    textOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getMultiSelectFilterData(
  f: FilterPropertyRuleMultiSelectFragment | SwimlaneByFilterPropertyRuleMultiSelectFragment,
): MultiSelectFilterData {
  const isEmptyOperator = f.multiSelectRule.__typename === 'RuleIsEmptyOrNot'
    ? f.multiSelectRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues'
    ? f.multiSelectRule.operator
    : undefined;
  const textOptions = f.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues'
    ? nodeToArray(f.multiSelectRule.values).map(selectableFilterValueToOption)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    textOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      multiSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getCreatorFilterData(f: FilterPropertyRuleCreatorFragment | SwimlaneByFilterPropertyRuleCreatorFragment): UserFilterData {
  const isEmptyOperator = f.creatorRule.__typename === 'RuleIsEmptyOrNot'
    ? f.creatorRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.creatorRule.__typename === 'RuleUserMultipleValues'
    ? f.creatorRule.operator
    : undefined;
  const userOptions = f.creatorRule.__typename === 'RuleUserMultipleValues'
    ? nodeToArray(f.creatorRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    userOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getAssigneeFilterData(f: FilterPropertyRuleAssigneeFragment | SwimlaneByFilterPropertyRuleAssigneeFragment): UserFilterData {
  const isEmptyOperator = f.assigneeRule.__typename === 'RuleIsEmptyOrNot'
    ? f.assigneeRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.assigneeRule.__typename === 'RuleUserMultipleValues'
    ? f.assigneeRule.operator
    : undefined;
  const userOptions = f.assigneeRule.__typename === 'RuleUserMultipleValues'
    ? nodeToArray(f.assigneeRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    userOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getStatusFilterData(f: FilterPropertyRuleStatusFragment): StatusFilterData {
  const isOrIsNotOperator = f.statusRule.__typename === 'RuleStatusMultipleValues'
    ? f.statusRule.operator
    : undefined;
  const statusOptions = f.statusRule.__typename === 'RuleStatusMultipleValues'
    ? nodeToArray(f.statusRule.values)
    : [];

  return {
    operator: isOrIsNotOperator,
    operatorOptions: [
      ...IS_OPERATOR_OPTIONS,
    ],
    statusOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getSourceFilterData(f: FilterPropertyRuleSourceFragment): SourceFilterData {
  const isOrIsNotOperator = f.sourceRule.__typename === 'RuleSourceMultipleValues'
    ? f.sourceRule.operator
    : undefined;
  const sourceOptions = f.sourceRule.__typename === 'RuleSourceMultipleValues'
    ? nodeToArray(f.sourceRule.values)
    : [];

  return {
    operator: isOrIsNotOperator,
    operatorOptions: [
      ...IS_OPERATOR_OPTIONS,
    ],
    sourceOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getProductAreaFilterData(f: FilterPropertyRuleProductAreaFragment): ProductAreaFilterData {
  const isEmptyOperator = f.productAreaRule.__typename === 'RuleIsEmptyOrNot'
    ? f.productAreaRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.productAreaRule.__typename === 'RuleProductAreaMultipleValues'
    ? f.productAreaRule.operator
    : undefined;
  const productAreaOptions = f.productAreaRule.__typename === 'RuleProductAreaMultipleValues'
    ? nodeToArray(f.productAreaRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    productAreaOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getAiStateFilterData(f: FilterPropertyRuleAiStateFragment | SwimlaneByFilterPropertyRuleAiStateFragment): AiStateFilterData {
  return {
    aiStateOperator: f.aiStateOperator,
    operatorOptions: AISTATE_OPERATOR_OPTIONS,
  };
}

function getCustomerFilterData(f: FilterPropertyRuleCustomerFragment): CustomerFilterData {
  const isEmptyOperator = f.customerRule.__typename === 'RuleIsEmptyOrNot'
    ? f.customerRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.customerRule.__typename === 'RuleCustomerMultipleValues'
    ? f.customerRule.operator
    : undefined;
  const selectedCustomers = f.customerRule.__typename === 'RuleCustomerMultipleValues'
    ? nodeToArray(f.customerRule.selectedValues)
    : [];
  const customerOptions = f.customerRule.__typename === 'RuleCustomerMultipleValues'
    ? nodeToArray(f.customerRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    selectedCustomers,
    customerOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getCompanyFilterData(f: FilterPropertyRuleCompanyFragment): CompanyFilterData {
  const isEmptyOperator = f.companyRule.__typename === 'RuleIsEmptyOrNot'
    ? f.companyRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.companyRule.__typename === 'RuleCompanyMultipleValues'
    ? f.companyRule.operator
    : undefined;
  const selectedCompanies = f.companyRule.__typename === 'RuleCompanyMultipleValues'
    ? nodeToArray(f.companyRule.selectedValues)
    : [];
  const companyOptions = f.companyRule.__typename === 'RuleCompanyMultipleValues'
    ? nodeToArray(f.companyRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    selectedCompanies,
    companyOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      singleSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

function getParentFilterData(f: FilterPropertyRuleDocParentFragment | SwimlaneByFilterPropertyRuleDocParentFragment): DocParentFilterData {
  const isEmptyOperator = f.docParentRule.__typename === 'RuleIsEmptyOrNot'
    ? f.docParentRule.isEmptyOperator
    : undefined;
  const isOrIsNotOperator = f.docParentRule.__typename === 'RuleDocParentMultipleValues'
    ? f.docParentRule.operator
    : undefined;
  const selectedParents = f.docParentRule.__typename === 'RuleDocParentMultipleValues'
    ? nodeToArray(f.docParentRule.selectedValues)
    : [];
  const parentOptions = f.docParentRule.__typename === 'RuleDocParentMultipleValues'
    ? nodeToArray(f.docParentRule.values)
    : [];

  return {
    operator: isEmptyOperator ?? isOrIsNotOperator,
    operatorOptions: [
      ...EMPTY_OPERATOR_OPTIONS,
      ...IS_OPERATOR_OPTIONS,
    ],
    selectedParents,
    parentOptions,
    getFilterValueInput: (newValues: string[], op: string): FilterValueInput => ({
      multiSelect: getFilterValueIsOperator(op, newValues),
    }),
  };
}

export function selectableUserToOption(selectableUser: FilterPropertyRuleSelectableValueUserFragment): SelectOption {
  const {
    selected,
    value: user,
  } = selectableUser;
  return {
    label: getUserLabel(user),
    value: user.id,
    selected,
    icon: <Avatar size={16} user={user} />,
  };
}

export function selectableStatusToOption(selectableStatus: FilterPropertyRuleSelectableValueStatusFragment): SelectOption {
  const {
    selected,
    value: status,
  } = selectableStatus;
  return {
    label: status.value,
    value: status.id,
    selected,
    icon: <StatusIcon category={status.category} withBackground />,
  };
}

export function selectableSourceToOption(selectableSource: FilterPropertyRuleSelectableValueSourceFragment): SelectOption {
  const {
    selected,
    value: source,
  } = selectableSource;
  return {
    label: sourceCategoryLabel(source.value),
    value: source.id,
    selected,
    icon: <SourceCategoryIcon value={source.value} />,
  };
}

export function selectableProductAreaToOption(selectableProductArea: FilterPropertyRuleSelectableValueProductAreaFragment): SelectOption {
  const {
    selected,
    value: productArea,
  } = selectableProductArea;
  return {
    label: productArea.value,
    value: productArea.id,
    selected,
    icon: <Icon name="tri-shapes" />,
  };
}

export function selectableCustomerToOption(selectableCustomer: CustomerFromFilter): SelectOption {
  const {
    selected,
    value: customer,
  } = selectableCustomer;
  return {
    ...getCustomerOption(customer),
    selected,
  };
}

export function selectableCompanyToOption(selectableCompany: CompanyFromFilter): SelectOption {
  const {
    selected,
    value: company,
  } = selectableCompany;
  return {
    ...getCompanyOption(company),
    selected,
  };
}

export function getFilterData(filter: FilterRule | undefined): FilterData | null {
  switch (filter?.__typename) {
    case 'FilterPropertyRuleUrl':
    case 'SwimlaneByFilterPropertyRuleUrl':
      return getUrlFilterData(filter);

    case 'FilterPropertyRuleText':
    case 'SwimlaneByFilterPropertyRuleText':
      return getTextFilterData(filter);

    case 'FilterPropertyRuleNumber':
    case 'SwimlaneByFilterPropertyRuleNumber':
      return getNumberFilterData(filter);

    case 'FilterPropertyRuleDate':
    case 'SwimlaneByFilterPropertyRuleDate':
      return getDateFilterData(filter);

    case 'FilterPropertyRuleEmail':
    case 'SwimlaneByFilterPropertyRuleEmail':
      return getEmailFilterData(filter);

    case 'FilterPropertyRulePhone':
    case 'SwimlaneByFilterPropertyRulePhone':
      return getPhoneFilterData(filter);

    case 'FilterPropertyRuleCheckbox':
    case 'SwimlaneByFilterPropertyRuleCheckbox':
      return getCheckboxFilterData(filter);

    case 'FilterPropertyRuleSingleSelect':
    case 'SwimlaneByFilterPropertyRuleSingleSelect':
      return getSingleSelectFilterData(filter);

    case 'FilterPropertyRuleMultiSelect':
    case 'SwimlaneByFilterPropertyRuleMultiSelect':
      return getMultiSelectFilterData(filter);

    case 'FilterPropertyRuleCreator':
    case 'SwimlaneByFilterPropertyRuleCreator':
      return getCreatorFilterData(filter);

    case 'FilterPropertyRuleAssignee':
    case 'SwimlaneByFilterPropertyRuleAssignee':
      return getAssigneeFilterData(filter);

    case 'FilterPropertyRuleDocParent':
    case 'SwimlaneByFilterPropertyRuleDocParent':
      return getParentFilterData(filter);

    case 'FilterPropertyRuleCustomer':
      return getCustomerFilterData(filter);

    case 'FilterPropertyRuleCompany':
      return getCompanyFilterData(filter);

    case 'FilterPropertyRuleStatus':
      return getStatusFilterData(filter);

    case 'FilterPropertyRuleAiState':
    case 'SwimlaneByFilterPropertyRuleAiState':
      return getAiStateFilterData(filter);

    case 'FilterPropertyRuleSource':
    // case 'SwimlaneByFilterPropertyRuleSource':
      return getSourceFilterData(filter);

    case 'FilterPropertyRuleProductArea':
      // case 'SwimlaneByFilterPropertyRuleSource':
      return getProductAreaFilterData(filter);

    default:
      return null;
  }
}

export function isBooleanFilterData(data: FilterData): data is BooleanFilterData {
  return 'checkboxOperator' in data;
}
export function isTextValueFilterData(data: FilterData): data is TextFilterData {
  return 'textFilterValue' in data;
}

export function isNumberValueFilterData(data: FilterData): data is NumberFilterData {
  return 'numberFilterValue' in data;
}

export function isDateValueFilterData(data: FilterData): data is DateFilterData {
  return 'dateFilterValue' in data;
}

export function isMultiValuesFilterData(data: FilterData): data is MultiSelectFilterData {
  return 'textOptions' in data;
}

export function isUsersFilterData(data: FilterData): data is UserFilterData {
  return 'userOptions' in data;
}

export function isDocParentFilterData(data: FilterData): data is DocParentFilterData {
  return 'parentOptions' in data;
}

export function isCustomerFilterData(data: FilterData): data is CustomerFilterData {
  return 'customerOptions' in data;
}

export function isCompanyFilterData(data: FilterData): data is CompanyFilterData {
  return 'companyOptions' in data;
}

export function isStatusFilterData(data: FilterData): data is StatusFilterData {
  return 'statusOptions' in data;
}

export function isAiStateFilterData(data: FilterData): data is AiStateFilterData {
  return 'aiStateOperator' in data;
}

export function isSourceFilterData(data: FilterData): data is SourceFilterData {
  return 'sourceOptions' in data;
}

export function isProductAreaFilterData(data: FilterData): data is ProductAreaFilterData {
  return 'productAreaOptions' in data;
}

interface SelectableFilterValue<Value = string | number> {
  id: string;
  selected: boolean;
  value: {
    id: string;
    value: Value;
  };
}

function selectableFilterValueToOption(f: SelectableFilterValue<string>): SelectOption {
  return {
    label: f.value.value,
    value: f.value.id,
    ...f.selected
      ? { selected: f.selected }
      : {},
  };
}

function getFilterValueIsOperator<Operator extends string, Value>(operator: Operator, value: Value) {
  return operator === 'IS'
    ? { IS: value }
    : { IS_NOT: value };
}

function getFilterValueContaisOperator<Operator extends string, Value>(operator: Operator, value: Value) {
  return operator === 'CONTAINS'
    ? { CONTAINS: value }
    : { DOES_NOT_CONTAIN: value };
}

function getFilterValueStrictComparisonOperator<Operator extends string, Value>(operator: Operator, value: Value) {
  return operator === 'IS_GREATER_THAN'
    ? { IS_GREATER_THAN: value }
    : { IS_LOWER_THAN: value };
}

function getFilterValueComparisonOperator<Operator extends string, Value>(operator: Operator, value: Value) {
  return operator === 'IS_GREATER_THAN_OR_EQUAL_TO'
    ? { IS_GREATER_THAN_OR_EQUAL_TO: value }
    : { IS_LOWER_THAN_OR_EQUAL_TO: value };
}
