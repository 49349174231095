import { Emoji, Tooltip, SelectPanel, Input } from '@cycle-app/ui';
import { ArrowRightUpIcon, AddIcon, MoreHorizIcon, PenIcon, CloseIcon } from '@cycle-app/ui/icons'; 
import { getDocSlug } from '@cycle-app/utilities';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useUpdateDocTitle } from 'src/hooks/api/mutations/updateDocHooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocBase } from 'src/hooks/api/useDoc';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getUrl } from 'src/utils/routing.utils';

import { QuoteStatus } from './QuoteStatus';

export const QuoteFeature = ({ docId }: { docId: string }) => {
  const { doc } = useDocBase(docId);
  const quoteDoctype = useGetDocType(doc?.doctype?.id);
  const featureDoctype = useGetDocType(doc?.parent?.doctype?.id);
  const mainBtnRef = useRef<HTMLButtonElement>(null);
  const optionsBtnRef = useRef<HTMLButtonElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const changeDocParent = useChangeDocParent();

  if (!doc || !quoteDoctype) return null;

  if (editTitle && doc.parent) {
    return (
      <EditTitle
        docId={doc.parent.id}
        defaultValue={doc.parent.title}
        hide={() => setEditTitle(false)}
      />
    );
  }

  const statusId = doc.parent?.status?.id;
  
  return (
    <div
      className="group -m-0.5 flex items-center overflow-hidden p-0.5"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <button
        ref={mainBtnRef}
        onClick={() => setDropdownVisible(true)}
        className={twJoin(
          'btn-tertiary btn-sm mr-0.5 gap-2 overflow-hidden font-normal hover:bg-grey-100 dark:hover:bg-grey-800',
          dropdownVisible && 'btn-hover',
        )}
      >
        {doc.parent ? (
          <>
            <Tooltip
              content={featureDoctype?.name}
              placement="top"
              withPortal
              withWrapper={false}
            >
              <Emoji
                size={12}
                emoji={featureDoctype?.emoji}
              />
            </Tooltip>
              
            {statusId && <QuoteStatus statusId={statusId} />}

            <Tooltip
              content="Change feature"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <span className="truncate">
                {doc.parent.title}
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            <AddIcon size={10} />
            <span className="truncate">
              Link feature
            </span>
          </>
        )}
      </button>

      {doc.parent && (
        <Tooltip
          content="Open"
          placement="top"
          withWrapper={false}
        >
          <Link
            to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc.parent) })}
            className={twJoin(
              'btn-tertiary btn-sm btn-square mr-0.5 size-5 text-disabled hover:text-primary group-focus-within:flex group-hover:flex',
              '[@media(pointer:fine)]:hidden',
            )}
          >
            <ArrowRightUpIcon size={12} />
          </Link>
        </Tooltip>
      )}

      {doc.parent && (
        <button
          ref={optionsBtnRef}
          onClick={() => setOptionsVisible(true)}
          className={twJoin(
            'btn-tertiary btn-sm btn-square size-5 text-disabled hover:text-primary group-focus-within:flex group-hover:flex',
            '[@media(pointer:fine)]:hidden',
            optionsVisible && 'btn-hover flex text-primary',
          )}
        >
          <MoreHorizIcon />
        </button>
      )}

      <DropdownLayer
        placement={doc.parent ? 'bottom' : 'bottom-start'}
        layer={Layer.DropdownModalZ3}
        withWrapper={false}
        visible={dropdownVisible}
        hide={() => setDropdownVisible(false)}
        reference={mainBtnRef}
        content={(
          <BoardConfigContextProvider>
            <DocParentPanel
              docId={doc.id}
              docType={quoteDoctype}
              hide={() => setDropdownVisible(false)}
              showNoneOption={false}
              showLinearAutoCreate
            />
          </BoardConfigContextProvider>
        )}
      />

      {doc.parent && (
        <DropdownLayer
          placement="bottom"
          layer={Layer.DropdownModalZ3}
          withWrapper={false}
          visible={optionsVisible}
          hide={() => setOptionsVisible(false)}
          reference={optionsBtnRef.current}
          content={(
            <SelectPanel
              hideSearch
              onOptionChange={option => {
                setOptionsVisible(false);
                option.onSelect?.();
              }}
              options={[{
                value: 'edit-title',
                label: 'Edit title',
                icon: <PenIcon />,
                onSelect: () => setEditTitle(true),
              }, {
                value: 'remove-feature',
                label: 'Remove feature',
                icon: <CloseIcon size={12} className="mx-0.5" />,
                onSelect: () => changeDocParent({
                  docId,
                  parentId: undefined, 
                }),
              }]}
            />
          )}
        />
      )}
    </div>
  );
};

const EditTitle = ({
  docId, defaultValue, hide,
}: {
  docId: string;
  defaultValue: string;
  hide: VoidFunction;
}) => {
  const { updateDocTitle } = useUpdateDocTitle();
  const updateDocTitleDebounced = useDebouncedCallback(updateDocTitle, INPUT_ONCHANGE_DEBOUNCE);
  return (
    <Input
      className="w-80 flex-1 [&>div>input]:h-6 [&>div>input]:text-[13px]"
      inputSize="S"
      autoFocus
      defaultValue={defaultValue}
      onBlur={hide}
      onClick={e => {
        e.stopPropagation();
      }}
      onKeyDown={e => {
        e.stopPropagation();
      }}
      onKeyUp={e => {
        e.stopPropagation();
        if (['Enter', 'Escape'].includes(e.key)) hide();
      }}
      onChange={e => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateDocTitleDebounced({
          docId,
          title: e.target.value,
        });
      }}
    />
  );
};
