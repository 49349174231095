import {
  CustomAttributeDefinitionFragment,
  OperatorIsEmptyOrNot,
  OperatorIsInOrNot,
  OperatorMultiSelect,
  PublishedBoardConfigFullFragment,
  Property,
} from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { getStatus, getStatuses } from 'src/reactives/productStatus.reactive';
import { getBoardConfigGroupBy } from 'src/utils/boardConfig/boardConfig.util';

import { useProductAreasCategories } from './product/useProductAreasCategories';

export const useCompatibility = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  return useBoardConfigCompatibility(boardConfig);
};

export const useBoardConfigCompatibility = (boardConfig: PublishedBoardConfigFullFragment | null | undefined) => {
  const { areas: productAreas } = useProductAreasCategories();

  const boardConfigGroupBy = boardConfig ? getBoardConfigGroupBy(boardConfig) : null;
  const groupByProperty = boardConfigGroupBy?.property as Property | undefined;
  const isGroupByProductAreas = groupByProperty?.__typename === 'ProductAreaDefinition';

  const shouldDisplayWarning = useCallback(
    (attributeDefinition: CustomAttributeDefinitionFragment) => !!boardConfig?.filterProperties.edges?.find(
      (edge) => 'attribute' in edge.node && edge.node.attribute.id === attributeDefinition.id,
    ),
    [boardConfig],
  );

  const getCompatibleStatuses = useCallback(() => {
    const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => rule.__typename === 'FilterPropertyRuleStatus');
    if (relativeRule?.node.__typename === 'FilterPropertyRuleStatus') {
      if (relativeRule.node.statusRule.__typename === 'RuleStatusMultipleValues') {
        const {
          operator, values,
        } = relativeRule.node.statusRule;
        const hasSelection = values.edges.some(({ node }) => node.selected);
        return hasSelection
          ? values.edges
            .filter(({ node }) => (operator === OperatorIsInOrNot.Is ? node.selected : !node.selected))
            .map(e => getStatus(e.node.value.id))
            .filter(isPresent)
          : getStatuses().all;
      }
    }
    return getStatuses().all;
  }, [boardConfig]);

  const compatibleProductAreas = useMemo(() => {
    const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => rule.__typename === 'FilterPropertyRuleProductArea');
    if (relativeRule?.node.__typename === 'FilterPropertyRuleProductArea') {
      if (
        relativeRule.node.productAreaRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.productAreaRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        return [];
      }

      if (relativeRule.node.productAreaRule.__typename === 'RuleProductAreaMultipleValues') {
        const {
          operator, values,
        } = relativeRule.node.productAreaRule;
        if (values.edges.some((edge) => edge.node.selected)) {
          return values.edges
            .filter(edge => (operator === OperatorMultiSelect.Is ? edge.node.selected : !edge.node.selected))
            .map(edge => edge.node.value);
        }
      }
    }
    return productAreas;
  }, [boardConfig?.filterProperties.edges, productAreas]);

  const isProductAreaReadonly = useMemo(() => {
    const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => rule.__typename === 'FilterPropertyRuleProductArea');
    if (relativeRule?.node.__typename === 'FilterPropertyRuleProductArea') {
      if (relativeRule.node.productAreaRule.__typename === 'RuleProductAreaMultipleValues') {
        const {
          operator, values,
        } = relativeRule.node.productAreaRule;
        if (values.edges.some((edge) => edge.node.selected)) {
          // IS are or conditions, so it is readonly if only one value selected. 
          return operator === OperatorMultiSelect.Is && values.edges.filter(edge => edge.node.selected).length === 1;
        }
      }
      if (
        relativeRule.node.productAreaRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.productAreaRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        return true;
      }
    }
    return false;
  }, [boardConfig?.filterProperties.edges]);

  const productAreasFromBoardConfig = useMemo(() => {
    const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => rule.__typename === 'FilterPropertyRuleProductArea');
    if (relativeRule?.node.__typename === 'FilterPropertyRuleProductArea') {
      if (
        relativeRule.node.productAreaRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.productAreaRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        return [];
      }
      if (
        relativeRule.node.productAreaRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.productAreaRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty
      ) {
        // Get only the first value
        return [productAreas[0]];
      }

      if (relativeRule.node.productAreaRule.__typename === 'RuleProductAreaMultipleValues') {
        const {
          operator, values,
        } = relativeRule.node.productAreaRule;
        if (values.edges.some((edge) => edge.node.selected)) {
          if (operator === OperatorMultiSelect.Is) {
            return values.edges
              .filter(edge => edge.node.selected)
              .map(edge => edge.node.value);
          }
          if (operator === OperatorMultiSelect.IsNot) {
            // Get only the first value
            return [values.edges.find(edge => !edge.node.selected)?.node.value];
          }
        }
      }
    }
    return [];
  }, [boardConfig?.filterProperties.edges, productAreas]);

  const getCompatibleOptions = useCallback((attributeDefinition: CustomAttributeDefinitionFragment) => {
    if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition') {
      const relativeRule = boardConfig?.filterProperties.edges.find(edge => {
        if (edge.node.__typename === 'FilterPropertyRuleSingleSelect') {
          return edge.node.attribute.id === attributeDefinition.id;
        }
        return false;
      });

      if (!relativeRule || relativeRule.node.__typename !== 'FilterPropertyRuleSingleSelect') {
        return attributeDefinition.values.edges;
      }

      if (
        relativeRule.node.singleSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.singleSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        return [];
      }

      return attributeDefinition.values.edges
        .filter((edge) => {
          if (
            relativeRule.node.__typename === 'FilterPropertyRuleSingleSelect' &&
            relativeRule.node.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues'
          ) {
            const {
              operator, values,
            } = relativeRule.node.singleSelectRule;
            const hasSelection = values.edges.find(({ node }) => node.selected);
            // is compatible if no selection, no matter the operation.
            return !hasSelection || values.edges
              .filter(({ node }) => (operator === OperatorIsInOrNot.Is ? node.selected : !node.selected))
              .map(e => e.node.value.id)
              .includes(edge.node.id);
          }
          return true;
        });
    }

    if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
      const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => {
        if (rule.__typename === 'FilterPropertyRuleMultiSelect') {
          return rule.attribute.id === attributeDefinition.id;
        }
        return false;
      });

      if (!relativeRule || relativeRule.node.__typename !== 'FilterPropertyRuleMultiSelect') {
        return attributeDefinition.values.edges;
      }

      if (
        relativeRule.node.multiSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.multiSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        return [];
      }

      return attributeDefinition.values.edges
        .filter((edge) => {
          if (
            relativeRule.node.__typename === 'FilterPropertyRuleMultiSelect' &&
            relativeRule.node.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues'
          ) {
            const {
              operator, values,
            } = relativeRule.node.multiSelectRule;
            const hasSelection = values.edges.find(({ node }) => node.selected);
            return !hasSelection || values.edges
              .filter(({ node }) => (operator === OperatorIsInOrNot.Is ? node.selected : !node.selected))
              .map(e => e.node.value.id)
              .includes(edge.node.id);
          }
          return true;
        });
    }
    return [];
  }, [boardConfig]);

  const isProductAreaRequiredToBeVisible = useMemo(() => {
    const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => rule.__typename === 'FilterPropertyRuleProductArea');
    if (relativeRule?.node.__typename === 'FilterPropertyRuleProductArea') {
      const shouldBeDefined =
        relativeRule.node.productAreaRule.__typename === 'RuleProductAreaMultipleValues' &&
        relativeRule.node.productAreaRule.operator === OperatorMultiSelect.Is &&
        relativeRule.node.productAreaRule.values.edges.some(edge => edge.node.selected);

      const shouldBeNotEmpty =
        relativeRule.node.productAreaRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.productAreaRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

      return shouldBeDefined || shouldBeNotEmpty;
    }
    return false;
  }, [boardConfig?.filterProperties.edges]);

  const isPropertyRequiredToBeVisible = useCallback((attributeDefinition: CustomAttributeDefinitionFragment) => {
    const relativeRule = boardConfig?.filterProperties.edges.find(edge => {
      if (edge.node.__typename === 'FilterPropertyRuleSingleSelect' ||
        edge.node.__typename === 'FilterPropertyRuleMultiSelect') {
        return edge.node.attribute.id === attributeDefinition.id;
      }
      return false;
    });

    if (relativeRule?.node.__typename === 'FilterPropertyRuleSingleSelect') {
      const shouldBeDefined =
        relativeRule.node.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues' &&
        relativeRule.node.singleSelectRule.operator === OperatorIsInOrNot.Is;

      const shouldBeNotEmpty =
        relativeRule.node.singleSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.singleSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

      return shouldBeDefined || shouldBeNotEmpty;
    }

    if (relativeRule?.node.__typename === 'FilterPropertyRuleMultiSelect') {
      const shouldBeDefined =
        relativeRule.node.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues' &&
        relativeRule.node.multiSelectRule.operator === OperatorIsInOrNot.Is;

      const shouldBeNotEmpty =
        relativeRule.node.multiSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.multiSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

      return shouldBeDefined || shouldBeNotEmpty;
    }

    return false;
  }, [boardConfig]);

  // Whether we should display the create option in the select menus. 
  const canProductAreaCreateOption = useMemo(() => {
    const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => rule.__typename === 'FilterPropertyRuleProductArea');
    if (relativeRule?.node.__typename === 'FilterPropertyRuleProductArea') {
      if (
        relativeRule.node.productAreaRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.productAreaRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        return false;
      }

      if (relativeRule.node.productAreaRule.__typename === 'RuleProductAreaMultipleValues') {
        const {
          operator, values,
        } = relativeRule.node.productAreaRule;
        if (values.edges.some(edge => edge.node.selected) && operator === OperatorMultiSelect.Is) {
          return false;
        }
        return true;
      }
    }
    return true;
  }, [boardConfig?.filterProperties.edges]);

  const canPropertyCreateOption = useCallback((attributeDefinition: CustomAttributeDefinitionFragment) => {
    const relativeRule = boardConfig?.filterProperties.edges.find(edge => {
      if (
        edge.node.__typename === 'FilterPropertyRuleSingleSelect' ||
        edge.node.__typename === 'FilterPropertyRuleMultiSelect'
      ) {
        return edge.node.attribute.id === attributeDefinition.id;
      }
      return false;
    });
    if (relativeRule?.node.__typename === 'FilterPropertyRuleSingleSelect') {
      if (relativeRule.node.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues') {
        return relativeRule.node.singleSelectRule.operator === OperatorIsInOrNot.IsNot;
      }
      if (relativeRule.node.singleSelectRule.__typename === 'RuleIsEmptyOrNot') {
        return relativeRule.node.singleSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;
      }
    }
    if (relativeRule?.node.__typename === 'FilterPropertyRuleMultiSelect') {
      if (relativeRule.node.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues') {
        return relativeRule.node.multiSelectRule.operator === OperatorIsInOrNot.IsNot;
      }
      if (relativeRule.node.multiSelectRule.__typename === 'RuleIsEmptyOrNot') {
        return relativeRule.node.multiSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;
      }
    }
    return true;
  }, [boardConfig]);

  return {
    shouldDisplayWarning,
    getCompatibleOptions,
    getCompatibleStatuses,
    compatibleProductAreas,
    isPropertyRequiredToBeVisible,
    isProductAreaRequiredToBeVisible,
    canPropertyCreateOption,
    canProductAreaCreateOption,
    productAreasFromBoardConfig,
    isProductAreaReadonly: isGroupByProductAreas || isProductAreaReadonly,
  };
};
