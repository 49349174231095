import { Tag, TagProps, Icon } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

import { useDoc } from '../../hooks';
import { useDocProductAreaUpdate } from '../../hooks/doc/useDocProductAreaUpdate';
import { useProductAreasCategories } from '../../hooks/product/useProductAreasCategories';
import { useCompatibility } from '../../hooks/useCompatibility';
import { getDocType } from '../../reactives/docTypes.reactive';
import { Layer } from '../../types/layers.types';
import { isFeedback } from '../../utils/docType.util';
import { ProductAreasManager } from '../ProductAreasManager';

type Props = {
  docId: string;
  isDisabled?: boolean;
  isCreateEnabled?: boolean;
  layer?: Layer;
};

export const DocProductAreas = ({
  docId, isDisabled, layer,
}: Props) => {
  const { doc } = useDoc(docId);
  const {
    compatibleProductAreas, isProductAreaRequiredToBeVisible,
  } = useCompatibility();
  const docProductAreas = nodeToArray(doc?.productAreas);
  const isDocFeedback = isFeedback(doc?.doctype);
  const productAreaUpdate = useDocProductAreaUpdate();
  const { areas } = useProductAreasCategories();
  const [{
    visible, rect,
  }, setDropdown] = useState<{ visible: boolean; rect: DOMRect | null }>({
    visible: false,
    rect: null,
  });

  const selectValues = sortBy(docProductAreas, 'value')
    .filter(selectedValue => !!areas.some(a => a.id === selectedValue.id));

  if (selectValues.length === 0) return null;

  return (
    <>
      <DropdownLayer
        layer={layer}
        closingArea
        withWrapper={false}
        placement="bottom-start"
        disabled={isDisabled}
        getReferenceClientRect={rect ? (() => rect) : undefined}
        // remove of unnecessary gap
        style={{ position: 'absolute' }}
        content={(
          <ProductAreasManager
            isRequired={isProductAreaRequiredToBeVisible}
            compatibleIds={compatibleProductAreas.map(area => area.id)}
            docTypeName={getDocType(doc?.doctype.id)?.name || ''}
            hideIncompatibleValues={!!doc?.isDraft}
            isMulti={isDocFeedback}
            showWarning={!doc?.isDraft}
            onClearValue={async (clearData) => {
              // Clear is displayed for single select only as there is no bulk mutation yet.
              const selectedArea = docProductAreas?.[0];
              if (selectedArea) {
                await productAreaUpdate.remove({
                  docId,
                  productAreaId: selectedArea.id,
                  isIncompatible: !doc?.isDraft && clearData.isIncompatible,
                });
              }
            }}
            onSelect={selectData => productAreaUpdate.update({
              docId,
              productAreaId: selectData.productAreaId,
              isMulti: selectData.isMulti,
              isIncompatible: !doc?.isDraft && selectData.isIncompatible,
            })}
            onUnselect={unselectData => productAreaUpdate.remove({
              docId,
              productAreaId: unselectData.productAreaId,
              isIncompatible: !doc?.isDraft && unselectData.isIncompatible,
            })}
            selectedValues={docProductAreas.map(area => area.id)}
          />
        )}
        visible={visible}
        hide={() => setDropdown({
          visible: false,
          rect,
        })}
      />

      {selectValues.map(selectValue => (
        <ProductAreaTag
          key={selectValue.id}
          value={selectValue.value}
          isDisabled={isDisabled}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if (!visible) setDropdown({
              visible: true,
              rect: e.currentTarget.getBoundingClientRect(),
            });
          }}
        />
      ))}
    </>
  );
};

type ProductAreaTag = TagProps & {
  isDisabled?: boolean;
  value: string;
};

export const ProductAreaTag = ({
  isDisabled = false,
  value,
  onClick,
  ...props
}: ProductAreaTag) => {
  return (
    <Tag
      {...!isDisabled && { onClick }}
      limitSize
      tooltip={{
        placement: 'top',
        withPortal: true,
        withWrapper: false,
        content: (
          <>
            <div className="flex items-center gap-1">
              <Icon name="tri-shapes" />
              {value}
            </div>
            {!isDisabled && (
              <div className="text-caption text-grey-500">
                Click to update
              </div>
            )}
          </>
        ),
      }}
      {...props}
    >
      {value}
    </Tag>
  );
};