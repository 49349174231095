/**
 * Image extension
 */
import { FileUploadedData } from './files.types';

export enum ImageSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export type FileData = {
  src: string;
  title?: string;
  dataId: string;
  width?: number;
  height?: number;
};

export type VideoViewAttributes = FileData & {
  dataGif?: 'on' | 'off';
};

export type FileViewAttributes = {
  file?: FileUploadedData;
  // DataId is optional since legacy file node didn't have the info
  dataId?: string;
};

export type AudioViewAttributes = Omit<FileData, 'title'>;
