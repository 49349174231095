import { useApolloClient } from '@apollo/client';
import { ReleaseNoteBaseFragment, ReleaseNotesDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import uniqBy from 'lodash/uniqBy';

import { RELEASE_NOTES_INITIAL_PAGINATION_SIZE } from 'src/constants/releases.constants';
import client from 'src/services/apollo/client';

export const useReleaseNotesCache = () => {
  const { cache } = useApolloClient();

  const prependEdge = ({
    releaseId, notes,
  }: { releaseId: string; notes: ReleaseNoteBaseFragment[] }) => {
    if (!notes.length) return;
    client.cache.updateQuery(
      {
        query: ReleaseNotesDocument,
        variables: {
          releaseId,
          isOther: notes.some(note => note.isOther),
          cursor: '',
          size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
        },
      },
      prev => produce(prev, draft => {
        if (draft?.node?.__typename !== 'Release') return;

        draft.node.releaseNotes.edges = uniqBy(
          [
            ...notes.map(note => ({
              cursor: '',
              node: note,
            })),
            ...draft.node.releaseNotes.edges,
          ],
          note => note.node.id,
        );
      }),
    );

    notes.forEach(note => {
      cache.modify({
        id: note.id,
        fields: {
          release: (current, { toReference }) => {
            return toReference(releaseId) ?? current;
          },
        },
      });
    });
  };

  const appendEdge = ({
    releaseId, notes,
  }: { releaseId: string; notes: ReleaseNoteBaseFragment[] }) => {
    if (!notes.length) return;
    client.cache.updateQuery(
      {
        query: ReleaseNotesDocument,
        variables: {
          releaseId,
          isOther: notes.some(note => note.isOther),
          cursor: '',
          size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
        },
      },
      prev => produce(prev, draft => {
        if (draft?.node?.__typename !== 'Release') return;

        draft.node.releaseNotes.edges = uniqBy(
          [
            ...draft.node.releaseNotes.edges,
            ...notes.map(note => ({
              cursor: '',
              node: note,
            })),
          ],
          note => note.node.id,
        );
      }),
    );

    notes.forEach(note => {
      cache.modify({
        id: note.id,
        fields: {
          release: (current, { toReference }) => {
            return toReference(releaseId) ?? current;
          },
        },
      });
    });
  };

  const removeEdge = ({
    releaseId, notes,
  }: { releaseId: string; notes: ReleaseNoteBaseFragment[] }) => {
    client.cache.updateQuery({
      query: ReleaseNotesDocument,
      variables: {
        releaseId,
        isOther: notes.some(note => note.isOther),
        cursor: '',
        size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
      },
    }, prev => produce(prev, draft => {
      if (draft?.node?.__typename !== 'Release') return;
      /* eslint-disable no-param-reassign */
      draft.node.releaseNotes.edges = draft.node.releaseNotes.edges.filter(edge => !notes.find(note => note.id === edge.node.id));

      // Update cursor in case we remove the last item of the list.
      const cursor = draft.node.releaseNotes.edges.at(-1)?.cursor;
      if (draft.node.releaseNotes.pageInfo.endCursor && cursor) {
        draft.node.releaseNotes.pageInfo.endCursor = cursor;
      }
    }));
  };

  const prenpendReleaseNotes = ({
    releaseId, mainReleaseNotes, otherReleaseNotes,
  }: { releaseId: string; mainReleaseNotes: ReleaseNoteBaseFragment[]; otherReleaseNotes: ReleaseNoteBaseFragment[] }) => {
    prependEdge({
      releaseId,
      notes: mainReleaseNotes,
    });
    prependEdge({
      releaseId,
      notes: otherReleaseNotes,
    });
  };

  const appendReleaseNotes = ({
    releaseId, mainReleaseNotes, otherReleaseNotes,
  }: { releaseId: string; mainReleaseNotes: ReleaseNoteBaseFragment[]; otherReleaseNotes: ReleaseNoteBaseFragment[] }) => {
    appendEdge({
      releaseId,
      notes: mainReleaseNotes,
    });
    appendEdge({
      releaseId,
      notes: otherReleaseNotes,
    });
  };

  const removeReleaseNotes = ({
    releaseId, mainReleaseNotes, otherReleaseNotes,
  }: { releaseId: string; mainReleaseNotes: ReleaseNoteBaseFragment[]; otherReleaseNotes: ReleaseNoteBaseFragment[] }) => {
    removeEdge({
      releaseId,
      notes: mainReleaseNotes,
    });
    removeEdge({
      releaseId,
      notes: otherReleaseNotes,
    });
  };

  return {
    removeReleaseNotes,
    prenpendReleaseNotes,
    appendReleaseNotes,
  };
};
