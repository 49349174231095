import { useFragment } from '@apollo/client';
import { StatusBaseFragmentDoc } from '@cycle-app/graphql-codegen';
import { StatusIcon, Tooltip } from '@cycle-app/ui';

export const QuoteStatus = ({ statusId }: { statusId: string }) => {
  const {
    data, complete, 
  } = useFragment({
    fragment: StatusBaseFragmentDoc,
    from: statusId,
  });
  if (!complete) return null;
  return (
    <Tooltip
      content={data.value}
      placement="top"
      withPortal
      withWrapper={false}
    >
      <StatusIcon
        className="flex-none"
        category={data.category}
        withBackground
        size={16}
      />
    </Tooltip>
  );
};
