import { useFragment } from '@apollo/client';
import { DocTitleFragmentDoc } from '@cycle-app/graphql-codegen';
import { Tooltip, Icon } from '@cycle-app/ui';
import { getDocSlug } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { getUrl } from 'src/utils/routing.utils';

export const QuoteFeedback = ({ docId }: { docId: string }) => {
  const {
    data, complete, 
  } = useFragment({
    fragment: DocTitleFragmentDoc,
    from: docId,
  });
  if (!complete) return null;

  return (
    <Tooltip
      content={data.title.trim() || 'Untitled feedback doc'}
      placement="top"
      withPortal
      withWrapper={false}
    >
      <Link
        to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(data) })}
        onClick={e => e.stopPropagation()}
        // eslint-disable-next-line max-len
        className="flex size-5 items-center justify-center rounded-[4px] text-disabled outline-none hover:bg-grey-200 hover:text-primary focus-visible:ring-2 dark:hover:bg-grey-800"
      >
        <Icon name="feedback" className="size-3.5" />
      </Link>
    </Tooltip>
  );
};
