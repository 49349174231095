import { typo, Shortcut, ShortcutComponents } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const ShortcutStyled = styled(Shortcut)`
  ${ShortcutComponents.Key} {
    background-color: ${p => p.theme.colors.commandbar.shortCut.bg};
    color: ${p => p.theme.colors.commandbar.shortCut.color};
  }
  margin-left: auto;
  opacity: 0.5;
`;

export const Action = styled.button<{ hoverDisabled?: boolean }>`
  ${typo.body400};
  transition: color .1s, background-color .1s;
  color: ${p => p.theme.colors.commandbar.action.color};
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  padding: 1px 6px;
  display: block;

  svg {
    width: 16px;
    height: 16px;
  }

  svg:not([data-icon-status]):not(.lucide) {
    color: ${p => p.theme.colors.commandbar.actionIcon.color};
  }

  &:hover, &:focus, &.selected {
    background-color: ${p => p.theme.colors.commandbar.action.bgHover};
    ${ShortcutStyled} {
      opacity: 1;
    }
  }
  ${p => p.hoverDisabled && css`
    color: ${p.theme.colors.commandbar.action.color};
    &:hover {
      background-color: transparent;
    }
  `};

  mark {
    color: ${p => p.theme.colors.commandbar.highlight.color};
    background-color: ${p => p.theme.colors.commandbar.highlight.bg};
    border-radius: 3px;
  }

  .highlight {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-value="create-doc"] {
    color: ${p => p.theme.colors.commandbar.createDoc.color};

    &:hover, &:focus, &.selected {
      background-color: hsla(var(--cycle400), 0.2);

      ${ShortcutComponents.Key} {
        background-color: hsla(var(--cycle400), 0.2);
        color: ${p => p.theme.colors.commandbar.createDoc.color};
      }
    }

    svg {
      color: ${p => p.theme.colors.commandbar.createDoc.color};
      width: 8px;
      height: 8px;
    }
  }
`;

export const Top = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 10px;
`;

export const TitleWithHighlight = styled.p`
  text-align: left;
`;

export const TextWithHighlight = styled.p`
  text-align: left;
  padding-left: 35px;
  padding-bottom: 6px;
  color: ${p => p.theme.colors.text.secondary};
`;
