import { Emoji, Tooltip } from '@cycle-app/ui';
import { useMemo, useState } from 'react';

import { BoardDefaultIcon } from 'src/components/BoardDefaultIcon';
import { BoardStarButton } from 'src/components/BoardHeader/BoardStarButton';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ViewContextMenu } from 'src/components/ViewContextMenu';
import { ViewsPanel } from 'src/components/ViewsPanel';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { usePageId } from 'src/hooks/usePageId';
import { useBoardId, useDashboardId } from 'src/hooks/usePathParams';
import { usePrevious } from 'src/hooks/usePrevious';
import { useGetPermission } from 'src/reactives';
import { SectionParent, getSectionType } from 'src/utils/section.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { DoubleCaret, ViewButton } from './ViewBreadcrumb.styles';

export const ViewDropdown = ({ sectionParent }: { sectionParent: SectionParent }) => {
  const pageId = usePageId();
  const currentBoardId = useBoardId();
  const currentDashboardId = useDashboardId();
  const previousBoardId = usePrevious(currentBoardId);
  const boardId = currentBoardId ?? previousBoardId;
  const { boards } = useSectionBoards(sectionParent);
  const { isPermissionInit } = useGetPermission();
  const [contextMenu, setContextMenu] = useState<{ clientX: number; clientY: number } | null>(null);
  
  const options = useMemo(() => {
    return boards
      .map(board => ({
        ...board,
        slug: getBoardSlug(board), 
      }))
      // Sort by specificity, in instance: `analyses/id` should be before `analyses`
      .sort((a, b) => b.slug.length - a.slug.length);
  }, [boards]);

  const selectedOption = options.find(o => (
    o.slug === pageId ||
      (boardId && o.slug.includes(boardId)) ||
      (currentDashboardId && o.slug.includes(currentDashboardId))
  ));

  const board = boards.find(b => b.id === boardId);

  if (!selectedOption) return null;

  return (
    <>
      <ToggleDropdown
        placement="bottom-start"
        withWrapper={false}
        content={props => (
          <ViewsPanel
            sectionParent={sectionParent}
            hide={props.hide}
          />
        )}
        button={props => (
          <ViewButton
            {...props}
            iconEnd={<DoubleCaret />}
            variant="light"
            iconStart={selectedOption.emoji ? (
              <Emoji
                emoji={selectedOption.emoji}
                color={selectedOption.color}
                size={16}
              />
            ) : (
              <BoardDefaultIcon
                sectionType={getSectionType(sectionParent)}
                color={selectedOption.color}
              />
            )}
            onContextMenu={e => {
              if (!isPermissionInit || !boardId) return;
              setContextMenu({
                clientX: e.clientX,
                clientY: e.clientY,
              });
              e.preventDefault();
            }}
          >
            <Tooltip
              content={selectedOption.description}
              placement="bottom"
              withPortal
              withWrapper={false}
              disabled={!selectedOption.description}
            >
              <span className="truncate">
                {selectedOption.name}
              </span>
            </Tooltip>
          </ViewButton>
        )}
      />

      {boardId && <BoardStarButton boardId={boardId} />}

      {board && contextMenu && (
        <ViewContextMenu
          viewId={board.id}
          type={board.type}
          clientX={contextMenu.clientX}
          clientY={contextMenu.clientY}
          hide={() => setContextMenu(null)}
          isDeleteDisabled={boards.length === 1}
        />
      )}
    </>
  );
};
