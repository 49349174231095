import { gql } from '@apollo/client';
import {
  DocBaseFragment,
  ChangeDocAttributeValueDocument,
  ChangeDocAttributeValueMutation,
  CustomAttributeDefinitionFragment,
  AddSelectStringAttributeValueDocument,
  DocNodeDocument,
  DocAttributeEdge,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { pick } from 'ramda';
import { isPresent } from 'ts-is-present';

import { Events } from 'src/constants/analytics.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useUpdateDocsGroup, useGetDocGroup, useGetGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { BoardGroup, useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useVerifyAiCreatedQuote } from 'src/hooks/doc/useVerifyAiCreatedQuote';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { customAttributeTypeData, getCurrentSelectedValuesFromDocMulti, getDocAttributeId } from 'src/utils/attributes.util';
import { getDocFromCache } from 'src/utils/cache.utils';
import { addAttributeTextValue } from 'src/utils/update-cache/attributes-cache.util';

import { useMoveDocs } from './useMoveDoc';

const tempNodeId = (id: string) => `temp-${id}`;

interface ChangeDocAttributeParams {
  attributeDefinition: CustomAttributeDefinitionFragment;
  value: string;
  textValue?: string;
  doc: DocBaseFragment;
  notCompatible?: boolean;
}

interface AddNewAttributeToDocParams extends Pick<ChangeDocAttributeParams, 'attributeDefinition'> {
  textValue: string;
  doc?: DocBaseFragment;
}

export const useAddNewAttributeToDoc = () => {
  const [changeDocAttributeValueMutation] = useSafeMutation(ChangeDocAttributeValueDocument);
  const [addSelectOptionMutation] = useSafeMutation(AddSelectStringAttributeValueDocument, {
    onCompleted: (data) => trackAnalytics(Events.AttributeValueCreated, { name: data.addSelectStringAttributeValue?.value ?? '' }),
  });

  const addNewAttributeToDoc = async ({
    doc,
    attributeDefinition,
    textValue,
  }: AddNewAttributeToDocParams) => {
    const attributeDefinitionId = attributeDefinition.id;
    const res = await addSelectOptionMutation({
      variables: {
        attributeDefinitionId,
        value: textValue,
      },
      optimisticResponse: {
        addSelectStringAttributeValue: {
          __typename: 'AttributeTextValue',
          id: 'temp-id',
          value: textValue,
        },
      },
      update(cache, { data }) {
        addAttributeTextValue(cache, attributeDefinitionId, data?.addSelectStringAttributeValue);

        if (!doc) return;

        const existingAttribute = doc.attributes.edges.map(e => e.node.definition.id).includes(attributeDefinition.id);

        const updatedAttributesEdges = existingAttribute
          ? doc.attributes.edges.map(e => {
            if (e.node.definition.id !== attributeDefinition.id) {
              return e;
            }
            return {
              __typename: e.__typename,
              node: {
                ...e.node,
                ...(e.node.__typename === 'DocAttributeSingleSelect' ? {
                  selectValue: {
                    id: data?.addSelectStringAttributeValue?.id,
                    value: textValue,
                  },
                } : {}),
                ...(e.node.__typename === 'DocAttributeMultiSelect' && e.node.selectValues ? {
                  selectValues: [
                    {
                      id: data?.addSelectStringAttributeValue?.id,
                      value: textValue,
                    },
                    ...e.node.selectValues,
                  ],
                } : {}),
              },
            };
          }).filter(isPresent) as DocAttributeEdge[]
          : [
            ...doc.attributes.edges,
            {
              __typename: 'DocAttributeEdge',
              node: {
                __typename: 'DocAttributeSingleSelect',
                definition: attributeDefinition,
                id: tempNodeId(attributeDefinition.id),
                selectValue: {
                  id: data?.addSelectStringAttributeValue?.id ?? '',
                  value: textValue,
                },
              },
            },
          ] as DocAttributeEdge[];

        cache.writeQuery({
          query: DocNodeDocument,
          data: {
            node: {
              ...doc,
              attributes: {
                ...doc.attributes,
                edges: updatedAttributesEdges,
              },
            },
          },
        });
      },
    });

    const newAttributeId = res.data?.addSelectStringAttributeValue?.id;
    const attributeDefinitionData = customAttributeTypeData[attributeDefinition.__typename];
    if (newAttributeId && doc) {
      const newValue = attributeDefinitionData.inputValue(newAttributeId);
      await changeDocAttributeValueMutation({
        variables: {
          docId: doc.id,
          attributeDefinitionId,
          value: newValue,
        },
      });
    }

    return newAttributeId;
  };

  return {
    addNewAttributeToDoc,
  };
};

export const useChangeDocAttributeValue = () => {
  const verifyAiCreatedQuote = useVerifyAiCreatedQuote();
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const { groups } = useBoardGroups();
  const { moveDocs } = useMoveDocs();
  const [changeDocAttributeValueMutation, { loading: loadingChange }] = useSafeMutation(ChangeDocAttributeValueDocument);

  const getDocGroup = useGetDocGroup();
  const updateGroup = useUpdateDocsGroup();
  const getGroup = useGetGroup();

  const changeDocAttributeValue = async ({
    attributeDefinition,
    value,
    textValue: textValueFromArgs,
    doc,
    notCompatible,
  }: ChangeDocAttributeParams) => {
    const currentDocAttribute = nodeToArray(doc.attributes).find(attr => attr.definition.id === attributeDefinition.id);
    const attributeDefinitionData = customAttributeTypeData[attributeDefinition.__typename];
    const newValue = attributeDefinitionData.inputValue(value);

    const textValue = attributeDefinition.__typename === 'AttributeSingleSelectDefinition' ||
      attributeDefinition.__typename === 'AttributeMultiSelectDefinition'
      ? attributeDefinition.values.edges.find(d => d.node.id === value)?.node.value
      : undefined;

    const currentValues = attributeDefinition.__typename === 'AttributeMultiSelectDefinition'
      ? getCurrentSelectedValuesFromDocMulti(doc, attributeDefinition)
      : [];

    const optimisticValue = attributeDefinitionData.optimistic?.(value, textValueFromArgs ?? textValue, currentValues, attributeDefinition);

    if (!optimisticValue || !newValue) return;

    const optimisticResponse: ChangeDocAttributeValueMutation = {
      changeDocAttributeValue: {
        __typename: attributeDefinitionData.docAttributeTypename,
        id: currentDocAttribute?.id ?? `temp-${crypto.randomUUID()}`,
        definition: pick(['__typename', 'id', 'name', 'color'], attributeDefinition),
        ...optimisticValue,
      },
    };

    const mutateDocAttributeValue = () => changeDocAttributeValueMutation({
      variables: {
        docId: doc.id,
        attributeDefinitionId: attributeDefinition.id,
        value: newValue,
      },
      optimisticResponse,
      update: (cache, { data }) => {
        if (!data?.changeDocAttributeValue || !doc) return;

        cache.modify({
          id: cache.identify(doc),
          fields: {
            attributes: (dirtyAttributes) => {
              const attributes = {
                ...dirtyAttributes,
                edges: dirtyAttributes.edges.filter((e: any) => e.node.__ref !== tempNodeId(attributeDefinition.id)),
              };

              const addedAttributeRef = cache.writeFragment({
                data: data.changeDocAttributeValue,
                fragment: gql`
                  fragment NewDocAttribute on DocAttribute {
                    id
                  }
                `,
              });

              // If that docAttribute already exists no need to do anything
              if (!addedAttributeRef || attributes.edges.find((e: any) => e.node.__ref === addedAttributeRef.__ref)) {
                return attributes;
              }

              return {
                ...attributes,
                edges: [{
                  __typename: 'DocAttributeEdge',
                  node: addedAttributeRef,
                }].concat(attributes.edges),
              };
            },
          },
        });
      },
    });

    const moveDoc = (): Promise<unknown> | null => {
      const docFromCache = getDocFromCache(doc.id);
      if (!docFromCache || !groups || !boardConfig) return null;

      // Make sure they have the same type, as later we need to compare them.
      const docParentId: BoardGroup['swimlaneDocId'] = docFromCache.parent?.id || null;

      const originalGroup = getDocGroup(docFromCache.id);

      if (!originalGroup) return null;

      const hiddenGroups = (
        boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
        boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
      )
        ? nodeToArray(boardConfig.docQuery.groupbyConfig.values).filter(v => v.hidden)
        : [];
      const isDocMovedToHiddenGroup = hiddenGroups.some(group => group?.propertyValue?.id === value);

      const shouldMoveToAnotherColumn =
        (
          boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
          boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
        ) &&
        boardConfig?.docQuery.groupbyConfig.property.id === attributeDefinition.id;

      if (notCompatible || isDocMovedToHiddenGroup) {
        // Remove doc from board
        updateGroup({
          groupData: originalGroup,
          updatedDocs: originalGroup.node.docs.edges.filter(edge => edge.node.id !== doc.id).map(edge => edge.node),
          boardConfigId: boardConfig.id,
        });
        return mutateDocAttributeValue();
      }

      if (!shouldMoveToAnotherColumn) return null;

      // Move doc from one column to another
      const previousAttributeId = currentDocAttribute ? getDocAttributeId(currentDocAttribute) : undefined;

      const groupIdOfNewValue = Object.keys(groups).find(groupId => {
        if (boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          return (
            groups[groupId]?.swimlaneDocId === docParentId &&
            groups[groupId]?.attributeValueId === value
          );
        }
        return groups[groupId]?.attributeValueId === value;
      });

      const groupIdOfPreviousValue = Object.keys(groups).find(groupId => {
        if (boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          return (
            groups[groupId]?.swimlaneDocId === docParentId &&
            groups[groupId]?.attributeValueId === previousAttributeId
          );
        }
        return (groups[groupId]?.attributeValueId === previousAttributeId);
      });

      if (!groupIdOfNewValue) {
        if (groupIdOfPreviousValue) {
          const previousGroup = getGroup(groupIdOfPreviousValue);
          if (previousGroup) {
            updateGroup({
              groupData: previousGroup,
              updatedDocs: previousGroup.node.docs.edges.filter(edge => doc.id !== edge.node.id).map(edge => edge.node),
              boardConfigId: boardConfig.id,
            });
          }
        }
        return mutateDocAttributeValue();
      }

      const docsFromNewGroup = Object.keys(groups[groupIdOfNewValue]?.docs ?? {}).map(docId => groups[groupIdOfNewValue]?.docs[docId]);

      return moveDocs({
        groupId: groupIdOfNewValue,
        previousGroupIds: [groupIdOfPreviousValue as string],
        docsMoved: [docFromCache],
        addedDoc: docFromCache,
        position: {
          before: docsFromNewGroup.length ? (docsFromNewGroup[0]?.id ?? '') : '',
        },
        groupByProperty,
        boardConfigId: boardConfig.id,
      });
    };

    await (moveDoc() ?? mutateDocAttributeValue());
    await verifyAiCreatedQuote(doc.id);
  };

  return {
    changeDocAttributeValue,
    loading: loadingChange,
  };
};
