import { AiIcon } from '@cycle-app/ui/icons';
import { memo, useEffect } from 'react';
import { twJoin } from 'tailwind-merge';

import { AskExample, AskPanel } from 'src/components/Ask/AskPanel';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { useAskDocSubscription } from 'src/hooks/api/useAskDocSubscription';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { closeAskPanel, openAsk, useGetAskPanel, getAskAnswers, setAskAnswers } from 'src/reactives/ask.reactive';
import { Layer } from 'src/types/layers.types';

type Props =  {
  docId: string;
  className?: string;
};

export const DocAsk = memo<Props>(({
  docId, className,
}) => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  const isAskOpen = useGetAskPanel(docId)?.isOpen;
  useAskDocSubscription(docId);

  // When the doc is closed, the subscription is off and cannot receive answers
  // When the doc is reopened, pending answers will never be completed, they must to be deleted.
  useEffect(() => {
    setAskAnswers({
      answers: Object.fromEntries(
        Object.entries(getAskAnswers().answers)
          .filter(([, answer]) => answer.askId !== docId || answer.status !== 'loading'),
      ),
    });
  }, [docId]);
  
  if (!isAskEnabled) return null;

  return (
    <DropdownLayer
      layer={Layer.Dropdown}
      withWrapper={false}
      withPortal
      hide={() => closeAskPanel(docId)}
      visible={isAskOpen}
      disabled={!isAskOpen}
      placement="top-end"
      width={590}
      className="rounded-2xl dark:outline dark:outline-grey-850"
      offsetY={12}
      content={(
        <AskPanel
          askId={docId}
          examples={examples}
        />
      )}
    >
      <button
        onClick={() => openAsk(docId)}
        className={twJoin(
          'flex size-9 cursor-pointer items-center justify-center rounded-full outline-none focus-visible:ring-2',
          'bg-white hover:bg-grey-100 dark:bg-grey-900 dark:hover:bg-grey-850',
          'border border-black/[0.08] dark:border-white/[0.08]',
          className,
        )}
        style={{
          boxShadow: '0px 2px 4px 0px rgba(32, 33, 36, 0.04), 0px 4px 6px -3px rgba(32, 33, 36, 0.04)',
        }}
      >
        <AiIcon hasGradient size={16} />
      </button>
    </DropdownLayer>
  );
});

const examples: AskExample[] = [
  {
    id: 'a',
    content: 'Can you please summarize this feedback?',
  },
  {
    id: 'b',
    content: 'What feature requests do we have in this feedback?',
  },
  {
    id: 'c',
    content: 'What are the top pain points you identified here?',
  },
  {
    id: 'd',
    content: 'Can you please list some action points based on this feedback?',
  },
];