import { Avatar, SelectPanel } from '@cycle-app/ui';
import { useMeasure } from '@cycle-app/utilities';
import { useMemo, FC } from 'react';

import { useUser } from 'src/hooks';
import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import { useActiveUsers } from 'src/hooks/api/useUsers';
import { Layer } from 'src/types/layers.types';
import { getUserLabel } from 'src/utils/users.util';

import { ToggleDropdown } from '../../DropdownLayer';
import { Caret, FieldButton, SkeletonInput } from '../Form.styles';
import { StyledSlot, Label, StyledAvatar } from './FieldAssignee.styles';

interface Props {
  className?: string;
  layer?: Layer;
  onChange: (value: string) => void;
  value: string;
  showClearOption?: boolean;
  disabled?: boolean;
}

export const FieldAssignee: FC<React.PropsWithChildren<Props>> = ({
  className, layer = Layer.DropdownModalZ3, onChange, value, showClearOption, disabled,
}) => {
  const {
    rect: itemRect,
    ref: itemRef,
  } = useMeasure<HTMLDivElement>();
  const {
    me, isLoading: isMeLoading,
  } = useMaybeMeV2({
    onCompleted: (data) => {
      if (!value && data.node?.__typename === 'Me') {
        onChange(data.node.id);
      }
    },
  });
  const {
    users,
    loading: isUsersLoading,
    loadMore,
    hasNextPage,
  } = useActiveUsers();
  const { user: selectedUser } = useUser(value);
  const options = useMemo(() => {
    const userOptions = users
      .map(user => ({
        label: getUserLabel(user, user.id === me?.id),
        value: user.id,
        icon: <Avatar user={user} size={20} />,
      }));
    const meOption = userOptions.find(o => o.value === me?.id);
    return meOption ? [
      meOption,
      ...userOptions.filter(o => o.value !== me?.id),
    ] : userOptions;
  }, [me?.id, users]);

  return (
    <div ref={itemRef}>
      <ToggleDropdown
        placement="bottom-start"
        layer={layer}
        width={itemRect?.width}
        button={props => ((isUsersLoading || isMeLoading) ? <SkeletonInput /> : (
          <FieldButton
            className={className}
            iconStart={selectedUser && <StyledAvatar user={selectedUser} size={20} />}
            disabled={disabled}
            {...props}
          >
            <StyledSlot>
              {selectedUser
                ? <Label>{getUserLabel(selectedUser)}</Label>
                : <span>Choose from list</span>}
            </StyledSlot>
            <Caret direction={props['data-active'] ? 'top' : 'bottom'} />
          </FieldButton>
        ))}
        content={props => (
          <SelectPanel
            options={options}
            selectedValue={value}
            onOptionChange={option => {
              onChange(option.value);
              props.hide();
            }}
            {...showClearOption && value && {
              onClearValue: () => {
                onChange('');
                props.hide();
              },
            }}
            infiniteScroll={{
              isLoading: isUsersLoading,
              hasMoreData: hasNextPage,
              loadMore,
            }}
          />
        )}
      />
    </div>
  );
};
