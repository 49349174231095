import { Color, SectionType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { PlaceHolderIcon, BulbLight } from '@cycle-app/ui/icons';
import { useTheme } from 'styled-components';
import { twJoin } from 'tailwind-merge';

interface Props {
  className?: string;
  sectionType: SectionType | undefined;
  color: Color | null | undefined;
}

export const BoardDefaultIcon = ({
  className, sectionType, color,
}: Props) => {
  const theme = useTheme();
  const colorValue = color ? theme.nuances[color].main : undefined;
  const style = colorValue ? { color: colorValue } : undefined;
  const iconProps = {
    className: twJoin('size-4', className),
    style,
  };
  
  if (sectionType === SectionType.Feedback) return <Icon name="feedback" {...iconProps} />;
  if (sectionType === SectionType.Insights) return <BulbLight {...iconProps} />;
  if (sectionType === SectionType.Roadmaps) return <Icon name="feature" {...iconProps} />;
  return <PlaceHolderIcon {...iconProps} />;
};
