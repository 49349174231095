import { SelectOption, ActionButton, Shortcuts, NewDocPosition } from '@cycle-app/ui';
import { AddIcon, EyeClosedIcon, PenIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { Placement } from 'tippy.js';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useGroupOptionsShortcutListener } from 'src/hooks/shortcuts/useGroupOptionsShortcutListener';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { ShortcutBoard } from 'src/types/shortcuts.types';

import { ToolbarDotsMenuLayer } from './BoardGroup.styles';

interface Props {
  groupId: string;
  dropdownPlacement?: Placement;
  onCreateDoc?: VoidFunction;
  onHideGroup?: VoidFunction;
  onRenameGroup?: VoidFunction;
  isGroupByProperty?: boolean;
  draftPosition?: NewDocPosition;
}

const BoardGroupToolbar: FC<React.PropsWithChildren<Props>> = ({
  groupId,
  dropdownPlacement = 'bottom',
  onCreateDoc,
  onRenameGroup,
  onHideGroup,
  isGroupByProperty,
  draftPosition,
}) => {
  const [isDropdownVisible, {
    setValueCallback: setIsDropdownVisible,
    setFalseCallback: hideMenu,
  }] = useOptimizedBooleanState(false);

  useGroupOptionsShortcutListener({
    groupId,
    onCreateDocTriggered: hideMenu,
    enabled: () => !!onCreateDoc,
    draftPosition,
  });

  const groupOptions: SelectOption[] = [
    ...(onRenameGroup ? [{
      value: 'rename',
      label: 'Rename',
      icon: <PenIcon />,
      onSelect: onRenameGroup,
    }] : []),
    ...(onHideGroup ? [{
      value: 'hide',
      label: 'Hide',
      icon: <EyeClosedIcon />,
      onSelect: onHideGroup,
    }] : []),
  ];

  return (
    <>
      {onCreateDoc && (
        <ActionButton
          onClick={onCreateDoc}
          tooltipPlacement="top"
          tooltip={(
            <Shortcuts
              shortcuts={[
                {
                  label: `Create ${draftPosition ? `at ${draftPosition}` : ''}`,
                  keys: shortcuts[ShortcutBoard.CreateDoc],
                },
              ]}
            />
          )}
        >
          <AddIcon />
        </ActionButton>
      )}

      {isGroupByProperty && !!groupOptions.length && (
        <ToolbarDotsMenuLayer
          visible={isDropdownVisible}
          setVisible={setIsDropdownVisible}
          placement={dropdownPlacement}
          tooltip="More"
          options={groupOptions}
        />
      )}
    </>
  );
};

export default BoardGroupToolbar;
