import { ToggleInput } from '@cycle-app/ui';

import { useFeatureTypesLinkedToFeedback } from 'src/hooks/api/queries/docTypes/useDocTypes';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { useUpdateSlackAllowEmptyDescription } from 'src/hooks/slack/useUpdateSlackAllowEmptyDescription';
import { useUpdateSlackDisableParentSelection } from 'src/hooks/slack/useUpdateSlackDisableParentSelection';
import { getDocTypeNames } from 'src/utils/docType.util';

import { ContentHeader, ContentHeading } from './SlackSettingsModal.styles';

export const AdvancedTab = () => {
  const { provider } = useSlackIntegration();
  const { updateDisableParentSelection } = useUpdateSlackDisableParentSelection();
  const { updateAllowEmptyDescription } = useUpdateSlackAllowEmptyDescription();
  const doctypes = useFeatureTypesLinkedToFeedback();
  return (
    <div>
      <ContentHeader>
        <ContentHeading>
          Customize the behavior of your integration fields
        </ContentHeading>
      </ContentHeader>
      
      <div className="mt-4 space-y-4 rounded-lg bg-grey-100 p-4 dark:bg-grey-800/50">
        {provider?.disableParentSelection != null && (
          <ToggleInput
            id="link-feature"
            className="[&>label>[data-label]]:flex-1 [&>label>[data-toggle]]:mt-1 [&>label>[data-toggle]]:self-start"
            label={(
              <div className="space-y-0.5">
                <div className="text-primary">
                  Allow direct feedback linking
                </div>
                <div className="text-secondary">
                  {`Reporters can link their submitted feedback directly to ${getDocTypeNames(doctypes)}`}
                </div>
              </div>
            )}
            checked={!provider.disableParentSelection}
            onChange={e => updateDisableParentSelection(!e.target.checked)}
          />
        )}

        {provider?.allowEmptyDescription != null && (
          <ToggleInput
            id="no-description"
            className="[&>label>[data-label]]:flex-1 [&>label>[data-toggle]]:mt-1 [&>label>[data-toggle]]:self-start"
            label={(
              <div className="space-y-0.5">
                <div className="text-primary">
                  Make descriptions mandatory
                </div>
                <div className="text-secondary">
                  When enabled, reporters must include a description for every piece of feedback they submit
                </div>
              </div>
            )}
            checked={provider.allowEmptyDescription}
            onChange={e => updateAllowEmptyDescription(e.target.checked)}
          />
        )}
      </div>
    </div>
  );
};
