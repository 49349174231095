import { CreateKeywordDocument, ProductKeywordsFragmentDoc } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCreateKeyword = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [mutate, result] = useSafeMutation(CreateKeywordDocument);
  
  const createKeyword = (value: string, { optimistic } = { optimistic: true }) => mutate({
    variables: {
      productId,
      value,
    },
    optimisticResponse: optimistic ? {
      createKeyword: {
        __typename: 'Keyword',
        id: `temp_${crypto.randomUUID()}`,
        value,
        misspellings: [],
      },
    } : undefined,
    update: (cache, { data }) => {
      const newKeyword = data?.createKeyword;
      if (!newKeyword) return;
      cache.updateFragment({
        fragment: ProductKeywordsFragmentDoc,
        fragmentName: 'ProductKeywords',
        id: productId,
      }, (prev) => produce(prev, draft => {
        draft?.keywords.edges.push({
          __typename: 'KeywordEdge',
          node: newKeyword,
        });
      }));
    },
  });

  return [createKeyword, result] as const;
};
