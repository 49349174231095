import { typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';

export const Container = styled.div<{
  asPlaceholder?: boolean;
  $collapsed?: boolean;
  $isOpen: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  opacity: ${p => (p.asPlaceholder ? 0 : 1)};
  row-gap: ${p => (p.$collapsed ? 4 : 2)}px;
  margin-bottom: 4px;
`;

export const DroppableArea = styled.div<{ isOver?: boolean }>`
  border-radius: 4px;
  background-color: ${p => (p.isOver ? p.theme.colors.sidebar.droppableArea.bg : 'transparent')};
`;

export const BoardSectionNavigationItem = styled(CollapsableNavigationItem)<{ $isOpen: boolean }>`
  ${typo.caption500};
  padding-left: 0 6px 0 14px;
  flex-grow: 0;
  gap: 6px;

  font-size: 13px;
  color: ${p => p.theme.colors.text.disabled};

  &:active, &.force-focus, &:hover, &:hover:active {
    background: none;

    > span, svg {
      color: ${p => p.theme.colors.sidebar.color};
    }
  }
`;

export const BoardNavigationItem = styled(CollapsableNavigationItem)`
  --color: ${p => p.theme.colors.sidebar.boardItem.color};

  ${mobile} {
    padding: 9px 6px 9px 14px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
