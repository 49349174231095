import { useState } from 'react';

import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardLink } from 'src/hooks/boards/useBoardLink';

export const useBoardLinkSectionField = (boardId: string) => {
  const {
    createBoardLink, removeBoardLink, 
  } = useBoardLink();

  const { mappingBoardLinks } = useBoardSections();
  const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === boardId);
  const initialBoardLinkSectionId = boardLink?.section.id ?? null;

  const [boardLinkSectionId, setBoardLinkSectionId] = useState<string | null>(initialBoardLinkSectionId);

  const updateBoardLink = () => {
    if (boardLinkSectionId !== initialBoardLinkSectionId) {
      if (boardLinkSectionId) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        createBoardLink({
          boardId: boardId,
          sectionId: boardLinkSectionId,
        });
      }
      
      if (boardLink) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        removeBoardLink({
          boardLinkId: boardLink.id,
        });
      }
    }
  };

  return {
    boardLinkSectionId,
    setBoardLinkSectionId,
    updateBoardLink,
  };
};