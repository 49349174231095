import { Icon, Tooltip } from '@cycle-app/ui';
import { useRef } from 'react';
import { twJoin } from 'tailwind-merge';

import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';

type Props = {
  docId: string;
  className?: string;
};

export const QuoteCoverMenu = ({
  docId, className, 
}: Props) => {
  const {
    updateDocCover, removeDocCover, 
  } = useDocCoverMutations(docId);
  const coverInputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div
      className={twJoin(
        'flex items-center gap-0.5 rounded-lg bg-grey-800 p-0.5 dark:bg-grey-900',
        className,
      )}
    >
      <Tooltip
        content="Update"
        placement="top"
        withPortal
        withWrapper={false}
      >
        <button
          className="btn-tertiary btn-sm btn-square size-5 text-disabled hover:text-grey-50"
          onClick={() => coverInputRef.current?.click()}
        >
          <Icon name="image" />
        </button>
      </Tooltip>
      
      <Tooltip
        content="Delete"
        placement="top"
        withPortal
        withWrapper={false}
      >
        <button
          className="btn-tertiary btn-sm btn-square size-5 text-disabled hover:text-grey-50"
          onClick={removeDocCover}
        >
          <Icon name="trash" />
        </button>
      </Tooltip>

      <CoverImageInputFile
        ref={coverInputRef}
        onCoverChanged={file => updateDocCover(file, { optimistic: true })}
      />
    </div>
  );
};
