import { CycleLoader } from '@cycle-app/ui';
import { FC, lazy, Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import PageTitleGuest from 'src/components/PageTitle/PageTitleGuest';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';
import { PageId, routing } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { useProductSlug } from 'src/hooks/usePathParams';
import { useResizeWindow } from 'src/hooks/useResizeWindow';
import { useGetAuth } from 'src/reactives/auth.reactive';
import { resetDocItem, resetDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getLastInboxBoard, getLastRoadmapBoard, getLastView } from 'src/reactives/lastView.reactive';

import { Onboarding } from '../components/Views/Onboarding';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import Auth from './Auth/Auth';
import IntegrationCallback from './IntegrationCallback/IntegrationCallback';
import { MainRoot } from './Main/MainRoot';
import { Maintenance } from './Maintenance/Maintenance';
import SSOCallback from './SSOCallback/SSOCallback';

const NewDoc = lazy(() => import('src/app/NewDoc/NewDoc'));
const VideoPage = lazy(() => import('src/components/VideoPage/VideoPage'));

interface Props {
  redirectTo?: PageId;
}

export const AppRouter: FC<React.PropsWithChildren<Props>> = ({ redirectTo }) => {
  const {
    isEnabled: isMaintenanceOff, isLoading,
  } = useFeatureFlag('maintenance-off');
  const pageId = usePageId();
  const { token } = useGetAuth();
  const productSlug = useProductSlug();
  const { navigate } = useNavigate();
  const defaultPage = token ? PageId.Main : PageId.Auth;
  const previousPageIdRef = useRef<PageId>();

  useEffect(() => {
    resetDocItem();
    resetDocItemHoverId();
    previousPageIdRef.current = pageId;
  }, [pageId]);

  useResizeWindow();

  useEffect(() => {
    const {
      productSlug: lastProductSlug,
      boardSlug: lastBoardSlug,
      section,
    } = getLastView();

    if (
      !token ||
      productSlug ||
      !lastProductSlug ||
      pageId === PageId.Callback ||
      pageId === PageId.OAuth ||
      pageId === PageId.NewDoc ||
      pageId === PageId.Video ||
      pageId === PageId.GetStarted ||
      pageId === PageId.Welcome ||
      pageId === PageId.SSO
    ) {
      return;
    }

    if (section === 'releases') {
      navigate(PageId.Releases, {
        productSlug: lastProductSlug,
      });
      return;
    }

    if (section === 'feedback' && lastBoardSlug) {
      navigate(PageId.InboxView, {
        productSlug: lastProductSlug,
        boardSlug: getLastInboxBoard().boardSlug,
      });
      return;
    }

    if (section === 'roadmaps' && lastBoardSlug) {
      navigate(PageId.RoadmapView, {
        productSlug: lastProductSlug,
        boardSlug: getLastRoadmapBoard().boardSlug,
      });
      return;
    }

    if (section === 'custom' && lastBoardSlug) {
      navigate(PageId.Board, {
        productSlug: lastProductSlug,
        boardSlug: lastBoardSlug,
      });
      return;
    }

    navigate(PageId.Main, {
      productSlug: lastProductSlug,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageId, navigate]);

  if (!isMaintenanceOff && !isLoading) return <Maintenance />;

  return (
    <>
      <PageTitleGuest />
      <Switch>
        <Route path={routing[PageId.GetStarted]}>
          <Onboarding />
        </Route>
        <Route path={routing[PageId.Welcome]}>
          <Onboarding />
        </Route>
        <Route path={routing[PageId.Callback]}>
          <IntegrationCallback />
        </Route>
        <Route path={routing[PageId.SSO]}>
          <SSOCallback />
        </Route>
        <ProtectedRoute path={routing[PageId.Main]}>
          <MainRoot />
        </ProtectedRoute>
        <ProtectedRoute path={routing[PageId.NewDoc]}>
          <ErrorBoundary>
            <Suspense fallback={<CycleLoader />}>
              <NewDoc />
            </Suspense>
          </ErrorBoundary>
        </ProtectedRoute>
        <Route path={routing[PageId.Video]}>
          <ErrorBoundary>
            <Suspense fallback={<CycleLoader />}>
              <VideoPage />
            </Suspense>
          </ErrorBoundary>
        </Route>
        <Route path={routing[PageId.Auth]}>
          <Auth />
        </Route>
        <Redirect to={routing[redirectTo || defaultPage]} />
      </Switch>
    </>
  );
};
