import barbie from '../../assets/hero-barbie.webp';
import bento from '../../assets/hero-bento.webp';
import folder from '../../assets/hero-folder.webp';
import gift from '../../assets/hero-gift.webp';
import ink from '../../assets/hero-ink.webp';
import moon from '../../assets/hero-moon.webp';
import postcards from '../../assets/hero-postcards.webp';
import postit from '../../assets/hero-postit.webp';
import sky from '../../assets/hero-sky.webp';
import sticker from '../../assets/hero-sticker.webp';
import updates from '../../assets/hero-updates.webp';
import waterfall from '../../assets/hero-waterfall.webp';

export const heroImageSuggestions = [
  updates,
  bento,
  folder,
  gift,
  ink,
  moon,
  postcards,
  postit,
  sky,
  sticker,
  waterfall,
  barbie,
];
