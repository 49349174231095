import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { AddIcon, DuplicateIcon } from '@cycle-app/ui/icons';

import { InsightCreate } from 'src/components/InsightCreate';
import { useDocInsights } from 'src/hooks';
import { insightName } from 'src/utils/doc.util';

import { InsightsList } from './InsightsList';
import { Buttons, ActionButtons, AddButton, Action, AddInsightButton } from './InsightsList.styles';
import { copyQuotesToClipboard } from './InsightsList.utils';

type FeedbackInsightListProps = {
  doc: DocBaseFragment;
};

export const FeedbackInsightList = ({ doc }: FeedbackInsightListProps) => {
  const {
    insights, isLoading,
  } = useDocInsights(doc.id);

  return (
    <div className="flex h-full flex-col items-stretch gap-2 overflow-hidden">
      <InsightsList
        insights={insights}
        isLoading={isLoading}
        docTargetsAiCount={doc.docTargetsAiCount}
        modal={insights.length > 0 ? (
          <>
            <ActionButtons>
              <Action
                size="L"
                tooltip="Copy all quotes"
                tooltipPlacement="top"
                onClick={() => copyQuotesToClipboard(insights)}
              >
                <DuplicateIcon />
              </Action>
            </ActionButtons>

            <InsightCreate
              feedbackDoc={doc}
              button={props => (
                <AddButton
                  onClick={props.onClick}
                  forceFocus={props.isFocus}
                  disabled={props.isDisabled}
                >
                  <AddIcon size={12} />
                  {`Add ${insightName()}`}
                </AddButton>
              )}
            />
          </>
        ) : (
          <Buttons>
            <InsightCreate
              feedbackDoc={doc}
              button={props => (
                <AddInsightButton
                  onClick={props.onClick}
                  forceFocus={props.isFocus}
                  $isFocus={props.isFocus}
                  $isFullWidth
                  disabled={props.isDisabled}
                >
                  <AddIcon size={12} />
                  {`Add ${insightName()}`}
                </AddInsightButton>
              )}
            />
          </Buttons>
        )}
      />
    </div>
  );
};
