import { UpdateDocCoverDocument, RemoveDocCoverDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from '../../useSafeMutation';

export default function useDocCoverMutations(docId: string, options?: { onUpdateStart?: VoidFunction; onUpdateSuccess?: VoidFunction }) {
  const [updateDocCoverMutation, { loading: isUploadingCover }] = useSafeMutation(UpdateDocCoverDocument);
  const [removeDocCoverMutation] = useSafeMutation(RemoveDocCoverDocument);

  const updateDocCover = useCallback((
    cover: File | string,
    { optimistic } = { optimistic: false },
  ) => {
    options?.onUpdateStart?.();
    const optimisticCoverUrl = typeof cover === 'string' || !optimistic ? null : URL.createObjectURL(cover);
    return updateDocCoverMutation({
      variables: {
        docId,
        image: typeof cover === 'string'
          ? { avatarUrl: cover }
          : { avatar: cover },
      },
      optimisticResponse: optimisticCoverUrl ? {
        updateDocCover: {
          __typename: 'Doc',
          id: docId,
          cover: {
            __typename: 'Image',
            id: 'temp-id',
            url: optimisticCoverUrl,
          },
        },
      } : undefined,
      onCompleted: (data) => {
        if (optimisticCoverUrl) {
          URL.revokeObjectURL(optimisticCoverUrl);
        }
        if (options?.onUpdateSuccess && data.updateDocCover?.id) {
          options.onUpdateSuccess();
        }
      },
    });
  }, [updateDocCoverMutation, docId, options]);

  const removeDocCover = useCallback(() => removeDocCoverMutation({
    variables: { docId },
    optimisticResponse: {
      removeDocCover: {
        __typename: 'Doc',
        id: docId,
        cover: null,
      },
    },
  }), [removeDocCoverMutation, docId]);

  return {
    updateDocCover,
    removeDocCover,
    isUploadingCover,
  };
}
