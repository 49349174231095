import { PropsWithChildren } from 'react';

import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { closePanel } from 'src/reactives/docRightPanel.reactive';

type Props = PropsWithChildren<{
  src: string;
}>;

export const QuoteImage = ({
  src, children, 
}: Props) => {
  const getZoomableProps = useZoomableProps();
  return (
    <div className="group relative flex-none">
      <img
        width={120}
        height={100}
        className="block rounded-md object-cover shadow-z2 outline outline-1 outline-black/5 dark:shadow-none dark:outline-grey-100/5"
        {...getZoomableProps({
          src: src,
          onMouseDown: closePanel,
        })}
      />
      {children}
    </div>
  );
};