import { useSlackIntegration } from './useSlackIntegration';

export const useSlackNotifications = () => {
  const { provider } = useSlackIntegration();
  return {
    feedbackCreatedNotification: !!provider?.feedbackCreatedNotification,
    feedbackProcessedNotification: !!provider?.feedbackProcessedNotification,
    statusNotStartedNotification: !!provider?.statusNotStartedNotification,
    statusStartedNotification: !!provider?.statusStartedNotification,
    statusCompletedNotification: !!provider?.statusCompletedNotification,
    statusCanceledNotification: !!provider?.statusCanceledNotification,
    loopCloseNotification: !!provider?.loopClosedNotification,
  };
};
