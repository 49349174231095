import { Helmet } from 'react-helmet-async';

import { APP_NAME, PAGE_TITLE_SEPARATOR } from 'src/constants/app.constants';
import { PageId } from 'src/constants/routing.constant';
import { usePageId } from 'src/hooks/usePageId';

const PageTitleGuest = () => {
  const pageId = usePageId();
  const pageTitle = [
    ...(pageId === PageId.Login ? ['Login'] : []),
    ...(pageId === PageId.Welcome ? ['Welcome'] : []),
    APP_NAME,
  ];
  return (
    <Helmet>
      <title>{pageTitle.join(PAGE_TITLE_SEPARATOR).trim()}</title>
    </Helmet>
  );
};

export default PageTitleGuest;
