import { Tooltip, CompanyLogo, CustomerAvatar, Icon } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';

import { Trigger, Label, Text, CompanyLabel, CustomerLabel } from './DocCompanyCustomer.styles';
import type { DocCompanyCustomerProps } from './DocCompanyCustomer.types';
import { DocCompanyCustomerModalContent } from './DocCompanyCustomerModalContent';


export const DocCompanyCustomer = ({
  canRemoveCustomer = false,
  doc,
  isDisabled = false,
  isCompact = false,
  isReadOnly = isDisabled,
  showArrow = false,
  showCompanyName,
  size,
  children,
  layer,
  withWrapper,
  className,
}: DocCompanyCustomerProps) => {
  const [showChangeCustomer, {
    setTrueCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);

  if (!doc) return null;

  const arrow = showArrow ? (
    <DownIcon
      size={14}
      direction={showChangeCustomer ? 'top' : 'bottom'}
      style={{ marginLeft: 8 }}
    />
  ) : null;

  const getContent = () => {
    const singleCustomer = doc.customer?.company && doc.customer.company.countCustomers === 1;
    const name = singleCustomer ? doc.customer?.company?.name : doc.customer?.displayName;

    if (doc.customer?.company?.id) {
      return (
        <>
          <CompanyLogo company={doc.customer.company} size={size === 'S' ? 'XS' : 'S'} />
          {!isCompact && (
            <CustomerLabel $size={size}>
              {name}
            </CustomerLabel>
          )}

          {!isCompact && !singleCustomer && showCompanyName && (
            <>
              <Text $size={size}>from</Text>
              <CompanyLabel $size={size}>
                {doc.customer.company.name}
              </CompanyLabel>
            </>
          )}
          {arrow}
        </>
      );
    }
    if (doc.customer?.id) {
      return (
        <>
          <CustomerAvatar customer={doc.customer} size="S" />
          {!isCompact && <CustomerLabel $size={size}>{doc.customer.displayName}</CustomerLabel>}
          {arrow}
        </>
      );
    }
    if (isReadOnly) {
      return (
        <>
          <Icon
            name="user"
            className="size-[18px] bg-grey-400"
            onClick={isReadOnly ? undefined : setTrueCallback}
          />
          {!isCompact && <CustomerLabel $size={size} $muted>No customer</CustomerLabel>}
        </>
      );
    }
    return (
      <Tooltip content="Add customer" placement="top">
        <Icon
          name="user"
          className="size-[18px] bg-grey-400"
          onClick={setTrueCallback}
        />
        {!isCompact && <Label $size={size} $muted>Add customer</Label>}
        {arrow}
      </Tooltip>
    );
  };

  if (doc.customer && !showChangeCustomer) {
    return (
      <HasCustomer
        doc={doc}
        onClick={isReadOnly ? undefined : setTrueCallback}
        isReadOnly={isReadOnly}
        layer={layer}
        withWrapper={withWrapper}
        className={className}
      >
        {children ?? getContent()}
      </HasCustomer>
    );
  }

  return (
    <NoCustomer
      doc={doc}
      forceOpen={showChangeCustomer}
      onCloseChange={setFalseCallback}
      canRemoveCustomer={canRemoveCustomer}
    >
      {children ?? getContent()}
    </NoCustomer>
  );
};

type HasCustomerProps = {
  doc: DocCompanyCustomerProps['doc'];
  children: ReactNode;
  onClick?: VoidFunction;
  isReadOnly: boolean;
  layer?: Layer;
  withWrapper?: boolean;
  className?: string;
};

const HasCustomer = ({
  doc, children, onClick, isReadOnly, layer = Layer.Dropdown, withWrapper, className,
}: HasCustomerProps) => {
  return (
    <DropdownLayer
      placement="top"
      animation={false}
      layer={layer}
      withWrapper={withWrapper}
      delay={[400, 0]}
      content={(
        <DocCompanyCustomerModalContent
          doc={doc}
          isReadOnly={isReadOnly}
          onClickUpdate={onClick}
        />
      )}
    >
      <Trigger
        className={className}
        $isReadOnly={isReadOnly}
        onClick={(e) => {
          if (isReadOnly) return;
          e.preventDefault();
          e.stopPropagation();
          onClick?.();
        }}
      >
        {children}
      </Trigger>
    </DropdownLayer>
  );
};

type NoCustomerProps = {
  doc: DocCompanyCustomerProps['doc'];
  children: ReactNode;
  forceOpen: boolean;
  canRemoveCustomer: boolean;
  onCloseChange: VoidFunction;
};

const NoCustomer = ({
  doc, children, forceOpen = false, onCloseChange, canRemoveCustomer,
}: NoCustomerProps) => {
  return (
    <DocCustomer
      doc={doc}
      forceOpenEditCustomer={forceOpen}
      onCloseChange={onCloseChange}
      isRemovable={canRemoveCustomer}
      isCompact
      context="doc-item"
    >
      {children}
    </DocCustomer>
  );
};
