import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Icon, Dropdown } from '@cycle-app/ui';
import { useEffect, Fragment, useCallback, useState, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useIsFreePlan } from 'src/hooks';
import { useCheckMonthlyAiQueries } from 'src/hooks/billing/useCheckMonthlyAiQueries';
import { setTemplate, setLimitationsModal, useGetPermission, useIsMobile } from 'src/reactives';
import { actions, Action as ActionType, ActionId } from 'src/services/editor/editorActions';
import { isCustom } from 'src/utils/docType.util';

import { QuickIntegrations } from '../QuickIntegrations';
import EmbedForm from '../SlashDropdown/EmbedForm/EmbedForm';
import { FileForm } from '../SlashDropdown/FileForm/FileForm';

export interface QuickActionsProps {
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
  applyTemplate: VoidFunction;
  disabledActions?: ActionId[];
  onShowPreviewTemplate?: VoidFunction;
  onHidePreviewTemplate?: VoidFunction;
  docType?: DoctypeFragment;
}

const ACTION_SIDE_PADDING = 8;
const actionsWithDropdown = [ActionId.File, ActionId.Image, ActionId.Embed];
const quickActions = [actions[ActionId.Transcript], actions[ActionId.Ai]];

const QuickActions = ({
  applyTemplate,
  disabledActions = [],
  onShowPreviewTemplate,
  onHidePreviewTemplate,
  docType,
  showAddTemplate = false,
  showIntegrations = false,
}: QuickActionsProps) => {
  const isMobile = useIsMobile();
  const {
    canReadSettings, canUseAi,
  } = useGetPermission();
  const editor = useEditorContext(ctx => ctx.editor);
  const editorId = useEditorContext(ctx => ctx.id);
  const isReadOnly = useEditorContext(ctx => ctx.isReadOnly);
  const [dropdownToShow, setDropdownToShow] = useState<ActionId | null>(null);

  const nbAiQueries = useEditorContext(ctx => ctx.nbAiQueries);
  const {
    maxAiQueriesReached,
    openUnlimitedAiPrompt,
  } = useCheckMonthlyAiQueries(nbAiQueries);

  const hideDropdown = useCallback(() => {
    setDropdownToShow(null);
  }, []);

  // Ensure that the preview template is closed on unmount
  useEffect(() => onHidePreviewTemplate, [onHidePreviewTemplate]);

  const isFreePlan = useIsFreePlan();
  const showEditButton = !isFreePlan && !isMobile && canReadSettings;
  const embedAction = actions[ActionId.Embed];

  return (
    <div
      className="m-auto flex max-w-full flex-wrap items-start gap-2"
      style={{
        width: EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2,
        padding: `0 ${EDITOR_MARGIN}px 12px ${EDITOR_MARGIN - ACTION_SIDE_PADDING}px`,
      }}
    >
      {docType?.template && (
        <div className="group relative">
          <button
            className="btn btn-tertiary text-disabled hover:text-secondary"
            disabled={isReadOnly}
            onClick={applyTemplate}
            onMouseLeave={onHidePreviewTemplate}
            onMouseEnter={onShowPreviewTemplate}
          >
            <Icon name="ticket" />
            {`${docType.name} template`}
          </button>

          {showEditButton && (
            <button
              className="btn-secondary btn-square absolute -right-1.5 -top-1.5 hidden size-4 text-disabled hover:text-secondary group-hover:flex"
              onClick={e => {
                e.stopPropagation();
                setTemplate({
                  admin: false,
                  selectedTemplateId: docType?.template?.id,
                  mode: 'edit',
                  modalVisible: true,
                  forceCloseOnUpdate: true,
                  docTypeId: docType?.id,
                });
              }}
            >
              <Icon name="pen-fill" className="size-2.5" />
            </button>
          )}
        </div>
      )}

      {!docType?.template && showAddTemplate && !isMobile && canReadSettings && (
        <button
          className="btn btn-tertiary text-disabled hover:text-secondary"
          onClick={() => setTemplate({
            admin: false,
            selectedTemplateId: docType?.template?.id,
            mode: docType?.template ? 'edit' : 'list',
            modalVisible: true,
            docTypeId: docType?.id,
          })}
          disabled={isReadOnly}
        >
          <Icon name="ticket" />
          Add template
        </button>
      )}

      {quickActions
        .filter(action => !disabledActions.includes(action.id))
        .map(action => {
          if (action.id === ActionId.Image || action.id === ActionId.File) {
            return renderDropdown(action, (
              <FileForm
                onCancel={hideDropdown}
                type={action.id}
              />
            ));
          }

          return (
            <Fragment key={action.id}>
              {renderAction(action)}
            </Fragment>
          );
        })}

      {!isCustom(docType) && showIntegrations && <QuickIntegrations />}

      {!disabledActions.includes(ActionId.Embed) && (
        <Dropdown
          content={<EmbedForm onCancel={hideDropdown} />}
          placement="bottom-start"
          visible={dropdownToShow === embedAction.id}
          hide={hideDropdown}
          className="!w-96 p-4"
        >
          <button
            className={twJoin(
              'btn btn-tertiary gap-2 text-disabled hover:text-secondary',
              dropdownToShow === embedAction.id && 'btn-hover',
            )}
            onClick={() => onActionSelected(embedAction)}
            disabled={isReadOnly}
          >
            {embedAction.quickActionIcon ?? embedAction.icon}
            {embedAction.labelAlt || embedAction.label}
          </button>
        </Dropdown>
      )}
    </div>
  );

  function renderDropdown(action: ActionType, contentDropdown: ReactNode) {
    return (
      <Dropdown
        key={action.id}
        content={contentDropdown}
        placement="bottom-start"
        visible={dropdownToShow === action.id}
        hide={hideDropdown}
        className="!w-96 p-4"
      >
        {renderAction(action)}
      </Dropdown>
    );
  }

  function renderAction(action: ActionType) {
    return (
      <button
        className={twJoin(
          'btn btn-tertiary text-disabled hover:text-secondary',
          dropdownToShow === action.id && 'btn-hover',
        )}
        key={action.id}
        onClick={() => onActionSelected(action)}
        disabled={isReadOnly}
      >
        {action.quickActionIcon ?? action.icon}
        {action.labelAlt || action.label}
      </button>
    );
  }

  function onActionSelected(action: ActionType) {
    if (actionsWithDropdown.includes(action.id)) {
      setDropdownToShow(action.id);
    }
    if (action.id === ActionId.Ai && !canUseAi) {
      setLimitationsModal({ action: 'USE_AI' });
      return;
    }
    if (action.id === ActionId.Ai && maxAiQueriesReached) {
      openUnlimitedAiPrompt();
      return;
    }
    action.toggle?.(editor, 'empty-menu', editorId);
  }
};

export default QuickActions;
