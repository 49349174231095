import { SelectPanel, ActionButton, Emoji, Icon } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useIsRoadmapsEnabled } from 'src/hooks';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardCreateFromSection } from 'src/hooks/boards/useBoardCreateFromSection';
import { useInboxSection } from 'src/hooks/inbox';
import { useRoadmapSection } from 'src/hooks/roadmap/useRoadmapSection';
import { useGetPermission } from 'src/reactives';

import { BoardDefaultIcon } from '../BoardDefaultIcon';
import { ToggleDropdown } from '../DropdownLayer';
import { SubmenuIcon } from './SectionViewOptions.styles';
import { SectionViewOptionsSubmenu } from './SectionViewOptionsSubmenu';

interface Props {
  sidebarSectionId: string;
  onMount?: VoidFunction;
  onHide?: VoidFunction;
}

type Submenu = 'inbox' | 'roadmaps' | null;

export const SectionViewOptions = ({
  onMount, onHide, sidebarSectionId,
}: Props) => {
  const [submenu, setSubmenu] = useState<Submenu>(null);
  const {
    createBoard, createBoardModal,
  } = useBoardCreateFromSection();
  const { isPermissionInit } = useGetPermission();
  if (!isPermissionInit) return null;
  return (
    <>
      <ToggleDropdown
        onMount={onMount}
        onHide={() => {
          onHide?.();
          setSubmenu(null);
        }}
        placement="bottom-start"
        content={({ hide }) => (
          <SectionViewContent
            hide={hide}
            sidebarSectionId={sidebarSectionId}
            submenu={submenu}
            setSubmenu={setSubmenu}
            createBoard={createBoard}
          />
        )}
        button={({
          onClick, ...buttonProps
        }) => (
          <ActionButton
            onClick={onClick}
            tooltip="Add view"
            tooltipPlacement="top"
            forceFocus={buttonProps['data-active']}
          >
            <AddIcon />
          </ActionButton>
        )}
      />
      {createBoardModal}
    </>
  );
};

const SectionViewContent = ({
  hide, sidebarSectionId, submenu, setSubmenu, createBoard,
}: {
  hide: VoidFunction;
  sidebarSectionId: string;
  submenu: Submenu;
  setSubmenu: (submenu: Submenu) => void;
  createBoard: ReturnType<typeof useBoardCreateFromSection>['createBoard'];
}) => {
  const {
    inbox, roadmaps, mappingBoardLinks,
  } = useBoardSections();
  const boardLinks = Object.values(mappingBoardLinks);
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const { section: inboxSection } = useInboxSection();
  const { section: roadmapSection } = useRoadmapSection();

  const setMouseEnterMenu = (value: string | null) => {
    setSubmenu(value as typeof submenu);
  };
  return (
    <SelectPanel
      onMouseEnterItem={setMouseEnterMenu}
      onMouseLeaveItem={() => setSubmenu(null)}
      hideSearch
      options={[
        {
          icon: <Icon name="feedback" />,
          value: 'inbox',
          label: 'Feedback',
          renderLabel: () => (
            <SectionViewOptionsSubmenu
              hideMenu={hide}
              hideSubmenu={() => setSubmenu(null)}
              label="Feedback"
              options={inbox
                // Omit linked views.
                .filter(board => !boardLinks.some(link => link.board.id === board.id))
                .map(board => ({
                  value: board.id,
                  icon: board.emoji
                    ? <Emoji color={board.color} emoji={board.emoji} />
                    : <BoardDefaultIcon sectionType={board.section?.type} color={board.color} />,
                  label: board.name,
                }))}
              onCreate={async () => {
                if (inboxSection?.id) {
                  await createBoard({
                    sectionId: inboxSection.id,
                    sidebarSectionId,
                  });
                }
              }}
              sidebarSectionId={sidebarSectionId}
              visible={submenu === 'inbox'}
            />
          ),
          end: <SubmenuIcon $isActive={submenu === 'inbox'} />,
        },
        ...isRoadmapsEnabled ? [{
          icon: <Icon name="feature" />,
          value: 'roadmaps',
          label: 'Features',
          renderLabel: () => (
            <SectionViewOptionsSubmenu
              hideMenu={hide}
              hideSubmenu={() => setSubmenu(null)}
              label="Features"
              options={roadmaps
                // Omit linked views.
                .filter(board => !boardLinks.some(link => link.board.id === board.id))
                .map(board => ({
                  value: board.id,
                  icon: board.emoji
                    ? <Emoji color={board.color} emoji={board.emoji} />
                    : <BoardDefaultIcon sectionType={board.section?.type} color={board.color} />,
                  label: board.name,
                }))}
              onCreate={async () => {
                if (roadmapSection?.id) {
                  await createBoard({
                    sectionId: roadmapSection.id,
                    sidebarSectionId,
                  });
                }
              }}
              sidebarSectionId={sidebarSectionId}
              visible={submenu === 'roadmaps'}
            />
          ),
          end: <SubmenuIcon $isActive={submenu === 'roadmaps'} />,
        }] : [],
      ]}
      onOptionChange={(o) => {
        setSubmenu(
          o.value === submenu
            ? null // toggle
            : o.value as typeof submenu,
        );
      }}
    />
  );
};
