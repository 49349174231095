import { AiState } from '@cycle-app/graphql-codegen';
import { CheckIcon } from '@cycle-app/ui/icons'; 

import { useUpdateDocAiState } from 'src/hooks/doc/useUpdateDocAiState';
import { getDocBaseFromCache } from 'src/utils/cache.utils';
import { isInsight } from 'src/utils/docType.util';
import { addToaster } from 'src/utils/toasters.utils';

export const useVerifyAiCreatedQuote = () => {
  const { updateDocAiState } = useUpdateDocAiState();
  return async (docId: string | null | undefined) => {
    if (!docId) return;
    const doc = getDocBaseFromCache(docId);
    if (!isInsight(doc?.doctype) || doc?.aiState !== AiState.AiCreated) return;
    await updateDocAiState(docId);
    addToaster({
      icon: <CheckIcon />,
      message: 'AI-generated quote was marked as user-verified',
    });
  };
};