import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useFeatureFlag } from 'src/hooks';
import { useAskSubscription } from 'src/hooks/api/useAskSubscription';
import { closeAskPanel, useGetAskPanel } from 'src/reactives/ask.reactive';

import { StyledDropdown } from './Ask.styles';
import { AskPanel } from './AskPanel';
import { useCycleAskExamples } from './useAskExamples';

export const Ask = () => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  const isAskOpen = useGetAskPanel('cycle')?.isOpen;
  const examples = useCycleAskExamples();
  useAskSubscription();
  if (!isAskEnabled) return null;

  // BoardConfigContextProvider is needed for the docs
  return (
    <BoardConfigContextProvider>
      <StyledDropdown
        withPortal
        placement="top-start"
        visible={isAskOpen}
        hide={() => closeAskPanel('cycle')}
        offset={[30, 30]}
        getReferenceClientRect={() => new DOMRect(0, window.innerHeight, 0, 0)}
        width={596}
        content={(
          <AskPanel
            askId="cycle"
            examples={examples}
            tip="Tip: you can mention your customers or date ranges to filter feedback"
          />
        )}
      />
    </BoardConfigContextProvider>
  );
};
