import { FilterPropertyRuleDoctypeFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { useMeasure, nodeToArray } from '@cycle-app/utilities';
import { update } from 'ramda';
import {
  FC, useCallback, useEffect, useMemo, useState, ReactNode,
} from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useBoardConfigFilterMutations from 'src/hooks/api/mutations/boardConfig/useBoardConfigFilterMutations';
import { Layer } from 'src/types/layers.types';
import { getOptionFromSelectableDoctype, disableLastSelectedOption } from 'src/utils/selectOptions.util';

import {
  Label,
  SelectedValues,
  OtherDoctypesCount,
  Caret,
} from './BoardConfigForm.styles';

interface Props {
  draftBoardConfigId: string;
  doctypesFilter: FilterPropertyRuleDoctypeFragment | undefined;
  boardID?: string;
  disabled?: boolean;
  label?: ReactNode;
  showSelectAll?: boolean;
  hideSearch?: boolean;
  isMulti?: boolean;
}
const BoardConfigFormDoctypes: FC<React.PropsWithChildren<Props>> = ({
  draftBoardConfigId,
  doctypesFilter,
  boardID,
  disabled = false,
  label,
  showSelectAll = true,
  hideSearch = false,
  isMulti = true,
}) => {
  const { addDoctypeFilterRule } = useBoardConfigFilterMutations(draftBoardConfigId, boardID);
  const {
    rect: itemRect,
    ref: itemRef,
  } = useMeasure<HTMLButtonElement>();

  const [isDoctypesVisible, setIsDoctypesVisible] = useState(false);

  const [doctypeOptions, setDoctypeOptions] = useState<SelectOption[]>(
    disableLastSelectedOption(nodeToArray(doctypesFilter?.doctypeRule.values).map(getOptionFromSelectableDoctype)),
  );
  useEffect(() => {
    const selectableDocTypes = nodeToArray(doctypesFilter?.doctypeRule.values);
    setDoctypeOptions(disableLastSelectedOption(selectableDocTypes.map(getOptionFromSelectableDoctype)));
  }, [doctypesFilter?.doctypeRule.values]);

  const selectedDoctypes: SelectOption[] = useMemo(() => doctypeOptions.filter(f => f.selected), [doctypeOptions]);

  const updateOptions = useCallback((updatedOption: SelectOption, index: number) => {
    const newFilters = update(index, updatedOption, doctypeOptions);
    setDoctypeOptions(disableLastSelectedOption(newFilters));
  }, [doctypeOptions]);

  const setDoctypesFilter = useCallback(async (options: SelectOption[]) => {
    const selectedFilter = doctypesFilter?.doctypeRule.values.edges.find(e => e.node.selected);
    const selectedFilterId = selectedFilter?.node.id;
    return addDoctypeFilterRule({
      singleSelect: {
        IS: options.map(o => o.value),
      },
    }, !isMulti ? selectedFilterId : undefined);
  }, [addDoctypeFilterRule, doctypesFilter, isMulti]);

  const hideDoctypes = useCallback((newOptions: SelectOption[] = doctypeOptions) => {
    setIsDoctypesVisible(false);
    /**
     * That mutation requires some api calculation so we need to avoid calling it each time
     * an doctype is selected/unselected
    */
    return setDoctypesFilter(newOptions.filter(o => o.selected));
  }, [doctypeOptions, setDoctypesFilter]);
  const toggleDoctypes = useCallback(() => (isDoctypesVisible
    ? hideDoctypes()
    : setIsDoctypesVisible(true)),
  [isDoctypesVisible, hideDoctypes]);

  const onSelectAll = useCallback(() => setDoctypeOptions(doctypeOptions.map(o => ({
    ...o,
    selected: true,
  }))), [doctypeOptions]);
  const onUnSelectAll = useCallback(() => setDoctypeOptions(doctypeOptions.map(o => ({
    ...o,
    selected: false,
  }))), [doctypeOptions]);

  return (
    <>
      {Boolean(label) && <Label>{label}</Label>}
      <DropdownLayer
        layer={Layer.DropdownModal}
        visible={isDoctypesVisible}
        hide={!isMulti ? () => setIsDoctypesVisible(false) : hideDoctypes}
        placement="bottom-start"
        width={itemRect?.width}
        content={isMulti ? (
          <SelectPanel
            isMulti
            options={doctypeOptions}
            onSelectOption={updateOptions}
            onUnselectOption={updateOptions}
            toggleAllValuesVariant="toggle"
            hideSearch={hideSearch}
            {...showSelectAll && {
              onSelectAll,
              onUnSelectAll,
            }}
          />
        ) : (
          <SelectPanel
            isMulti={false}
            options={doctypeOptions}
            onOptionChange={updatedOption => {
              const newFilters = doctypeOptions.map(option => ({
                ...option,
                selected: option.value === updatedOption.value,
              }));
              setDoctypeOptions(newFilters);
              return hideDoctypes(newFilters);
            }}
            hideSearch={hideSearch}
          />
        )}
      >
        <SelectedValues
          type="button"
          ref={itemRef}
          onClick={toggleDoctypes}
          forceFocus={isDoctypesVisible}
          disabled={disabled || !doctypesFilter?.isEditable}
        >
          {selectedDoctypes.length === 0
            ? <span>All doc types</span>
            : (
              <>
                {selectedDoctypes[0]?.icon}
                <span>{selectedDoctypes[0]?.label}</span>
                {isMulti && selectedDoctypes.length > 1 && (
                  <OtherDoctypesCount>
                    +
                    {selectedDoctypes.length - 1}
                  </OtherDoctypesCount>
                )}
              </>
            )}
          <Caret direction={isDoctypesVisible ? 'top' : 'bottom'} />
        </SelectedValues>
      </DropdownLayer>
    </>
  );
};

export default BoardConfigFormDoctypes;
