import { Color } from '@cycle-app/graphql-codegen';

import shipADark from '../assets/ship-a-dark.png';
import shipALight from '../assets/ship-a-light.png';
import shipBDark from '../assets/ship-b-dark.png';
import shipBLight from '../assets/ship-b-light.png';
import shipCDark from '../assets/ship-c-dark.png';
import shipCLight from '../assets/ship-c-light.png';
import shipDDark from '../assets/ship-d-dark.png';
import shipDLight from '../assets/ship-d-light.png';
import shipEDark from '../assets/ship-e-dark.png';
import shipELight from '../assets/ship-e-light.png';
import shipFDark from '../assets/ship-f-dark.png';
import shipFLight from '../assets/ship-f-light.png';
import shipGDark from '../assets/ship-g-dark.png';
import shipGLight from '../assets/ship-g-light.png';
import shipHDark from '../assets/ship-h-dark.png';
import shipHLight from '../assets/ship-h-light.png';
import shipIDark from '../assets/ship-i-dark.png';
import shipILight from '../assets/ship-i-light.png';
import shipJDark from '../assets/ship-j-dark.png';
import shipJLight from '../assets/ship-j-light.png';
import shipKDark from '../assets/ship-k-dark.png';
import shipKLight from '../assets/ship-k-light.png';

type ShipOption = {
  color: string;
  darkBadge: string;
  lightBadge: string;
};

export const shipOptions: Record<Color, ShipOption> = {
  [Color.A]: {
    color: '#2C45E8',
    darkBadge: shipADark,
    lightBadge: shipALight,
  },
  [Color.B]: {
    color: '#7B40F2',
    darkBadge: shipBDark,
    lightBadge: shipBLight,
  },
  [Color.C]: {
    color: '#3F8FF7',
    darkBadge: shipCDark,
    lightBadge: shipCLight,
  },
  [Color.D]: {
    color: '#09BB90',
    darkBadge: shipDDark,
    lightBadge: shipDLight,
  },
  [Color.E]: {
    color: '#F7CF3F',
    darkBadge: shipEDark,
    lightBadge: shipELight,
  },
  [Color.F]: {
    color: '#EA9E10',
    darkBadge: shipFDark,
    lightBadge: shipFLight,
  },
  [Color.G]: {
    color: '#EA3C10',
    darkBadge: shipGDark,
    lightBadge: shipGLight,
  },
  [Color.H]: {
    color: '#F240AB',
    darkBadge: shipHDark,
    lightBadge: shipHLight,
  },
  [Color.I]: {
    color: '#DA40F2',
    darkBadge: shipIDark,
    lightBadge: shipILight,
  },
  [Color.J]: {
    color: '#404040',
    darkBadge: shipJDark,
    lightBadge: shipJLight,
  },
  [Color.K]: {
    color: '#F0F0F0',
    darkBadge: shipKDark,
    lightBadge: shipKLight,
  },
  [Color.L]: {
    color: '',
    darkBadge: '',
    lightBadge: '',
  },
};
