import { BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen';
import { Tooltip, Icon } from '@cycle-app/ui';
import { HTMLAttributes, useMemo, useState, memo } from 'react';

import { useNavigate, useSidebarCollapsed } from 'src/hooks';
import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useIsBoardStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';
import { useOpenBoardConfig } from 'src/hooks/boards/useOpenBoardConfig';
import { useIsBoardActive } from 'src/hooks/useIsBoardActive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { LocationState } from 'src/types/routes.types';
import { getBoardUrl, getViewPageId } from 'src/utils/routing.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { BoardNavigationItem } from '../SidebarBoardSection.styles';
import { CustomViewIcon } from './CustomViewIcon';
import { StyledBoardOptions, More, StarButton } from './SidebarItemBoard.styles';

export interface Props extends HTMLAttributes<HTMLElement> {
  board: BoardWithMinimalConfigFragment;
  asPlaceholder?: boolean;
  isDragging?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  activeAlpha?: number;
  placeholderScale?: [number, number];
  linkState?: LocationState;
}

const SidebarItemBoard = memo(({
  board,
  asPlaceholder,
  isDragging,
  placeholderScale,
  activeAlpha,
  linkState,
  ...htmlProps
}: Props) => {
  const openBoardConfig = useOpenBoardConfig();
  const { unStar } = useBoardStar();
  const isBoardStarred = useIsBoardStarred();
  const isMobile = useIsMobile();
  const collapsed = useSidebarCollapsed();
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const { navigate } = useNavigate();

  const isActive = useIsBoardActive({
    boardId: board.id,
    isStarred: !!linkState?.fromStarredBoard,
  });

  const icon = useMemo(() => {
    return (
      <CustomViewIcon
        viewId={board.id}
        color={board.color}
        emoji={board.emoji}
        sectionType={board.section?.type}
      />
    );
  }, [board.color, board.emoji, board.id, board.section?.type]);

  const contentMore = useMemo(() => (
    <More>
      {isBoardStarred(board.id) === true && (
        <Tooltip content="Unstar" placement="top">
          <StarButton
            onClick={(e) => {
              e.preventDefault();
              return unStar(board.id);
            }}
          >
            <Icon name="star-fill" />
          </StarButton>
        </Tooltip>
      )}
      <StyledBoardOptions
        boardId={board.id}
        boardType={board.type}
        onColoredBg={isActive}
        onClick={() => setMenuIsVisible(true)}
        onHide={() => setMenuIsVisible(false)}
        openBoardConfig={() => openBoardConfig(board.id)}
        boardConfigId={board.publishedBoardConfig?.id}
      />
    </More>
  ), [board, isActive, isBoardStarred, openBoardConfig, unStar]);

  if (!board.section?.type) return null;

  const isDraft = board.draftBoardConfig != null;

  return (
    <BoardNavigationItem
      {...htmlProps}
      linkId={linkState?.fromStarredBoard ? `${board.id}--starred` : board.id}
      key={board.id}
      label={board.name}
      icon={icon}
      iconColor={board.color || undefined}
      href={getBoardUrl(board.section?.type, getBoardSlug(board))}
      onClick={(e) => {
        if (!board.section?.type) return;
        navigate(
          getViewPageId(board.section.type),
          { boardSlug: getBoardSlug(board) },
          linkState,
          e.metaKey,
        );
      }}
      isStarredBoard={!!linkState?.fromStarredBoard}
      isActive={isActive}
      isChild
      isFocus={menuIsVisible}
      warning={isDraft ? 'Unsaved changes' : null}
      contentMore={contentMore}
      asPlaceholder={asPlaceholder}
      isDragging={isDragging}
      isSidebarColor
      placeholderScale={placeholderScale}
      activeAlpha={activeAlpha}
      hasArrowRight={isMobile}
      collapsed={collapsed}
    />
  );
});

export default SidebarItemBoard;
