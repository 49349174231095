import { Button, Input, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { PortalModal } from 'src/components/PortalModal';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
  label {
    margin-bottom: 8px;
  }
`;

export const FormInput = styled(Input)`
  width: 100%;
`;

export const StyledDotsMenuLayer = styled(DotsMenuLayer)`
  &.force-focus, &:hover {
    color: ${p => p.theme.colors.text.primary};
    background-color: ${p => p.theme.colors.settings.navigationItem.bgHover};
  }
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const CompanyActionSelect = styled(CustomerCompanyAction)`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  gap: 4px;
  justify-content: space-between;
  padding: 7px 8px;
  width: 100%;
  ${typo.body400};

  &:hover, &:active, &:focus {
    background: transparent;
  }
`;

export const CompanyActionSelectLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.primary};
`;


export const InputContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UploadNewBtn = styled(Button).attrs({ size: 'M' })`
  white-space: nowrap;
`;

export const RemoveBtn = styled(Button).attrs({
  size: 'M',
  variant: 'nospace',
})`
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary}
`;

export const CustomerModal = styled(PortalModal)`
  min-width: 450px;
  width: 450px;
  padding: 16px;

  @media (max-width: 500px) {
    min-width: unset;
    min-height: unset;
    max-width: unset;
    max-height: unset;
    width: 100vw;
    height: 100vh;
    border: none;
    border-radius: 0; 
  }
`;
