import { Emoji, Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import { BoardDefaultIcon } from 'src/components/BoardDefaultIcon';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { useCreateViewModal } from 'src/hooks/useCreateViewModal';
import { navigate } from 'src/hooks/useNavigate';
import { useGetPermission, useIsMobile } from 'src/reactives';
import { getUrl } from 'src/utils/routing.utils';
import { SectionParent, getSectionName, getViewPageId, getSectionType } from 'src/utils/section.utils';
import { getBoardSlug } from 'src/utils/slug.util';
import { getSectionName as getViewSectionName } from 'src/utils/viewSection.utils';

type Props = {
  sectionParent: SectionParent;
  hide: VoidFunction;
};

export const ViewsPanel = ({
  sectionParent, hide,
}: Props) => {
  const isMobile = useIsMobile();

  const { boards } = useSectionBoards(sectionParent);
  
  const boardsWithSlug = boards.map(board => ({
    ...board,
    slug: getBoardSlug(board),
  }));

  const {
    mappingBoardLinks, sections,
  } = useBoardSections();

  const {
    isPermissionInit, canCreateView,
  } = useGetPermission();

  const {
    openModal, modal,
  } = useCreateViewModal({
    sectionParent,
    onHide: hide,
  });

  const {
    listProps,
    itemProps,
    hovered,
  } = useListNav({
    optionsValues: boardsWithSlug.map(board => board.slug),
    value: boardsWithSlug[0]?.slug ?? null,
    onSelect: (boardSlug) => {
      hide();
      if (!boardSlug) return;
      navigate(getViewPageId(sectionParent), { boardSlug });
    },
    selectOnHover: true,
  });

  return (
    <>
      <div className="w-[420px] max-w-full">
        <div className="flex items-center justify-between border-b border-grey-200 py-2.5 pl-4 pr-2 dark:border-grey-800">
          <div className="font-medium">
            {getSectionName(sectionParent, true)}
          </div>

          {!isMobile && isPermissionInit && canCreateView && (
            <button
              className="btn btn-tertiary text-cycle dark:text-primary"
              onClick={() => openModal(sectionParent)}
            >
              <AddIcon size={12} />
              Add view
            </button>
          )}
        </div>

        <div className="shy-scrollbar max-h-80" {...listProps}>
          {boardsWithSlug.map(board => {
            const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === board.id);
            const sectionName = boardLink ? getViewSectionName(boardLink?.section, sections.length) : null;
            return (
              <Link
                key={board.slug}
                className={twJoin(
                  // eslint-disable-next-line max-len
                  'flex items-center gap-2 overflow-hidden border-b border-grey-200 px-4 py-2.5 hover:bg-grey-50 dark:border-grey-800 dark:hover:bg-grey-800/50',
                  hovered === board.slug && 'bg-grey-50 dark:bg-grey-800/50',
                )}
                to={getUrl(getViewPageId(sectionParent), { boardSlug: board.slug })}
                {...itemProps(board.slug)}
                onClick={hide}
              >
                {board.emoji ? (
                  <Emoji
                    emoji={board.emoji}
                    color={board.color}
                    size={16}
                  />
                ) : (
                  <BoardDefaultIcon
                    sectionType={getSectionType(sectionParent)}
                    color={board.color}
                  />
                )}

                <Tooltip
                  content={board.description}
                  placement="bottom"
                  withPortal
                  withWrapper={false}
                  disabled={!board.description}
                >
                  <div className="-mt-px truncate">
                    {board.name}
                  </div>
                </Tooltip>

                {sectionName && (
                  <div className="text-caption text-secondary">
                    {sectionName}
                  </div>
                )}
              </Link>
            );
          })}
        </div>
      </div>

      {modal}
    </>
  );
};
