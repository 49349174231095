import { UploadImageDocument, UploadVideoDocument, UploadFileDocument } from '@cycle-app/graphql-codegen';
import { getFileType, FileUploadSource, FileUploadedData } from '@cycle-app/utilities';
import { useCallback, useRef } from 'react';

import { Events } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { getFileError, isPdfLink } from 'src/utils/files.util';
import { addToaster } from 'src/utils/toasters.utils';

import { setLimitationsModal } from '../reactives';

export type UploadReturn = Promise<FileUploadedData | null>;

const useUploadFile = () => {
  const source = useRef<FileUploadSource | undefined>(undefined);

  const [uploadImageMutation, { loading: isUploadingImage }] = useSafeMutation(UploadImageDocument, {
    onCompleted: () => {
      trackAnalytics(Events.FileUploaded, {
        type: 'image',
        ...(source.current ? { source: source.current } : {}),
      });
      source.current = undefined;
    },
    onError: (error) => {
      source.current = undefined;
      logError(error);
    },
  });
  const [uploadVideoMutation, { loading: isUploadingVideo }] = useSafeMutation(UploadVideoDocument, {
    onCompleted: () => {
      trackAnalytics(Events.FileUploaded, {
        type: 'video',
        ...(source.current ? { source: source.current } : {}),
      });
      source.current = undefined;
    },
    onError: (error) => {
      source.current = undefined;
      logError(error);
    },
  });
  const [uploadFileMutation, { loading: isUploadingFile }] = useSafeMutation(UploadFileDocument, {
    onCompleted: () => {
      trackAnalytics(Events.FileUploaded, {
        type: 'file',
        ...(source.current ? { source: source.current } : {}),
      });
      source.current = undefined;
    },
    onError: (error) => {
      source.current = undefined;
      logError(error);
    },
  });

  const uploadFile = useCallback(async (file: File): UploadReturn => {
    const fileType = getFileType(file);
    const uploadFunction = {
      image: uploadImageMutation,
      // We have to use uploadImage for pdf as there is an issue with cloudinary:
      // uploadFile uploads raw files, but ocr works only on images.
      file: isPdfLink(file.name) ? uploadImageMutation : uploadFileMutation,
      video: uploadVideoMutation,
      // Use video for same reason as above
      audio: uploadVideoMutation,
    }[fileType];

    const response = await uploadFunction({ variables: { file } });

    if (!response.data?.upload) return null;

    const data = response.data.upload;

    return {
      alt: file.name,
      mime: file.type,
      size: file.size,
      title: file.name,
      type: fileType,
      src: data.url ?? '',
      width: data.width ?? undefined,
      height: data.height ?? undefined,
      id: data.id ?? undefined,
      hasAudio: data.hasAudio ?? undefined,
    };
  }, [uploadFileMutation, uploadImageMutation, uploadVideoMutation]);

  const onUpload = useCallback(async (file: File, from: FileUploadSource): UploadReturn => {
    const fileType = getFileType(file);
    const fileError = getFileError(file);

    if (fileError) {
      if (fileError.type === 'file-too-large') {
        setLimitationsModal({
          action: 'USE_ADD_ON',
          brand: 'file-size-unlimited',
        });
      } else {
        addToaster({
          title: 'An error occured',
          message: fileError.message,
        });
      }
      trackAnalytics(Events.FileUploadFailed, {
        type: fileType,
        reason: fileError.type,
        sizeInBit: file.size,
        source: from,
      });

      return null;
    }

    source.current = from;

    return uploadFile(file);
  }, [uploadFile]);

  return {
    isUploading: isUploadingImage || isUploadingFile || isUploadingVideo,
    onUpload,
    source: source.current,
  };
};

export default useUploadFile;
