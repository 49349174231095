import { make } from 'src/utils/reactives.util';

import { SlackSettingsTab } from '../types/slackSettings.types';

interface UserModal {
  visible: boolean;
  tab: SlackSettingsTab;
}

export const {
  hookState: useSlackSettingsModal,
  hookValue: useSlackGetSettingsModal,
  getValue: getSlackSettingsModal,
  setValue: setSlackSettingsModal,
} = make<UserModal>({
  defaultState: {
    visible: false,
    tab: 'ws',
  },
});

export const closeSlackSettingsModal = () => setSlackSettingsModal({ visible: false });

export const toggleSettingsModal = () => {
  setSlackSettingsModal({
    visible: !getSlackSettingsModal().visible,
    tab: 'ws',
  });
};

export const openSlackSettingsWorkspace = () => setSlackSettingsModal({
  visible: true,
  tab: 'ws',
});

export const openSlackSettingsReacjis = () => setSlackSettingsModal({
  visible: true,
  tab: 'reacjis',
});

export const openSlackSettingsNotifications = () => setSlackSettingsModal({
  visible: true,
  tab: 'notifications',
});

// export const openSlackSettingsAutomations = () => setSlackSettingsModal({
//   visible: true,
//   tab: 'automations',
// });

export const openSlackSettingsAdvanced = () => setSlackSettingsModal({
  visible: true,
  tab: 'advanced',
});