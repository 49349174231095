import { ActionButton, Shortcuts, NewDocPosition } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useGroupOptionsShortcutListener } from 'src/hooks/shortcuts/useGroupOptionsShortcutListener';
import { ShortcutBoard } from 'src/types/shortcuts.types';

interface Props {
  onCreateDoc?: VoidFunction;
  groupId: string;
  draftPosition?: NewDocPosition;
}

export const NewInboxToolbar = ({
  onCreateDoc, groupId, draftPosition,
}: Props) => {
  useGroupOptionsShortcutListener({
    groupId,
    enabled: () => !!onCreateDoc,
    draftPosition,
  });

  if (!onCreateDoc) return null;

  return (
    <ActionButton
      onClick={onCreateDoc}
      data-action="new-doc"
      tooltipPlacement="top"
      tooltip={(
        <Shortcuts
          shortcuts={[
            {
              label: `Create ${draftPosition ? `at ${draftPosition}` : ''}`,
              keys: shortcuts[ShortcutBoard.CreateDoc],
            },
          ]}
        />
      )}
    >
      <AddIcon />
    </ActionButton>
  );
};
