import {
  CreateDocDocument,
  CreateDocMutationVariables,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { refetchProfileDocs, refetchProfileDocsCount } from '../api/cache/cacheProfile';

export const useDocCreate = () => {
  const product = useProductBase();
  const [createDoc, { loading }] = useSafeMutation(CreateDocDocument, {
    onCompleted: () => {
      refetchProfileDocsCount();
      refetchProfileDocs();
    },
  });

  const create = useCallback(
    async (
      params: Omit<CreateDocMutationVariables, 'productId'>,
    ) => {
      if (!product) return null;

      const response = await createDoc({
        variables: {
          productId: product.id,
          doctypeId: params.doctypeId,
          title: params.title,
          assignee: params.assignee,
          attributes: params.attributes,
          contentJSON: params.contentJSON,
          customer: params.customer,
          parentId: params.parentId,
          source: params.source,
          ...params.docLink && {
            docLink: params.docLink,
          },
          withLinear: params.withLinear,
          productAreaIds: params.productAreaIds,
        },
      });

      return response.data?.addNewDoc || null;
    },
    [createDoc, product],
  );

  return {
    create,
    isLoading: loading,
  };
};
