import { FilterPropertyRuleFragment, SwimlaneFilterPropertyRuleFragment } from '@cycle-app/graphql-codegen';

export type FilterRule = FilterPropertyRuleFragment | SwimlaneFilterPropertyRuleFragment;

export const isFilterPropertyRuleFragment = (
  filter: FilterPropertyRuleFragment | SwimlaneFilterPropertyRuleFragment,
): filter is FilterPropertyRuleFragment => [
  'FilterPropertyRuleAssignee',
  'FilterPropertyRuleCheckbox',
  'FilterPropertyRuleCompany',
  'FilterPropertyRuleCreator',
  'FilterPropertyRuleCustomer',
  'FilterPropertyRuleDate',
  'FilterPropertyRuleDocParent',
  'FilterPropertyRuleDoctype',
  'FilterPropertyRuleEmail',
  'FilterPropertyRuleMultiSelect',
  'FilterPropertyRuleNumber',
  'FilterPropertyRulePhone',
  'FilterPropertyRuleSelectableValueCompany',
  'FilterPropertyRuleSelectableValueCustomer',
  'FilterPropertyRuleSelectableValueDoc',
  'FilterPropertyRuleSelectableValueDoctype',
  'FilterPropertyRuleSelectableValueText',
  'FilterPropertyRuleSelectableValueUser',
  'FilterPropertyRuleSingleSelect',
  'FilterPropertyRuleStatus',
  'FilterPropertyRuleText',
  'FilterPropertyRuleUrl',
  'FilterPropertyRuleSource',
  'FilterPropertyRuleProductArea',
].includes(filter.__typename);
