import { useSubscription } from '@apollo/client';
import { ReleaseSubscriptionDocument, ReleaseNotesDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { RELEASE_NOTES_INITIAL_PAGINATION_SIZE } from 'src/constants/releases.constants';

export const useReleaseSubscription = (releaseId?: string | null) => {
  return useSubscription(ReleaseSubscriptionDocument, {
    variables: {
      id: releaseId as string,
    },
    onData: ({
      data: { data },
      client: { cache },
    }) => {
      const releaseNotes = data?.release?.releaseNotes;
      if (!releaseNotes || !releaseId) return;

      cache.updateQuery({
        query: ReleaseNotesDocument,
        variables: {
          releaseId,
          isOther: false,
          cursor: '',
          size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
        },
      }, prev => produce(prev, draft => {
        if (draft?.node?.__typename !== 'Release') return;
        const edges = releaseNotes.edges.filter((e) => e.node.isOther === false);
        const subscriptionIds = edges.map((e) => e.node.id).join(',');
        const existingIds = draft.node.releaseNotes.edges.filter((e) => e.node.isOther === false).map((e) => e.node.id).join(',');
        // Equals and same order. It can conflict with the cache if in instance new note was already added.
        if (subscriptionIds === existingIds) return;
        draft.node.releaseNotes.edges = edges;
      }));

      cache.updateQuery({
        query: ReleaseNotesDocument,
        variables: {
          releaseId,
          isOther: true,
          cursor: '',
          size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
        },
      }, prev => produce(prev, draft => {
        if (draft?.node?.__typename !== 'Release') return;
        const edges = releaseNotes.edges.filter((e) => e.node.isOther === true);
        const subscriptionIds = edges.map((e) => e.node.id).join(',');
        const existingIds = draft.node.releaseNotes.edges.filter((e) => e.node.isOther === true).map((e) => e.node.id).join(',');
        if (subscriptionIds === existingIds) return;
        draft.node.releaseNotes.edges = edges;
      }));
    },
  });
};
