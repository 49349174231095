import { SectionType } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import BoardEditCommonModal from 'src/components/BoardEditCommonModal/BoardEditCommonModal';
import { BoardEditCommonFormData } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { useProduct } from 'src/hooks';
import useForkBoardMutation from 'src/hooks/api/mutations/useForkBoardMutation';
import {
  useMoveFeedbackViewToSectionEnd,
  useMoveRoadmapViewToSectionEnd,
} from 'src/hooks/api/mutations/useMoveViewToSectionEnd';
import { useBoard } from 'src/hooks/api/useBoard';
import { useBoardLink } from 'src/hooks/boards/useBoardLink';
import { useNavigate } from 'src/hooks/useNavigate';
import { getViewPageId } from 'src/utils/routing.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { BoardLinkSectionField } from '../BoardLinkSectionField';

interface DuplicateBoardModalProps {
  boardId: string;
  onHide: VoidFunction;
  boardConfigId?: string;
  onDone?: VoidFunction;
  showViewEmoji?: boolean;
}

const DuplicateBoardModal = ({
  boardId, onHide, onDone, showViewEmoji, ...props
}: DuplicateBoardModalProps) => {
  const { product } = useProduct();
  const board = useBoard(boardId);
  const { navigate } = useNavigate();

  const moveFeedbackViewToSectionEnd = useMoveFeedbackViewToSectionEnd();
  const moveRoadmapViewToSectionEnd = useMoveRoadmapViewToSectionEnd();

  const [boardLinkSectionId, setBoardLinkSectionId] = useState<string | null>(null);
  const { createBoardLink } = useBoardLink();

  const {
    forkBoardFromBoardConfig,
    loadingForkBoard,
  } = useForkBoardMutation(board?.id);

  if (!board) return null;

  return (
    <BoardEditCommonModal
      defaultValues={{
        ...board,
        name: `Copy of ${board.name}`,
      }}
      sectionType={board.section?.type}
      title="Duplicate view"
      onHide={onHide}
      onSubmit={onSubmit}
      loading={loadingForkBoard}
      showViewEmoji={showViewEmoji}
    >
      <BoardLinkSectionField
        boardId={boardId}
        value={boardLinkSectionId}
        onChange={setBoardLinkSectionId}
      />
    </BoardEditCommonModal>
  );

  async function onSubmit({
    name, description, emoji, color,
  }: BoardEditCommonFormData) {
    const section = product?.boardSections.edges.find(e => e.node.boards.edges.some(b => b.node.id === boardId))?.node;
    if (!section) return;

    const boardConfigId = props.boardConfigId ?? board?.publishedBoardConfig?.id;
    if (!boardConfigId) return;

    const mutation = await forkBoardFromBoardConfig({
      boardConfigId,
      name,
      description,
      emoji,
      color,
    });

    const createdBoard = mutation.data?.addBoardFromBoardConfig;
    if (createdBoard && boardLinkSectionId) {
      await createBoardLink({
        boardId: createdBoard.id,
        sectionId: boardLinkSectionId,
      });
    }

    onHide();
    onDone?.();

    const viewId = mutation.data?.addBoardFromBoardConfig?.id;

    if (viewId && section.type === SectionType.Feedback) await moveFeedbackViewToSectionEnd(viewId);
    if (viewId && section.type === SectionType.Roadmaps) await moveRoadmapViewToSectionEnd(viewId);

    const boardConfig = mutation.data?.addBoardFromBoardConfig;
    if (boardConfig == null) return;

    const boardSlug = getBoardSlug(boardConfig);
    navigate(getViewPageId(section.type), { boardSlug });
  }
};

export default DuplicateBoardModal;
