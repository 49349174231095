import { CustomerFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { PageId } from 'src/constants/routing.constant';
import { useCustomerRemove } from 'src/hooks/api/mutations/customers/useCustomerRemove';
import { closeCustomerModal, getCustomerIdParam } from 'src/hooks/modals/useCustomerModal';
import { navigate } from 'src/hooks/useNavigate';
import { getPageId } from 'src/utils/routing.utils';

interface CustomerUpdateModalProps {
  onClose: () => void;
  customer: CustomerFragment;
}

export const CustomerRemoveModal = ({
  onClose, customer,
}: CustomerUpdateModalProps) => {
  const {
    remove, isLoading,
  } = useCustomerRemove(customer);

  const onSubmit = useCallback(async () => {
    if (isLoading) return;

    if (getPageId() === PageId.Customer) {
      navigate(PageId.Main);
    } else if (getCustomerIdParam()) {
      closeCustomerModal();
    }

    await remove({
      customerId: customer.id,
    });
    onClose();
  }, [customer, isLoading, onClose, remove]);

  return (
    <DialogModal
      title={`Are you sure you want to delete ${customer.name || customer.email}?`}
      hide={onClose}
      onConfirm={onSubmit}
      loading={isLoading}
    />
  );
};
