import { BoardType } from '@cycle-app/graphql-codegen';
import { Icon, Spinner } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { memo, MouseEvent } from 'react';

import { CustomViewIconEditable } from 'src/app/Main/Sidebar/SidebarBoard/SidebarBoardSections/SidebarBoardSection/SidebarItemBoard/CustomViewIcon';
import { useIsBoardStarred } from 'src/hooks';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { SectionParent } from 'src/utils/section.utils';

import { ItemViewType } from './ItemViewType';
import { ViewDescription } from './ViewDescription';
import { ViewName } from './ViewName';
import {
  Item, Title, View, Actions, StyledBoardStarButton, StyledBoardOptions,
} from './ViewsList.styles';
import { useBoardConfigFilters } from '../../hooks/boards/useBoardConfigFilters';

type Props = {
  sectionParent: SectionParent;
  boardId: string;
  onClick?: (event: MouseEvent) => void;
  isOverlay?: boolean;
  isDragging?: boolean;
  isSmall: boolean;
};

export const ViewItem = memo(({
  sectionParent, boardId, onClick, isOverlay = false, isSmall, isDragging,
}: Props) => {
  const isBoardStarred = useIsBoardStarred();
  const { boards } = useSectionBoards(sectionParent);
  const board = boards.find(b => b.id === boardId);
  const filters = useBoardConfigFilters(board?.publishedBoardConfig?.id);
  const viewType = board?.publishedBoardConfig?.viewType;
  const isInbox = sectionParent === 'inbox';
  const isDashboard = !viewType;
  if (!board || (board.type === BoardType.Custom && !viewType)) return null;
  return (
    <Item
      onClick={onClick}
      $isDragging={isDragging}
      $isOverlay={isOverlay}
      $isSmall={isSmall}
      $isInbox={isInbox}
    >
      <Title>
        <CustomViewIconEditable
          viewId={board.id}
          color={board.color}
          emoji={board.emoji}
          sectionType={board.section?.type}
        />
        <ViewName
          boardId={board.id}
          name={board.name}
          isSmall={isSmall}
        />
      </Title>

      <View>
        <ItemViewType
          viewType={isDashboard ? 'dashboard' : viewType}
          isSmall={isSmall}
        />
      </View>

      {isInbox && filters.isLoading && (
        <div className="flex items-center gap-2 overflow-hidden text-secondary">
          <Spinner className="opacity-50" />
        </div>
      )}
      {isInbox && !filters.isLoading && (
        <div className="flex items-center gap-2 overflow-hidden text-secondary">
          {filters.insightDocType.isTheOnlyOne || isDashboard
            ? <><QuoteIcon /> Quotes</>
            : <><Icon name="feedback" className="flex-none" /> Docs</>}
        </div>
      )}

      <ViewDescription
        boardId={board.id}
        description={board.description?.trim()}
      />

      <Actions>
        {!isSmall && (
          <StyledBoardStarButton
            boardId={board.id}
            tooltipPlacement="top"
            $visible={isBoardStarred(board.id)}
          />
        )}

        <StyledBoardOptions
          boardId={board.id}
          boardType={board.type}
          placement="bottom-end"
          tooltipPlacement="top"
          boardConfigId={board.publishedBoardConfig?.id}
          hideSettings
        />
      </Actions>
    </Item>
  );
});
