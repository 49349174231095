import { Emoji } from '@cycle-app/ui';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { QuoteContentPreview } from 'src/components/QuoteContentPreview';
import { useDoc, useInsightDocType } from 'src/hooks';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { useRemoveReleaseNote } from 'src/hooks/releases/useRemoveReleaseNote';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { insightName } from 'src/utils/doc.util';
import { isBuiltIn, isFeedback, isInsight } from 'src/utils/docType.util';

import { Tag } from './DocDeleteWarningModal.styles';

type DocDeleteWarningModalProps = {
  docId: string;
  onConfirm?: VoidFunction | (() => Promise<void>);
  onHide: VoidFunction;
};

export const DocDeleteWarningModal = ({
  docId,
  onConfirm,
  onHide,
}: DocDeleteWarningModalProps) => {
  const { doc } = useDoc(docId);
  const docType = useGetDocType(doc?.doctype.id);
  const insightDocType = useInsightDocType();

  const hasReleaseNote = !!doc?.releaseNote;
  const releaseId = doc?.releaseNote?.release.id;

  const { removeDoc } = useRemoveDoc();
  const { removeReleaseNote } = useRemoveReleaseNote(releaseId);

  if (!docType || !doc) return null;

  const title = (isInsight(docType) ? doc.docSource?.content : doc.title) || doc.title || doc._docKey;

  const message = isInsight(docType)
    ? `Are you sure you want to delete this ${insightName()}?`
    : isFeedback(docType)
      ? 'Are you sure you want to delete this feedback?'
      : (
        <>
          {`Are you sure you want to delete ${docType.name} `}
          <Tag
            inline
            limitSize={false}
            icon={isBuiltIn(docType) ? undefined : <Emoji emoji={docType?.emoji} />}
          >
            {title}
          </Tag>
          {' ?'}
        </>
      );

  const modalContentBody = isFeedback(docType)
    ? `If it has some ${insightDocType?.name}, they will be deleted too.`
    : "This action can't be undone.";

  return (
    <DialogModal
      hide={onHide}
      title={`Delete ${isInsight(docType) ? insightName() : isFeedback(docType) ? 'feedback' : docType.name}`}
      onConfirm={async () => {
        await onConfirm?.();
        const releaseNoteId = doc.releaseNote?.id;
        const promises = [removeDoc(doc.id)];
        if (releaseNoteId) promises.push(removeReleaseNote(releaseNoteId));
        await Promise.all(promises);
      }}
      confirmLabel="Delete"
      info={(
        <>
          {message}

          <br />

          {isInsight(docType) && doc.docSource?.id && (
            <QuoteContentPreview
              docId={doc.id}
              docSourceId={doc.docSource.id}
              className="my-2"
            />
          )}

          {hasReleaseNote && <p>Note that it will delete the corresponding release note too.</p>}

          {modalContentBody}
        </>
      )}
    />
  );
};
