import { AskItem, AskItemAnswer } from 'src/components/Ask/Ask.types';
import { makeVar } from 'src/utils/makeVar.utils';
import { make } from 'src/utils/reactives.util';

type AskPanelState = {
  isOpen: boolean;
  question: string;
  screen: 'examples' | 'answers';
  conversationId?: string;
};

const initialState: AskPanelState = {
  isOpen: false,
  question: '',
  screen: 'examples',
};

// index = 'cycle' for the main Ask, or the feedback id for the doc Ask
type AskState = Record<string, AskPanelState>;

const askVar = make<AskState>({
  defaultState: {
    cycle: initialState,
  },
});

export const getAskPanel = (askId: string) => askVar.getValue()[askId];

export const useGetAskPanel = (askId: string) => askVar.hookValue()[askId];

export const setAskPanel = (id: string, state: Partial<AskPanelState>) => {
  return askVar.setValue({
    [id]: {
      ...initialState,
      ...getAskPanel(id),
      ...state,
    },
  });
};

export const closeAskPanel = (id: string) => {
  const state = getAskPanel(id);
  // Do not reset the screen
  askVar.setValue({
    [id]: {
      screen: state?.screen ?? 'examples',
      isOpen: false,
      question: '',
    },
  });
};

export const openAsk = (id: string, search?: string) => {
  askVar.setValue({
    [id]: {
      screen: getAskPanel(id)?.screen ?? 'examples',
      isOpen: true,
      question: search ?? '',
    },
  });
};

// Questions and answers ordered by newest first
const itemsVar = make<Record<string, AskItem[]>>({
  defaultState: {},
});

export const useGetAskItems = (id: string) => {
  return itemsVar.hookValue()[id] ?? [];
};

export const addAskItems = (id: string, newItems: AskItem[]) => itemsVar.setValue({
  [id]: [
    ...newItems,
    ...(itemsVar.getValue()[id] ?? []),
  ],
});

export const {
  hookValue: useGetAskAnswers,
  getValue: getAskAnswers,
  setValue: setAskAnswers,
} = make<{
  answers: Record<string, AskItemAnswer>;
}>({
  defaultState: {
    // Answers content indexed by answer id
    answers: {},
  },
});

export const updateAskAnswer = (id: string, answer: AskItemAnswer) => {
  setAskAnswers({
    answers: {
      ...getAskAnswers().answers,
      [id]: answer,
    },
  });
};

export const expandedVar = makeVar<Record<string, boolean>>({});

export const useIsAskExpanded = (id: string) => {
  const state = expandedVar.hook();
  return state[id] ?? false;
};

export const toggleAskExpanded = (id: string) => expandedVar.produce(state => {
  state[id] = !state[id];
});