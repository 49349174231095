import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddQuoteFromBlockButton = styled(ActionButton)`
  position: absolute;
  padding: 0;
  width: 22px;
  height: 22px
`;

export const DocPanelBodyContainer = styled.div`
  position: relative;
  
  &[data-hovering="false"] .drag-icon[data-drag-handle] {
    opacity: 0;
  }

  &[data-hovering="false"] ${AddQuoteFromBlockButton}:not(.force-focus) {
    opacity: 0;
  }
`;
