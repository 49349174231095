import { ApolloProvider } from '@apollo/client';
import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';

import { ScrollToTop } from 'src/components/ScrollToTop';
import ThemeProvider from 'src/providers/ThemeProvider';
import client from 'src/services/apollo/client';

import { history } from './browserHistory';
import { FeatureFlagProvider } from './FeatureFlagProvider';

export const AppProviders: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <FeatureFlagProvider>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <ThemeProvider>
          {/* Added types react 18 types in resolution. We need to ignore the children error props.
        // @ts-ignore */}
          <Router history={history}>
            <ScrollToTop />
            {children}
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  </FeatureFlagProvider>
);
