import { Tooltip, Shortcut, OfflineBadge, Icon } from '@cycle-app/ui';
import { twJoin } from 'tailwind-merge';

import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsOffline } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { closeMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';
import { useGetNotifications, openNotifications } from 'src/reactives/notifications.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { ShortcutSidebar } from 'src/types/shortcuts.types';

export const Notifications = () => {
  const collapsed = useSidebarCollapsed();
  const isMobile = useIsMobile();
  const { product } = useProduct();
  const { notificationsNotRead } = product || {};

  const { isNotifCenterVisible } = useGetNotifications();
  const isOffline = useIsOffline();

  if (isMobile) {
    return renderContent();
  }
  return (
    <NotificationCenter>
      {renderContent()}
    </NotificationCenter>
  );

  function renderContent() {
    if (isOffline) return <OfflineBadge />;

    return (
      <Tooltip
        content={(
          <div className="flex items-center gap-2">
            Notifications
            <Shortcut keys={shortcuts[ShortcutSidebar.OpenNotifCenter]} />
          </div>
        )}
        placement={collapsed ? 'right' : 'bottom'}
        withPortal
      >
        <button
          className={twJoin(
            'btn btn-tertiary btn-square',
            !collapsed && 'text-disabled hover:text-secondary',
            !collapsed && isNotifCenterVisible && 'btn-hover text-secondary',
            collapsed && isNotifCenterVisible && 'btn-hover',
          )}
          onClick={() => {
            openNotifications();
            setTimeout(closeMobileAsideMenu, 250);
          }}
        >
          <Icon name={notificationsNotRead ? 'bell-active' : 'bell'} />
        </button>
      </Tooltip>
    );
  }
};
