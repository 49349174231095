import { BoardWithMinimalConfigFragment, SectionType } from '@cycle-app/graphql-codegen';
import { NavigationSectionSkeleton } from '@cycle-app/ui';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useMemo, memo } from 'react';

import { useMoveSection } from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardLinkMoveInSectionList } from 'src/hooks/boards/useBoardLinkMoveInSectionList';
import { useGroupsDnd } from 'src/hooks/dnd/useGroupsDnd';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getSections, useGetNewSectionForm, useSections } from 'src/reactives/sections.reactive';
import { sidebarCollisionDetection } from 'src/utils/dnd.util';

import SidebarBoardSection from './SidebarBoardSection/SidebarBoardSection';
import SidebarBoardSectionSortable from './SidebarBoardSection/SidebarBoardSectionSortable';
import SidebarItemBoard from './SidebarBoardSection/SidebarItemBoard/SidebarItemBoard';
import SidebarBoardNewSection from './SidebarBordNewSection/SidebarBoardNewSection';

const SidebarBoardSections = memo(() => {
  const { moveBoardLinkInSectionList } = useBoardLinkMoveInSectionList();
  const { moveSection } = useMoveSection();

  const {
    sections,
    loading,
    mappingSections,
    mappingBoardLinks,
  } = useBoardSections();
  const isOnboarding = useIsOnboarding();
  const collapsed = useSidebarCollapsed();
  const { isOpen: isNewSectionFormOpen } = useGetNewSectionForm();

  const [sectionsState, setSectionsState] = useSections();

  // Dnd board links
  const initialBoardLinkItems = useMemo(
    () => Object.fromEntries(sections.map(section => [section.id, section.boardLinks.edges.map(boardLink => boardLink.node.id)])),
    [sections],
  );
  const {
    activeId,
    activeType,
    groupActiveId,
    dropGroupOverId,
    dndContextProps,
    items,
  } = useGroupsDnd({
    initialItems: initialBoardLinkItems,
    collisionDetection: sidebarCollisionDetection,
    crossGroupStrategy: 'droppable',
    onGroupMoved: params => moveSection({
      sectionId: params.groupId,
      updatedGroupIds: params.sortedGroupIds,
      position: params.position,
    }),
    onItemsMoved: params => {
      const boardLinkId = params.itemsId[0];
      if (!boardLinkId) return;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      moveBoardLinkInSectionList({
        boardLinkId,
        sectionId: params.groupId,
        position: params.position,
      });
    },
  });

  const { canReorderBoards } = useGetPermission();

  if (loading) {
    return <NavigationSectionSkeleton />;
  }

  const itemIds = Object.keys(items);

  const activeSection = activeId ? mappingSections[activeId] : null;
  const activeBoard = activeId ? getBoard(activeId) : null;
  const hasBoardLinks = Object.keys(mappingBoardLinks).length > 0;

  const filteredItemIds = itemIds.filter(sectionId => !!mappingSections[sectionId]);

  return (
    <DndContext {...dndContextProps}>
      <SortableContext
        items={itemIds}
        strategy={verticalListSortingStrategy}
        disabled={!canReorderBoards}
      >
        {filteredItemIds
          .map((sectionId) => {
            const isOpen = activeId !== sectionId && sectionsState[sectionId] === true;
            const section = mappingSections[sectionId];
            // Sections: show title
            // Default section: hide title if has no view links.
            // Prevents:
            //
            // Main section (default)
            // Sections (first section title)
            // + Create your first section
            //
            const hideTitle = section?.type === SectionType.Default && !items[sectionId]?.length && filteredItemIds.length > 1;
            if (hideTitle && !items[sectionId]?.length) return null;
            return section && (
              <SidebarBoardSectionSortable
                key={sectionId}
                section={section}
                sections={Object.values(mappingSections)}
                isOpen={isOpen}
                toggleSection={toggleSection(sectionId)}
                asPlaceholder={activeType === 'group' && activeId === sectionId}
                boardLinksId={items[sectionId]}
                getBoard={getBoard}
                droppableEnabled={activeType === 'item' && groupActiveId !== sectionId}
                isOver={sectionId === dropGroupOverId}
                hideTitle={hideTitle}
                hasBoardLinks={hasBoardLinks}
              />
            );
          })}
      </SortableContext>
      {!collapsed && !isOnboarding && isNewSectionFormOpen && <SidebarBoardNewSection />}
      <DragOverlay>
        {activeSection && activeType === 'group' && (
          <SidebarBoardSection
            section={activeSection}
            sections={Object.values(mappingSections)}
            isOpen={false}
            isDragging
          />
        )}
        {activeBoard && activeType === 'item' && (
          <OverlayItemBoard
            activeBoard={activeBoard}
          />
        )}
      </DragOverlay>
    </DndContext>
  );

  function getBoard(boardLinkId: string) {
    return mappingBoardLinks[boardLinkId]?.board;
  }

  function toggleSection(sectionId: string) {
    return () => {
      const currentState = getSections()[sectionId];
      setSectionsState({ [sectionId]: currentState === undefined ? true : !currentState });
    };
  }
});

const OverlayItemBoard = ({ activeBoard }: {
  activeBoard: BoardWithMinimalConfigFragment;
}) => {
  return (
    <SidebarItemBoard
      board={activeBoard}
      isDragging
    />
  );
};

export default SidebarBoardSections;
