import { DocBaseFragment, StatusType, UpdateDocsStatusDocument } from '@cycle-app/graphql-codegen';

import { refetchProfileDocsCount } from 'src/hooks/api/cache/cacheProfile';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getStatus } from 'src/reactives/productStatus.reactive';
import { setBulkLoading } from 'src/reactives/selection.reactive';

const onDone = () => setBulkLoading({ isLoading: false });

export const useUpdateDocsStatusMutation = () => {
  const [mutate, { loading }] = useSafeMutation(UpdateDocsStatusDocument, {
    onCompleted: () => {
      onDone();
      refetchProfileDocsCount();
    },
    onError: onDone,
  });

  const updateDocsStatus = (docIds: string[], statusId: string) => {
    const status = getStatus(statusId);
    if (!status) return null;
    const newStatus: DocBaseFragment['status'] = {
      id: statusId,
      type: status.type,
      category: status.category,
      value: status.value,
    };
    return mutate({
      variables: {
        docIds,
        statusId,
      },
      update: (cache) => {
        docIds.forEach(docId => {
          cache.modify({
            id: docId,
            fields: {
              status: () => newStatus,
              // Non-verified quotes will be discarded if the status is processed
              quotes: (refs: any) => ({
                ...refs,
                edges: status.type === StatusType.Processed ? [] : refs.edges,
              }),
              quotesCount: (value) => (status.type === StatusType.Processed ? 0 : value),
            },
          });
        });
      },
    });
  };

  return {
    loading,
    updateDocsStatus,
  };
};
