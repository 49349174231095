import { useMoveBoardInSectionsList } from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { useInboxSection } from 'src/hooks/inbox';
import { useRoadmapSection } from 'src/hooks/roadmap/useRoadmapSection';

export const useMoveFeedbackViewToSectionEnd = () => {
  const {
    section, boards,
  } = useInboxSection();
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();

  return async (viewId: string) => {
    const lastViewId = boards[boards.length - 1]?.id;
    if (!section || !lastViewId) return null;

    return moveBoardInSectionList({
      boardId: viewId,
      fromSectionId: section.id,
      toSectionId: section.id,
      position: {
        before: '',
        after: lastViewId,
      },
    });
  };
};

export const useMoveRoadmapViewToSectionEnd = () => {
  const {
    section, boards,
  } = useRoadmapSection();
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();

  return async (viewId: string) => {
    const lastViewId = boards[boards.length - 1]?.id;
    if (!section || !lastViewId) return null;

    return moveBoardInSectionList({
      boardId: viewId,
      fromSectionId: section.id,
      toSectionId: section.id,
      position: {
        before: '',
        after: lastViewId,
      },
    });
  };
};
