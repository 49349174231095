import { ActionButton, ring } from '@cycle-app/ui';
import { hover } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DocParent from '../DocParent/DocParent';

export const EditParentBtnContainer = styled.div<{ $absolute?: boolean }>`
  ${p => p.$absolute && css`
    position: absolute;
    right: 4px;
  `};
`;

export const EditParentBtn = styled(ActionButton)`
  ${ring}
  --ring-color: ${p => p.theme.colors.actionButton.outline};
  background-color: ${p => p.theme.colors.background.primary};
  &:hover {
    background-color: ${p => p.theme.colors.background.hover};
    svg {
      color: ${p => p.theme.colors.card.parent.colorHover};
    }
  }
`;

export const StyledDocParent = styled(DocParent)`
  position: relative;

  ${EditParentBtn} {
    display: none;
    opacity: 0;
  }

  ${hover} {
    ${EditParentBtn}.force-focus {
      display: flex;
      opacity: 1;
    }
    &:hover {
      ${EditParentBtn} {
        display: flex;
        opacity: 1;
      }
    }
  }

  ${p => p.context === 'doc-item' && css`
    ${EditParentBtn} {
      display: flex;
      opacity: 1;
    }
  `}
`;
