import { PropertyValueFragment } from '@cycle-app/graphql-codegen';

import { DEFAULT_GROUP_NAME } from 'src/constants/boardGroups.constants';

import { sourceCategoryLabel } from './sources.utils';

type DocGroup = {
  propertyValue?: PropertyValueFragment | undefined | null;
  name?: string | undefined | null;
} | undefined;

export const getGroupName = ({
  propertyValue, name,
}: DocGroup = {}, propertyName?: string | null): string => {
  if (!propertyValue) {
    return name ?? (propertyName ? `No ${propertyName}` : DEFAULT_GROUP_NAME);
  }

  switch (propertyValue.__typename) {
    case 'AttributeCheckboxValue':
      // @todo Group name for checkbox TBD
      return propertyValue.checkbox ? 'Yes' : 'No';
    case 'AttributeDateValue':
      return propertyValue.date;

    case 'AttributeEmailValue':
      return propertyValue.email;

    case 'AttributeNumberValue':
      return propertyValue.number.toString();

    case 'AttributePhoneValue':
      return propertyValue.phone;

    case 'AttributeSelectValue':
      return propertyValue.select ?? '';

    case 'AttributeTextValue':
      return propertyValue.text;

    case 'AttributeUrlValue':
      return propertyValue.url;

    case 'OtherUser':
      return propertyValue.firstName ?? '';

    case 'Doctype':
      return propertyValue.name;

    case 'Me':
      return propertyValue.firstName ?? '';

    case 'Doc':
      return propertyValue.title;

    case 'Status':
      return propertyValue.value;
    
    case 'SourceCategory':
      return sourceCategoryLabel(propertyValue.value);
    
    case 'ProductArea':
      return propertyValue.value;

    default:
      // @todo Default case TDB
      return '';
  }
};

export const getGroupCategory = ({ propertyValue }: DocGroup = {}) => {
  return propertyValue?.__typename === 'Status' ? propertyValue.category : undefined;
};
