import { DocSourceFragment } from '@cycle-app/graphql-codegen';

import { isCycleFileSource } from 'src/utils/doc.util';

export const getLabel = (source?: Partial<DocSourceFragment> | null) => {
  if (isCycleFileSource(source)) return `File・${source?.url?.split('.').at(-1)?.toUpperCase()}`;
  if (source?.url?.includes('canny.io')) return 'Canny';
  if (source?.url?.includes('reddit.com')) return 'Reddit';
  if (source?.url?.includes('twitter.com') || source?.url?.includes('https://x.com')) return 'X (Twitter)';
  if (source?.url?.includes('docs.google.com/spreadsheets')) return 'Google Sheet';
  if (source?.url?.includes('docs.google.com/document')) return 'Google Doc';
  if (source?.url?.includes('attio.com')) return 'Attio';
  if (source?.url?.includes('clickup.com')) return 'ClickUp';
  if (source?.url?.includes('claap.io')) return 'Claap';
  if (source?.url?.includes('atlassian.net')) return 'Atlassian';
  if (source?.url?.includes('descript.com')) return 'Descript';
  if (source?.url?.includes('discord.com')) return 'Discord';
  if (source?.url?.includes('dovetail.com')) return 'Dovetail';
  if (source?.url?.includes('dropbox.com')) return 'Dropbox';
  if (source?.url?.includes('freshdesk.com')) return 'Freshdesk';
  if (source?.url?.includes('front.com')) return 'Front';
  if (source?.url?.includes('fullstory.com') || source?.url?.includes('fsty.io')) return 'Fullstory';
  if (source?.url?.includes('gainsight.com')) return 'Gainsight';
  if (source?.url?.includes('github.com')) return 'GitHub';
  if (source?.url?.includes('gitlab.com')) return 'GitLab';
  if (source?.url?.includes('gong.io')) return 'Gong';
  if (source?.url?.includes('grain.com')) return 'Grain';
  if (source?.url?.includes('make.com')) return 'Make';
  if (source?.url?.includes('microsoft.com')) return 'Microsoft';
  if (source?.url?.includes('miro.com')) return 'Miro';
  if (source?.url?.includes('modjo.com')) return 'Modjo';
  if (source?.url?.includes('monday.com')) return 'Monday';
  if (source?.url?.includes('outlook.com')) return 'Outlook';
  if (source?.url?.includes('productboard.com')) return 'Productboard';
  if (source?.url?.includes('pitch.com')) return 'Pitch';
  if (source?.url?.includes('pinterest.com')) return 'Pinsterest';
  if (source?.url?.includes('shortcut.com')) return 'Shortcut';
  if (source?.url?.includes('skype.com')) return 'Skype';
  if (source?.url?.includes('slite.com')) return 'Slite';
  if (source?.url?.includes('snowflake.com')) return 'SnowFlake';
  if (source?.url?.includes('zoom.com')) return 'Zoom';
  if (source?.url?.includes('zapier.com')) return 'Zapier';
  if (source?.url?.includes('airtable.com')) return 'Airtable';
  if (source?.url?.includes('notion.com')) return 'Notion';
  if (source?.url?.includes('salesforce.com')) return 'Salesforce';
  if (source?.url?.includes('hubspot.com')) return 'HubSpot';
  if (source?.url?.includes('zendesk.com')) return 'Zendesk';
  if (source?.url?.includes('intercom.com')) return 'Intercom';
  if (source?.url?.includes('slack.com')) return 'Slack';
  if (source?.url?.includes('asana.com')) return 'Asana';
  if (source?.url?.includes('g2.com')) return 'G2';
  if (source?.url?.includes('trustpilot.com')) return 'Trustpilot';
  if (source?.url?.includes('capterra')) return 'Capterra';
  switch (source?.__typename) {
    case 'SourceMail': return 'Email';
    case 'SourceZapier': return 'Zapier';
    case 'SourceSlack': return 'Slack';
    case 'SourceIntercom': return 'Intercom';
    case 'SourceHubspot': return 'Hubspot';
    case 'SourceZoom': return 'Zoom';
    case 'SourceMicrosoftTeams': return 'Microsoft Teams';
    case 'SourceGoogleMeet': return 'Google Meet';
    case 'SourceLoom': return 'Loom';
    case 'SourceNotion': return 'Notion';
    case 'SourceGong': return 'Gong';
    case 'SourceZendesk': return 'Zendesk';
    case 'SourceSalesforce': return 'Salesforce';
    case 'SourceModjo': return 'Modjo';
    case 'SourceCycle':
    default:
      return 'Cycle';
  }
};

export const getTooltipFooter = (source?: Partial<DocSourceFragment> | null) => {
  switch (source?.__typename || '') {
    case 'SourceCycle':
      return source?.url === window.location.origin ? 'Click to add URL' : 'Click to edit URL';
    case 'SourceZapier':
      return 'Click to edit URL';
    case 'SourceMail':
    case 'SourceSlack':
    case 'SourceIntercom':
    case 'SourceHubspot':
    case 'SourceZoom':
    case 'SourceMicrosoftTeams':
    case 'SourceGoogleMeet':
    case 'SourceLoom':
    case 'SourceNotion':
    case 'SourceGong':
    case 'SourceSalesforce':
    case 'SourceModjo':
    case 'SourceZendesk':
    default:
      return '';
  }
};
