import { Icon } from '@cycle-app/ui';
import { memo } from 'react';

import { Item } from 'src/app/Main/Sidebar/SidebarFooterItem';
import { SidebarChangelog } from 'src/components/SidebarChangelog';
import { useOfflineGuard } from 'src/hooks';
import { openLearningCenter } from 'src/reactives';
import { toggleAddMember } from 'src/reactives/addMember.reactive';

export const SidebarFooterActions = memo(() => {
  const offlineGuard = useOfflineGuard({ message: '🔌 You need access to network to invite a member' });
  return (
    <div className="flex flex-col gap-1">
      <SidebarChangelog />

      <Item
        icon={<Icon name="compass" />}
        label="Help center"
        onClick={openLearningCenter}
      />

      <Item
        icon={<Icon name="add-user" />}
        label="Invite member"
        onClick={() => offlineGuard(toggleAddMember)}
      />
    </div>
  );
});
