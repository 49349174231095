import { Role } from '@cycle-app/graphql-codegen';
import { AnimatePresence } from 'framer-motion';
import { FC, useCallback } from 'react';

import { useMaybeMe } from 'src/hooks/api/useMe';
import { setTemplate, useGetTemplate } from 'src/reactives/template.reactive';

import ExistingTemplateEditor from './TemplateEditor/ExistingTemplateEditor';
import Templates from './Templates/Templates';

const TemplateModal: FC = () => {
  const me = useMaybeMe();
  const {
    admin,
    modalVisible,
    mode,
    selectedTemplateId,
    docTypeId,
    forceCloseOnUpdate,
    initialView,
  } = useGetTemplate();
  const modeAdmin = me?.role === Role.SuperAdmin && admin;

  const onTemplateUpdated = useCallback(() => {
    if (admin && !forceCloseOnUpdate) {
      setTemplate({ mode: 'list' });
    } else {
      setTemplate({
        modalVisible: false,
        forceCloseOnUpdate: false,
        initialView: 'list',
      });
    }
  }, [admin, forceCloseOnUpdate]);

  return (
    <AnimatePresence>
      {modalVisible && (mode === 'edit' && selectedTemplateId ?  (
        <ExistingTemplateEditor
          templateId={selectedTemplateId}
          onTemplateUpdated={onTemplateUpdated}
          modeAdmin={modeAdmin}
        />
      ) : (
        <Templates
          modeAdmin={modeAdmin}
          docTypeId={docTypeId}
          initialView={initialView}
        />
      ))}
    </AnimatePresence>
  );
};

export default TemplateModal;
