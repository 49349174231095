import { Role } from '@cycle-app/graphql-codegen';
import { plural } from '@cycle-app/utilities';
import { MouseEventHandler } from 'react';

import { useCurrentBilling } from 'src/hooks';
import { PAGINATION_SIZE, useActiveUsers } from 'src/hooks/api/useUsers';

import { AvatarList, AvatarListProps } from '../AvatarList';
import { Container, Info } from './MakersAvatar.styles';

export interface MakersAvatarProps {
  className?: string;
  label?: (nbMakers: number, makersDisplayed: number) => string;
  limit?: number;
  onClick?: MouseEventHandler;
  size?: AvatarListProps['size'];
}

export const MakersAvatar = ({
  className, label, limit, onClick, size,
}: MakersAvatarProps) => {
  const {
    users,
    loading,
  } = useActiveUsers();
  const billing = useCurrentBilling();
  const makers = users.filter(user => user.productRole === Role.Maker).slice(0, limit ?? users.length);
  const skeletonCount = Math.min(PAGINATION_SIZE, billing?.nbMakers || 1);

  return (
    <Container onClick={onClick} className={className}>
      <AvatarList
        size={size}
        avatars={makers}
        isLoading={loading}
        skeletonCount={skeletonCount}
      />
      {billing && (label ? label(billing.nbMakers, makers.length) : (
        <Info>{makersLabel(billing.nbMakers, makers.length)}</Info>
      ))}
    </Container>
  );
};

const makersLabel = (nbMakers: number, makersDisplayed: number) => {
  if (nbMakers === 0) return 'No makers';
  if (nbMakers === 1) return '1 maker';
  if (nbMakers > makersDisplayed) {
    const diff = nbMakers - makersDisplayed;
    return `+${diff} ${plural('maker', diff)}`;
  }
  return `${nbMakers} makers`;
};
