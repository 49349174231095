import { Role, ChangeUserProductRoleDocument } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { useCurrentBilling } from '../..';

export const useChangeUserProductRole = () => {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();

  const [mutate, result] = useSafeMutation(ChangeUserProductRoleDocument);

  const changeUserProductRole = (role: Role, userId: string, currentRole: Role) => {
    if (!product || role === currentRole) return null;
    return mutate({
      variables: {
        productId: product.id,
        userId,
        role,
      },
      optimisticResponse: {
        changeUserProductRole: {
          id: userId,
          productRole: role,
        },
      },
      update: (cache) => {
        if (currentBilling) {
          const billing = cache.identify(currentBilling);
          if (billing) {
            cache.modify({
              id: billing,
              fields: {
                nbMakers: (currentMakers) => {
                  if (role === Role.Maker) {
                    return currentMakers + 1;
                  }
                  if (currentRole === Role.Maker) {
                    return currentMakers - 1;
                  }
                  return currentMakers;
                },
              },
            });
          }
        }
      },
    });
  };

  return {
    ...result,
    changeUserProductRole,
  };
};
