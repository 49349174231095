import { SectionType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';

import { MainPageId, PageId } from 'src/constants/routing.constant';

export type SectionParent = 'inbox' | 'roadmap';

export const getSectionName = (sectionParent: SectionParent, plural = false) => {
  if (sectionParent === 'inbox') return 'Feedback';
  return plural ? 'Features' : 'Feature';
};

export const getSectionIcon = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return <Icon name="feedback" />;
  return <Icon name="feature" />;
};

export const getSectionType = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return SectionType.Feedback;
  return SectionType.Roadmaps;
};

export const getViewPageId = (sectionParent: SectionParent): MainPageId => {
  if (sectionParent === 'inbox') return PageId.InboxView;
  return PageId.RoadmapView;
};

export const getSectionPageId = (sectionParent: SectionParent): MainPageId => {
  if (sectionParent === 'inbox') return PageId.Inbox;
  return PageId.Roadmap;
};
