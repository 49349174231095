import { useQuery } from '@apollo/client';
import { AddNewDocDocument, AddNewDocAttributeValue, SearchInsightDocDocument, DocTargetResultFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray, useListNav, useHotkeys } from '@cycle-app/utilities';
import { FC, useMemo, useCallback, useEffect } from 'react';

import { useSearchSuggestionDocTargets } from 'src/hooks/api/queries/useSearchSuggestionDocTargets';
import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';

import { CREATE_VALUE_PREFIX, CREATE_VALUE_MORE } from './DocSearchDropdown.constants';
import {
  ListContainer,
  SectionTitle,
  NotFound,
  SearchResultsInsights,
  InsightButton,
  InsightQuote,
} from './DocSearchDropdown.styles';
import { StyledInsightCard, Content, Suggestions } from './DocSearchInsightResult.styles';

const SUGGESTION_PREFIX = 'suggestion';

export type DocSearchInsightResultProps = {
  onAdd?: (docId: string, options?: { isNewDoc?: boolean; remove?: boolean }) => void;
  childDoctypeId?: string;
  search?: string;
  inheritedAttributes?: AddNewDocAttributeValue[];
  customerId?: string;
  sourceId?: string;
  showLeaveBoardWarnings?: boolean;
  showAddSelectOption?: boolean;
  isOptionDisabled?: (doc: DocTargetResultFragment) => boolean;
  suggestionDocId?: string;
  onClickCreate?: (search?: string) => void;
  semanticSearch?: string;
  onOptionsChange?: VoidFunction;
  setLoading?: (loading: boolean) => void;
};

export const DocSearchInsightResult: FC<React.PropsWithChildren<DocSearchInsightResultProps>> = ({
  onAdd,
  childDoctypeId,
  search = '',
  inheritedAttributes,
  customerId,
  sourceId,
  suggestionDocId,
  onClickCreate,
  semanticSearch,
  onOptionsChange,
  setLoading,
  ...props
}) => {
  const insightDocType = useInsightDocType();
  const statusIds = useSuggestionStatusIds();
  const { product } = useProduct();
  const [createDoc, { loading: loadingCreateDoc }] = useSafeMutation(AddNewDocDocument);
  const {
    data,
    previousData,
    loading,
  } = useQuery(SearchInsightDocDocument, {
    skip: !insightDocType,
    variables: {
      text: search,
      productId: product?.id ?? '',
      statusIds,
      childDoctypeId,
      size: 50,
      cursor: '',
      hasParent: false,
      doctypeIds: [insightDocType?.id as string],
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setLoading?.(loading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const results = useMemo(() => nodeToArray((loading ? previousData : data)?.searchDoc), [data, loading, previousData]);

  const isSuggestionsEnabled = !search && (!!suggestionDocId || !!semanticSearch);

  const suggestionQuery = useSearchSuggestionDocTargets({
    docId: suggestionDocId as string,
    hasParent: false,
    doctypeIds: [insightDocType?.id as string],
  }, {
    skip: !isSuggestionsEnabled || !insightDocType,
  });

  const optionsValues = useMemo(() => ([
    ...suggestionQuery.docs.map((x) => `${SUGGESTION_PREFIX}${x.id}`),
    ...results.map((x) => (x.doc.id)),
  ]), [results, suggestionQuery.docs]);

  const onCreateDoc = useCallback(async (docTypeId: string) => {
    if (!docTypeId || !product?.id) return;
    const docTypesAttributeIds: string[] = insightDocType?.attributeDefinitions.edges.map(({ node }) => node.id) ?? [];

    const commonAttributes: AddNewDocAttributeValue[] =
      inheritedAttributes?.filter((attr) => docTypesAttributeIds.includes(attr.attributeDefinitionId)) ?? [];

    const docId = (await createDoc({
      variables: {
        doctypeId: docTypeId,
        title: search,
        productId: product?.id,
        attributes: commonAttributes,
        ...(sourceId && {
          source: {
            sourceId,
          },
        }),
        customer: customerId ? { id: customerId } : undefined,
      },
    })).data?.addNewDoc?.id;

    if (docId) {
      onAdd?.(docId, { isNewDoc: true });
    }
  }, [
    customerId,
    onAdd,
    createDoc,
    search,
    sourceId,
    inheritedAttributes,
    product?.id,
    insightDocType,
  ]);

  const onResultSelected = useCallback(async (optionId: string | null) => {
    if (!optionId || optionId === CREATE_VALUE_MORE) return;

    if (optionId.includes(CREATE_VALUE_PREFIX)) {
      if (onClickCreate) {
        onClickCreate(search);
        return;
      }
      await onCreateDoc(optionId.replace(CREATE_VALUE_PREFIX, ''));
      return;
    }

    onAdd?.(optionId.replace(SUGGESTION_PREFIX, ''), { isNewDoc: false });
  }, [onAdd, onClickCreate, onCreateDoc, search]);

  const {
    listProps,
    itemProps,
    selected,
    select,
  } = useListNav({
    optionsValues,
    value: null,
    onSelect: onResultSelected,
    autoFocus: true,
    defaultIndex: 0,
  });

  useHotkeys('tab', e => {
    e.preventDefault();
    e.stopPropagation();

    if (!selected) return;

    if (selected.includes(CREATE_VALUE_PREFIX) || selected.includes(CREATE_VALUE_MORE)) {
      const firstOption = optionsValues[0];
      if (firstOption) select(firstOption);
    } else {
      const firstCreateOption = optionsValues.find(o => o.includes(CREATE_VALUE_PREFIX));
      if (firstCreateOption) select(firstCreateOption);
    }
  });

  const showRemoveSelectOption = !search;
  const showAddSelectOption = props.showAddSelectOption || !!search;
  const showResultsContainer = !!results.length || showRemoveSelectOption || isSuggestionsEnabled;

  useEffect(() => {
    onOptionsChange?.();
  }, [onOptionsChange, optionsValues]);

  return (
    <ListContainer {...listProps}>
      {showResultsContainer && (
        <SearchResultsInsights
          $hasMarginBottom={!showAddSelectOption}
          className={search && loading ? 'opacity-50' : ''}
        >
          {isSuggestionsEnabled && (
            <>
              <SectionTitle>
                Recommended
              </SectionTitle>

              {!suggestionQuery.isLoading && suggestionQuery.docs.length === 0 && (
                <NotFound>No recommendation found</NotFound>
              )}

              {suggestionQuery.isLoading && suggestionQuery.docs.length === 0 && (
                <div className="space-y-2">
                  <Skeleton height={20} />
                  <Skeleton height={20} />
                </div>
              )}

              {suggestionQuery.docs.length > 0 && (
                <Suggestions>
                  {suggestionQuery.docs.map(doc => (
                    <StyledInsightCard
                      key={doc.id}
                      doc={doc}
                      showSource
                      showAssignee
                      showCustomer
                      $isSelected={selected === doc.id}
                      {...itemProps(doc.id)}
                    />
                  ))}
                </Suggestions>
              )}

              <SectionTitle>
                Recent
              </SectionTitle>
            </>
          )}

          {loading && results.length === 0 && (
            <div className="space-y-2">
              <Skeleton height={20} />
              <Skeleton height={20} />
            </div>
          )}

          {results.map(result => (
            <StyledInsightCard
              key={result.doc.id}
              doc={result.doc}
              showSource
              showAssignee
              showCustomer
              $hasHighlight={!!result.highlightContent}
              context={result.highlightContent ? (
                <Content
                  className="highlight"
                  dangerouslySetInnerHTML={{
                    __html: `<p>${result.highlightContent}</p>`,
                  }}
                />
              ) : null}
              $isSelected={selected === result.doc.id}
              {...itemProps(result.doc.id)}
            />
          ))}
        </SearchResultsInsights>
      )}

      {showAddSelectOption && (
        <InsightButton
          iconStart={<AddIcon size={12} />}
          isLoading={loadingCreateDoc}
          onClick={() => onResultSelected(`${CREATE_VALUE_PREFIX}${insightDocType?.id}`)}
        >
          <span style={{ flex: 'none' }}>Create quote</span>
          <InsightQuote>{search}</InsightQuote>
        </InsightButton>
      )}
    </ListContainer>
  );
};
