import { Icon } from '@cycle-app/ui';
import {
  WheelIcon, QuoteIcon,
  UsersIcon, TagIcon, ShapesIcon, BagIcon, CodeBorderIcon,
} from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { PageId, SettingsPageId } from 'src/constants/routing.constant';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useRouteAccess } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useUrl } from 'src/hooks/useUrl';
import { setSettingsFromUrl } from 'src/reactives/lastView.reactive';
import { useSettingsFeedbackLastTab } from 'src/reactives/settingsFeedback.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';

import { useProductAreaAttribute } from '../api/useAttributes';
import { closeCommandBar } from '../modals/useCommandBarModal';
import { useProductAreasCategories } from '../product/useProductAreasCategories';

type SettingsItem = {
  id: string;
  name: string;
  pageId: SettingsPageId;
  icon: ReactNode;
  disabled?: boolean;
};

const iconProps = (height: number) => ({
  style: {
    height,
    width: 20,
  },
});

export const useSettingsResult = (): Array<CommandActionCategory> => {
  const { navigate } = useNavigateToSettings();
  const getUrl = useUrl();
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);
  const isChangelogOnly = useIsChangelogOnly();
  const feedbackLastTab = useSettingsFeedbackLastTab();
  const productAreaAttribute = useProductAreaAttribute();
  const productAreas = useProductAreasCategories();

  const settings: SettingsItem[] = [
    {
      id: 'general',
      name: 'General',
      pageId: PageId.Settings,
      icon: <WheelIcon {...iconProps(18)} />,
    },
    {
      id: 'members',
      name: 'Members',
      pageId: PageId.SettingsUsers,
      icon: <UsersIcon {...iconProps(19)} />,
    },
    {
      id: 'product-hierarchy',
      name: productAreaAttribute.attributeNamePlural,
      pageId: PageId.SettingsProductHierarchy,
      icon: <Icon name="tri-shapes" />,
      disabled: !productAreas.areas.length,
    },
    {
      id: 'feedback',
      name: 'Feedback',
      pageId: feedbackLastTab === 'docs' ? PageId.SettingsFeedback : PageId.SettingsInsights,
      icon: <Icon name="feedback" className="size-[17px]" />,
      disabled: isChangelogOnly,
    },
    {
      id: 'quotes',
      name: 'Quotes',
      pageId: PageId.SettingsInsights,
      icon: <QuoteIcon {...iconProps(19)} />,
      disabled: isChangelogOnly,
    },
    {
      id: 'features',
      name: 'Features',
      pageId: PageId.SettingsFeatures as SettingsPageId,
      icon: <Icon name="feature" {...iconProps(17)} />,
      disabled: isChangelogOnly,
    },
    {
      id: 'releases',
      name: 'Releases',
      pageId: PageId.SettingsReleases as SettingsPageId,
      icon: <Icon name="release" {...iconProps(17)} />,
    },
    {
      id: 'customers',
      name: 'Customers',
      pageId: PageId.SettingsCustomers,
      icon: <Icon name="user" className="!size-[18px]" />,
      disabled: isChangelogOnly,
    },
    {
      id: 'properties',
      name: 'Properties',
      pageId: PageId.SettingsAttributes,
      icon: <TagIcon {...iconProps(18)} />,
      disabled: isChangelogOnly,
    },
    {
      id: 'integrations',
      name: 'Integrations',
      pageId: PageId.SettingsIntegrations,
      icon: <ShapesIcon {...iconProps(19)} />,
      disabled: isChangelogOnly,
    },
    {
      id: 'api',
      name: 'API',
      pageId: PageId.SettingsAPI,
      icon: <CodeBorderIcon {...iconProps(14)} />,
    },
    {
      id: 'billing',
      name: 'Billing',
      pageId: PageId.SettingsBilling,
      icon: <BagIcon {...iconProps(18)} />,
      disabled: !hasBillingAccess,
    },
  ];

  return [{
    id: 'settings',
    label: 'Settings',
    actions: settings
      .filter(setting => !setting.disabled)
      .map(setting => ({
        id: setting.id,
        label: setting.name,
        icon: setting.icon,
        linkTo: getUrl(setting.pageId),
        onClick: (e) => {
          setSettingsFromUrl();
          if (!e.metaKey) closeCommandBar();
        },
        onSelect: () => {
          setSettingsFromUrl();
          closeCommandBar();
          navigate(setting.pageId);
        },
      })),
  }];
};
