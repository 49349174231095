import { AddNewDocAttributeValue, DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { useDocCoverUpdate } from 'src/hooks/doc/useDocCoverUpdate';
import { useDocCreate } from 'src/hooks/doc/useDocCreate';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { getInheritedAttributeFromDoc, getSubmittableAttributeValues } from 'src/utils/attributes.util';

import { useDocInsightsCache } from './useDocInsights.cache';
import { addDocAutomationWatch } from '../../reactives/docAutomationWatch.reactive';
import { useCustomerDocFromCache } from '../api/cache/cacheCustomerDoc';
import { useUpdateChildCache } from '../api/cache/cacheHierarchy';
import { useDocAttributeUpdate } from '../api/useDocAttributeUpdate';

type CreateInsightParams = {
  assignee: string;
  contentJSON: string;
  customerId: string;
  cover?: string;
  docLinkContent: string | null;
  docLinkSourceId?: string;
  doctypeId: string;
  parentId?: string;
  properties: AddNewDocAttributeValue[];
  title: string;
  blockId?: string | null;
  sourceId?: string;
  withLinear?: boolean;
  productAreaIds?: string[];
};

type CreateInsightOptions = {
  parentDoc?: DocBaseFragment | null;
};

export const useDocInsightCreate = () => {
  const {
    create: createDoc, isLoading,
  } = useDocCreate();
  const { add } = useDocInsightsCache();
  const updateChild = useUpdateChildCache();
  const { addCustomerDoc } = useCustomerDocFromCache();
  const { addAttributeToDoc } = useDocAttributeUpdate();
  const { update: updateDocCover } = useDocCoverUpdate();

  const create = useCallback(async (
    {
      assignee,
      contentJSON,
      customerId,
      docLinkContent,
      docLinkSourceId,
      doctypeId,
      parentId,
      properties,
      title,
      blockId,
      sourceId,
      cover,
      withLinear,
      productAreaIds,
    }: CreateInsightParams,
    options?: CreateInsightOptions,
  ) => {
    const newInsight = await createDoc({
      assignee,
      attributes: properties,
      contentJSON,
      customer: { id: customerId },
      ...docLinkSourceId && {
        docLink: {
          sourceId: docLinkSourceId,
          blockId,
          content: docLinkContent,
        },
      },
      doctypeId,
      parentId,
      title,
      ...sourceId && {
        source: {
          sourceId,
        },
      },
      withLinear,
      productAreaIds,
    });
    if (!newInsight) return;

    if (withLinear && newInsight.parent?.id) {
      addDocAutomationWatch(newInsight.parent.id);
    }

    /**
     * Add cover to insight if exist
     */
    if (cover) {
      await updateDocCover({
        docId: newInsight.id,
        image: { avatarUrl: cover },
      });
    }
    /**
     * If parentDoc is passed, make sure the parent inherit of common attributes
     * from the new insight doc
     */
    if (options?.parentDoc) {
      const parentDocType = getDocType(options.parentDoc.doctype.id);
      const { common } = getInheritedAttributeFromDoc({
        originAttributes: nodeToArray(newInsight.attributes), // insight
        targetAttributesDefinitions: nodeToArray(parentDocType?.attributeDefinitions), // parent
      });

      // Only add attributes that are not already on the parent doc
      const parentAttributes = nodeToArray(options?.parentDoc.attributes);
      const commonFiltered = common.filter(c => !parentAttributes.some(p => p.definition.id === c.definition.id));
      if (commonFiltered.length) {
        await Promise.all(getSubmittableAttributeValues(commonFiltered).map(c => {
          return addAttributeToDoc({
            attributeDefinitionId: c.attributeDefinitionId,
            docId: options.parentDoc?.id || '',
            value: c.value,
          });
        }));
      }
    }
    if (docLinkSourceId && newInsight.docSource?.__typename) {
      // DocSource and DocTarget have the same id, just different type.
      // DocSource_{uuid}_{uuid}
      const decodedDocSourceId = atob(newInsight.docSource.id);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      add({
        feedbackId: docLinkSourceId,
        docTarget: {
          __typename: 'DocTarget',
          id: btoa(decodedDocSourceId.replace(newInsight.docSource.__typename, 'DocTarget')),
          content: newInsight.docSource.content,
          blockId: newInsight.docSource.blockId,
        },
        docId: newInsight.id,
      });
    }
    if (newInsight.customer?.id) {
      addCustomerDoc({
        doc: newInsight,
        customer: newInsight.customer,
      });
    }
    if (newInsight.parent?.id) {
      await updateChild({
        docs: [{
          docId: newInsight.id,
          parentId: undefined,
        }],
        newParentId: newInsight.parent.id,
      });
    }
  }, [createDoc, updateDocCover, addAttributeToDoc, add, addCustomerDoc, updateChild]);

  return {
    create,
    isLoading,
  };
};
