import { ActionButton } from '@cycle-app/ui';
import { ImageIcon, TrashIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import CoverDropzoneOverlay from 'src/components/CoverDropzoneOverlay/CoverDropzoneOverlay';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { closePanel } from 'src/reactives/docRightPanel.reactive';

import { CoverContainer, Cover, StyledImageMenu } from './DocPanelCover.styles';

interface Props {
  docId: string;
  coverUrl: string;
  isDragActive: boolean;
  getDropzoneProps: () => DropzoneRootProps;
  onUpdateCoverClicked: VoidFunction;
  className?: string;
  withNonConsistentDesign?: boolean;
  horizontalLayout?: boolean;
}

const DocPanelCover: FC<Props> = ({
  docId,
  coverUrl,
  isDragActive,
  getDropzoneProps,
  onUpdateCoverClicked,
  className,
  withNonConsistentDesign,
  horizontalLayout,
}) => {
  const editable = !coverUrl.startsWith('blob:'); // optimistic data
  const { removeDocCover } = useDocCoverMutations(docId);
  const getZoomableProps = useZoomableProps();
  return (
    <CoverContainer
      withNonConsistentDesign={withNonConsistentDesign}
      className={className}
      {...getDropzoneProps?.()}
    >
      {isDragActive && (
        <CoverDropzoneOverlay
          label="Update cover"
          noBorderRadius={withNonConsistentDesign}
          horizontalLayout={horizontalLayout}
        />
      )}

      <Cover
        {...getZoomableProps({
          src: coverUrl,
          onMouseDown: closePanel,
        })}
      />

      {editable && (
        <StyledImageMenu>
          <ActionButton
            tooltip="Update"
            tooltipPlacement="top"
            onClick={onUpdateCoverClicked}
          >
            <ImageIcon />
          </ActionButton>

          <ActionButton
            tooltip="Delete"
            tooltipPlacement="top"
            onClick={removeDocCover}
          >
            <TrashIcon />
          </ActionButton>
        </StyledImageMenu>
      )}
    </CoverContainer>
  );
};

export default DocPanelCover;
