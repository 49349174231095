import {
  ChangelogBaseFragment, Color, ContentSection, HeroSection, ReleaseTagStyle, Typography, ReleaseNoteTagValue,
} from '@cycle-app/graphql-codegen';
import { useFormContext } from 'react-hook-form';

import { CHANGELOG_TEMPLATES } from './constants/templates';
import { heroImageSuggestions } from './content/hero/heroImages';

type ChangelogBuilderFormData = Pick<ChangelogBaseFragment,
| 'socialLinkText'
| 'socialLinkURL'
| 'subtitle'
| 'title'
| 'language'
| 'shipBadgeColor'
| 'typography'
| 'heroSection'
| 'contentSection'
| 'headersColor'
| 'bodyColor'
| 'backgroundColor'
| 'dividerColor'
| 'linkColor'
| 'releaseTagStyle'
| 'releaseTagColor'
| 'subscribeLabel'
| 'subscribeToggled'
| 'dateStyle'
| 'heroStyle'
| 'availableHeroImages'
> & {
  logo: File | string | null;
  favicon: File | string | null;
  tags: {
    id: string;
    value: string;
    color: string;
    enabled: boolean;
  }[];
  subscribeWebhook: {
    url: string;
    label: string;
  };
  heroImage: File | string | null;
};

export function generateChangelogDefaultValues(changelog: ChangelogBaseFragment): ChangelogBuilderFormData {
  return {
    socialLinkText: changelog.socialLinkText || '',
    socialLinkURL: changelog.socialLinkURL || '',
    subtitle: changelog.subtitle || '',
    title: changelog.title || '',
    language: changelog.language,
    logo: changelog.logo?.url || null,
    favicon: changelog.favicon?.url || null,
    shipBadgeColor: changelog.shipBadgeColor || Color.A,
    typography: changelog.typography || Typography.Inter,
    heroSection: changelog.heroSection || HeroSection.L,
    contentSection: changelog.contentSection || ContentSection.Unique,
    headersColor: changelog.headersColor || '#000000',
    bodyColor: changelog.bodyColor || '#000000',
    backgroundColor: changelog.backgroundColor || '#FFFFFF',
    dividerColor: changelog.dividerColor || '#E6E6E6',
    linkColor: changelog.linkColor || '#2B44E7',
    releaseTagStyle: changelog.releaseTagStyle || ReleaseTagStyle.Background,
    releaseTagColor: changelog.releaseTagColor || '#000000',
    tags: generateTags(changelog.releaseNoteTagValues),
    subscribeToggled: changelog.subscribeToggled,
    subscribeLabel: changelog.subscribeLabel || 'Subscribe for updates',
    subscribeWebhook: {
      url: changelog.subscribeWebhook?.url || '',
      label: changelog.subscribeWebhook?.label || '',
    },
    dateStyle: changelog.dateStyle,
    heroStyle: changelog.heroStyle,
    heroImage: changelog.heroImage?.url || changelog.availableHeroImages[0] || heroImageSuggestions[0] || null,
    availableHeroImages: getAvailableHeroImages(changelog.availableHeroImages, changelog.heroImage?.url),
  };
}

function generateTags(tags: ReleaseNoteTagValue[]): ChangelogBuilderFormData['tags'] {
  return [
    ...tags
      .map(tag => ({
        id: tag.id,
        value: tag.value,
        color: tag.color,
        enabled: true,
      })),
    ...['Fixed', 'Changed', 'Bug fix', 'Feature', 'Performance', 'Design', 'Update']
      .filter(tag => !tags.find(t => t.value === tag))
      .map((tag, tagIndex) => {
        const tagColors = CHANGELOG_TEMPLATES[0]?.theme.tagColors || [];
        return {
          id: crypto.randomUUID(),
          color: tagColors[tagIndex % tagColors.length] || '',
          value: tag,
          enabled: false,
        };
      })
    ,
  ];
}

function getAvailableHeroImages(heroImages?: string[], currentHeroImage?: string) {
  return [
    ...currentHeroImage ? [currentHeroImage] : [],
    ...heroImages?.filter(x => x !== currentHeroImage) ?? [],
  ];
}

export function useChangelogBuilderForm() {
  return useFormContext<ChangelogBuilderFormData>();
}
