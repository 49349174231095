import {
  DocFullAttributesFragment,
  ViewType,
} from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { nodeToArray, useResizeObserver } from '@cycle-app/utilities';
import { produce } from 'immer';
import remove from 'lodash/remove';
import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { isPresent } from 'ts-is-present';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useFullDoc, useOptimizedBooleanState, useProductDoctypes } from 'src/hooks';
import { useRemoveDocAssignee, useUpdateDocAssignee } from 'src/hooks/api/mutations/updateDocHooks';
import { useCreateDoc } from 'src/hooks/api/mutations/useCreateDoc';
import { useDocAttributesFromBoardConfig } from 'src/hooks/api/useAttributesFromBoardConfig';
import { useCompatibility } from 'src/hooks/useCompatibility';
import { useFirstStatusinView } from 'src/hooks/useFirstStatusinView';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocAttributeCount, getDocAttributeInput } from 'src/utils/attributes.util';
import { doctypeHasAutomation } from 'src/utils/doctype.automation.util';
import { isCustom, isFeedback, isInsight } from 'src/utils/docType.util';

import { DropdownLayer } from '../DropdownLayer';
import { DropdownContent, StyledDocPanelDocAttributes, StyledDocStatus, StyledDropdownAction } from './DocItemDraftAttributes.styles';
import { PARENT_MAX_WIDTH } from './DocItemDraftParent.styles';

interface Props {
  doctypeId: string;
  groupId?: string;
  onDocCreated?: (data: { id: string; parent?: { id?: string } | null }) => void;
  parentId?: string;
  statusId?: string;
  viewType: ViewType;
  parent?: ReactNode;
  title?: ReactNode;
  containerWidth?: number;
  withLinearChecked: boolean;
  onWithLinearChange: (checked: boolean) => void;
}

export const DocItemDraftAttributes: FC<React.PropsWithChildren<Props>> = ({
  doctypeId,
  groupId,
  onDocCreated,
  parentId,
  statusId,
  parent,
  title,
  viewType,
  containerWidth,
  withLinearChecked,
  onWithLinearChange,
}) => {
  const draftAttributesRef = useRef<DocFullAttributesFragment['attributes']>();
  const firstStatusinView = useFirstStatusinView();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const isGroupByStatus = useBoardConfig(ctx => ctx.isGroupByStatus);
  const isGroupByProductAreas = useBoardConfig(ctx => ctx.isGroupByProductAreas);
  const doctypes = useProductDoctypes();
  const { removeDocAssignee } = useRemoveDocAssignee();
  const { updateDocAssignee } = useUpdateDocAssignee();
  const {
    createDoc,
    createdDocData,
    loading,
  } = useCreateDoc({
    // always put in on top whe creating a draft.
    // isDraft + bottom fails.
    from: 'top',
    groupId,
    onCreated: onDocCreated,
    statusId: statusId ?? firstStatusinView?.id,
    skipCache: true,
  });
  const createDocRef = useRef(createDoc);
  // Always get the last ref without running the effect.
  createDocRef.current = createDoc;

  const { doc } = useFullDoc({
    docId: createdDocData?.addNewDoc?.id,
  });
  const {
    getHiddenAttributeDefinitionIds, readOnlyAttributeDefinitionIds,
  } = useDocAttributesFromBoardConfig({ docId: doc?.id });
  const selectedDoctype = doctypes.find(d => d.id === doctypeId);
  const {
    getCompatibleStatuses, isProductAreaRequiredToBeVisible, productAreasFromBoardConfig, isProductAreaReadonly, canProductAreaCreateOption,
  } = useCompatibility();

  const compatibleStatusIds = useMemo(() => getCompatibleStatuses().map(status => status.id), [getCompatibleStatuses]);
  const defaultProductAreaIds = useMemo(() => productAreasFromBoardConfig.map(a => a?.id || '').filter(id => !!id), [productAreasFromBoardConfig]);
  // Keep track of the doc attributes, we re-use them when the user creates a new draft (eg. on doctype change).
  draftAttributesRef.current = produce(doc, draft => {
    if (draft?.attributes.edges) {
      remove(draft.attributes.edges, ({ node }) => (
        !nodeToArray(selectedDoctype?.attributeDefinitions).find(attr => attr.id === node.definition.id)
      ));
    }
  })?.attributes;
  const draftAttributesCount = getDocAttributeCount(nodeToArray(draftAttributesRef.current));

  useEffect(() => {
    if (selectedDoctype) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      createDocRef.current({
        isDraft: true,
        title: '',
        doctype: selectedDoctype,
        attributes: nodeToArray(draftAttributesRef.current)
          .map(getDocAttributeInput)
          .filter(isPresent),
        parentId,
        // back end handles the value when grouped by product area.
        ...!isGroupByProductAreas && isProductAreaRequiredToBeVisible && defaultProductAreaIds.length && {
          // feedback is multiselect
          productAreaIds: isFeedback(selectedDoctype) ? defaultProductAreaIds : defaultProductAreaIds.slice(0, 1),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctype?.id]);

  const [isDropdownVisible, {
    setTrueCallback: openDropdown, setFalseCallback: closeDropdown,
  }] = useOptimizedBooleanState(false);

  const attributesMeasure = useResizeObserver<HTMLDivElement>();
  const attributesWidthRef = useRef<number>();
  if (attributesMeasure.rect?.width) {
    // store last known width, this avoid issues when switching from inline to dropdown.
    attributesWidthRef.current = attributesMeasure.rect.width;
  }
  const isMobile = useIsMobile();
  // First time we add an assignee.
  // Subsequents updates are done in DocAssignee in attributes component.
  const onAssigneePropertyUpdated = async (userId: string | null) => {
    if (!doc) return;
    if (userId) {
      await updateDocAssignee({
        docId: doc?.id,
        userId,
      }, true);
    } else {
      await removeDocAssignee({ docId: doc.id });
    }
  };

  const disableCustomerCreation = !!boardConfig?.filterProperties.edges.find(({ node }) => (
    node.__typename === 'FilterPropertyRuleCustomer'
  ));
  const isKanban = viewType === ViewType.Kanban;
  const withLinear = doctypeHasAutomation(selectedDoctype?.id);
  const attributesElement = doc && (
    <StyledDocPanelDocAttributes
      ref={attributesMeasure.setRef}
      doc={doc}
      hideStatusLabel
      hideIncompatibleValues
      hiddenAttributeDefinitionIds={getHiddenAttributeDefinitionIds(groupId)}
      placement="bottom-start"
      readOnlyAttributeDefinitionIds={readOnlyAttributeDefinitionIds}
      readOnlyStatus={isGroupByStatus}
      showDoctype={false}
      showAssignee
      showCustomer
      showStatus={isKanban}
      showLinear={isCustom(selectedDoctype) && !withLinear}
      onAssigneePropertyUpdated={doc.assignee ? undefined : onAssigneePropertyUpdated}
      disableCustomerCreation={disableCustomerCreation}
      compatibleStatusIds={compatibleStatusIds}
      showCustomAttributes
      context="doc-item"
      showLinearAutoCreate={withLinear}
      withLinearChecked={withLinearChecked}
      onWithLinearChange={onWithLinearChange}
      isProductAreaReadonly={isProductAreaReadonly}
      canProductAreaCreateOption={canProductAreaCreateOption}
    />
  );

  if (loading || !doc) {
    return isKanban
      ? (
        <>
          {parent}
          {title}
          <Skeleton height={20} width={50} />
        </>
      )
      : (
        <>
          <Skeleton height={24} width={22} />
          {parent}
          {title}
        </>
      );
  }

  const renderParentInDropdown =
    !isKanban &&
    !!parent &&
    (
      isMobile ||
      // 200 is an arbitrary values for other elements (cancel, create etc...).
      (!!containerWidth && PARENT_MAX_WIDTH + 200 > containerWidth / 2)
    );

  const renderAttributesInDropdown =
    !isKanban &&
    (
      isMobile ||
      (
        !!containerWidth &&
        !!attributesWidthRef.current &&
        attributesWidthRef.current + 200 > containerWidth / 2
      )
    );

  const renderDropdown = renderParentInDropdown || renderAttributesInDropdown;

  const dropdownCount =
    draftAttributesCount +
    (renderParentInDropdown ? 1 : 0) +
    (doc.assignee ? 1 : 0) +
    (doc.customer ? 1 : 0);

  return (
    <>
      {!isKanban && doc.status && (
        <StyledDocStatus
          compatibleStatusIds={compatibleStatusIds}
          docId={doc.id}
          docTypeId={doc.doctype.id}
          hideLabel
          isDisabled={isInsight(doc.doctype) || isGroupByStatus}
          statusId={doc.status.id}
        />
      )}
      {!renderParentInDropdown && parent}
      {title}
      {!renderDropdown && attributesElement}
      {renderDropdown && (
        <DropdownLayer
          visible={renderDropdown && isDropdownVisible}
          hide={closeDropdown}
          placement="bottom-start"
          layer={Layer.Sidebar}
          content={(
            <DropdownContent>
              {renderParentInDropdown && parent}
              {attributesElement}
            </DropdownContent>
          )}
        >
          <StyledDropdownAction
            size="L"
            forceFocus={isDropdownVisible}
            onClick={openDropdown}
            tooltipPlacement="top"
            tooltip={['Add property', renderParentInDropdown ? ' and parent' : ''].join('')}
          >
            {`+${dropdownCount}`}
          </StyledDropdownAction>
        </DropdownLayer>
      )}
    </>
  );
};
