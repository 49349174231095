import { SelectPanel } from '@cycle-app/ui';
import { BackArrowIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { useProductKeywords } from 'src/hooks/api/fragments/useProductKeywords';
import { useCreateKeyword } from 'src/hooks/api/mutations/useCreateKeyword';
import { useUpdateKeyword } from 'src/hooks/api/mutations/useUpdateKeyword';
import { Action as ActionType } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';

import { ActionButton } from './ActionButton';

export const ActionKeywords = ({ action }: { action: ActionType }) => (
  <ToggleDropdown
    placement="bottom"
    layer={Layer.DropdownModalZ3}
    content={<KeywordsPanel />}
    button={props => (
      <ActionButton
        action={action}
        onClick={props.onClick}
        active={props['data-active']}
      />
    )}
  />
);

const KeywordsPanel = () => {
  const state = useEditorContext(ctx => ctx.editor.view.state);
  const text = useRef(state.doc.textBetween(state.selection.from, state.selection.to, '').trim());

  const keywords = useProductKeywords();
  const [createKeyword] = useCreateKeyword();
  const [updateKeyword] = useUpdateKeyword();
    
  const [selectedKeywordId, setSelectedKeywordId] = useState<string | null>(null);
  const selectedKeyword = keywords.find(keyword => keyword.id === selectedKeywordId);

  if (selectedKeyword) {
    return (
      <SelectPanel
        key="misspellings"
        className="max-w-96"
        header={(
          <div className="flex items-center gap-2 overflow-hidden">
            <button
              className="btn-tertiary btn-sm size-5"
              onClick={() => setSelectedKeywordId(null)}
            >
              <BackArrowIcon />
            </button>
            <div className="truncate">
              {'Misspellings for '}
              <span className="font-semibold">
                {selectedKeyword.value}
              </span>
            </div>
          </div>
        )}
        searchPlaceholder="Search misspelling..."
        defaultFilter={text.current}
        options={selectedKeyword.misspellings.map((misspelling, index) => ({
          value: index.toString(),
          label: misspelling,
        }))}
        onOptionChange={() => {}}
        clearSearchOnCreate
        onCreateOption={async label => {
          await updateKeyword(selectedKeyword, [label], { optimistic: false });
          text.current = '';
        }}
      />
    );
  }

  return (
    <SelectPanel
      key="keywords"
      className="max-w-96"
      header="Keywords"
      searchPlaceholder="Search keyword..."
      options={keywords.map(keyword => ({
        value: keyword.id,
        label: keyword.value,
      }))}
      onOptionChange={option => {
        setSelectedKeywordId(option.value);
      }}
      onCreateOption={async label => {
        await createKeyword(label, { optimistic: false });
      }}
    />
  );
};