import { IntegrationType } from '@cycle-app/graphql-codegen';

import { extract } from 'src/types/graphql.types';

import { useProductIntegrations } from '../api/useProductIntegrations';

export const useSlackIntegration = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Slack);
  const provider =  extract('Slack', integration?.provider);
  return {
    integration,
    isInstalled: !!provider,
    provider,
  };
};
