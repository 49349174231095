import { HIGHLIGHT_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { IntegrationType } from '@cycle-app/graphql-codegen';
import { FC, useMemo } from 'react';

import { GithubIssuesCreationModal } from 'src/components/GithubIssuesCreationModal/GithubIssuesCreationModal';
import { TextColorDropdown } from 'src/components/TextColorDropdown';
import { useEditorContext } from 'src/contexts/editorContext';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { Action as ActionType, ActionId } from 'src/services/editor/editorActions';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

import { LinearIssueCreationModalTrigger } from '../../LinearIssueCreationModal/LinearIssueCreationModalTrigger';
import { DocMentionDropdown } from '../DocMentionDropdown/DocMentionDropdown';
import LinkDropdown from '../LinkDropdown/LinkDropdown';
import { LinkInput } from '../LinkInput';
import { ActionButton } from './ActionButton';
import { ActionKeywords } from './ActionKeywords';

type ActionProps = {
  action: ActionType;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: VoidFunction;
  // eslint-disable-next-line react/no-unused-prop-types
  active?: boolean;
  onClose?: VoidFunction;
};

export const Action: FC<React.PropsWithChildren<ActionProps>> = (props) => {
  const { action } = props;
  const disabledActions = useEditorContext(ctx => ctx.disabledActions);
  if (action.id === ActionId.Link && disabledActions?.includes(ActionId.MentionDoc)) {
    return (
      <LinkInput
        button={buttonProps => (
          <ActionButton
            {...props}
            onClick={(e) => {
              buttonProps.onClick(e);
              props.onClick?.();
            }}
          />
        )}
      />
    );
  }

  if (action.id === ActionId.Link && !disabledActions?.includes(ActionId.MentionDoc)) {
    return (
      <LinkDropdown>
        <ActionButton {...props} />
      </LinkDropdown>
    );
  }

  if (action.id === ActionId.Ai) return <ActionButton {...props} hasGradient />;
  if (action.id === ActionId.TurnTextIntoInsight) return <TurnTextIntoInsight {...props} />;
  if (action.id === ActionId.TurnTextIntoDocMention) return <TurnTextIntoDocMention {...props} />;
  if (action.id === ActionId.TurnTextIntoGithubIssueMention) return <GitHubButton {...props} />;
  if (action.id === ActionId.TurnTextIntoLinearMention) return <LinearButton {...props} />;
  if (action.id === ActionId.Colors) return <TextColor {...props} />;
  if (action.id === ActionId.Keywords) return <ActionKeywords {...props} />;
  return <ActionButton {...props} />;
};

const TurnTextIntoInsight = (props: ActionProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const isActiveHighlight = editor.isActive(HIGHLIGHT_EXTENSION_NAME);

  const actionButton = (
    <ActionButton
      {...props}
      onClick={handleClick}
      disabledTooltip={isActiveHighlight ? 'Your selection is already linked to an insight' : undefined}
    />
  );

  return actionButton;

  function handleClick(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (isActiveHighlight) return;
    requestInsightCreate(props.onClick);
  }
};

const TurnTextIntoDocMention = (props: ActionProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const { selectionValues } = getDocTitlesFromSelectedText(editor.state);
  const isTurnIntoMentionDisabled = !selectionValues.length;
  return (
    <DocMentionDropdown
      isDisabled={isTurnIntoMentionDisabled}
      button={buttonProps => (
        <ActionButton
          {...props}
          isDocMention
          onClick={(e) => {
            props.onClick?.();
            buttonProps.onClick(e);
          }}
        />
      )}
    />
  );
};

const GitHubButton = (props: ActionProps) => {
  const {
    list,
    getIntegration,
  } = useProductIntegrations();
  const isGithubActive = useMemo(() => !!getIntegration(IntegrationType.Github)?.provider?.id, [list]);

  if (!isGithubActive) return null;

  return (
    <GithubIssuesCreationModal>
      <ActionButton {...props} />
    </GithubIssuesCreationModal>
  );
};

const LinearButton = (props: ActionProps) => {
  const {
    list,
    getIntegration,
  } = useProductIntegrations();
  const { action } = props;
  const isLinearActive = useMemo(() => !!getIntegration(IntegrationType.Linear)?.provider?.id, [list]);

  if (!isLinearActive) return null;

  return (
    <LinearIssueCreationModalTrigger shortcut={action.shortcut}>
      <ActionButton {...props} />
    </LinearIssueCreationModalTrigger>
  );
};

const TextColor = (props: ActionProps) => {
  const editor = useEditorContext(ctx => ctx.editor);

  return (
    <TextColorDropdown
      button={(onClick) => (
        <ActionButton
          action={props.action}
          onClick={onClick}
          active={!!editor.getAttributes('textStyle').color}
        />
      )}
      onSelect={(color) => {
        editor.chain().focus().setColor(color).run();
        props.onClose?.();
      }}
      onClear={() => {
        editor.chain().focus().unsetColor().run();
        props.onClose?.();
      }}
    />
  );
};
