import { ViewType } from '@cycle-app/graphql-codegen';
import { Navigation } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { useRef, RefObject, memo } from 'react';
import { useTheme } from 'styled-components';

import { useSidebarShortcutListener } from 'src/hooks/shortcuts/useSidebarShortcutListener';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { usePageId, useParentPage, PageId, useIsDocPanelPage } from 'src/hooks/usePageId';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { HANDLE_RESIZE_DIV_ID, useSidebarResize } from 'src/hooks/useSidebarResize';
import { useViewType } from 'src/reactives/boardConfig.reactive';
import { toggleSidebar, useGetSidebarResising, useGetSidebarWidth, setSidebarWidth, setSidebarResising } from 'src/reactives/sidebar.reactive';

import { Container, HandleResize, ToggleSidebarButton } from './Sidebar.styles';
import { SidebarActions } from './SidebarActions/SidebarActions';
import { SidebarBilling } from './SidebarBilling';
import { SidebarBoard, ScrollableSection } from './SidebarBoard/SidebarBoard';
import { ActionsSection, SidebarFooter } from './SidebarBoard/SidebarBoard.styles';
import { SidebarFooterActions } from './SidebarFooterActions';
import { SidebarHeader } from './SidebarHeader/SidebarHeader';

const Sidebar = memo(() => {
  const isOnboarding = useIsOnboarding();
  const { viewType } = useViewType();
  const { width: widthNav } = useGetSidebarWidth();
  const collapsed = useSidebarCollapsed();
  const navigationRef = useRef<HTMLDivElement>(null);
  useSidebarShortcutListener();

  const { resizing } = useGetSidebarResising();
  const isDocPanelPage = useIsDocPanelPage();
  const animateSidebar = isDocPanelPage && !resizing;

  const theme = useTheme();
  const isPageWithBorder = usePageId(
    pageId => pageId === PageId.DocFullPage ||
      pageId === PageId.Customer || pageId === PageId.Company || pageId === PageId.Quote ||
      pageId === PageId.Releases || pageId === PageId.Release || pageId === PageId.ReleaseNote || pageId === PageId.ReleaseDoc ||
      (!theme.isDark && (pageId === PageId.Inbox || pageId === PageId.Roadmap || pageId === PageId.Customers)) ||
      (pageId === PageId.InboxView && !theme.isDark && viewType === ViewType.List),
  );

  const isPageWithoutSidebar = useParentPage(
    parent => ['settings', 'billing-update'].includes(parent),
  );

  if (isPageWithoutSidebar) return null;

  return (
    <Container $withBorder={isPageWithBorder}>
      <Navigation
        ref={navigationRef}
        $collapsed={collapsed}
        $width={widthNav}
        animate={animateSidebar}
      >
        <SidebarHeader />

        <ActionsSection className="!mt-0">
          <SidebarActions />
        </ActionsSection>

        <ScrollableSection collapsed={collapsed}>
          <SidebarBoard collapsed={collapsed} />

          {!isOnboarding && (
            <SidebarFooter>
              {!collapsed && <SidebarBilling />}
              <SidebarFooterActions />
            </SidebarFooter>
          )}

        </ScrollableSection>

        <Resize containerRef={navigationRef}>
          <ToggleSidebarButton
            $collapsed={collapsed}
            onMouseDown={e => {
              e.stopPropagation();
            }}
            onClick={e => {
              e.stopPropagation();
              toggleSidebar();
            }}
          >
            <CaretIcon />
          </ToggleSidebarButton>
        </Resize>
      </Navigation>
    </Container>
  );
});

export default Sidebar;

const Resize = ({
  containerRef, children,
}: React.PropsWithChildren<{ containerRef: RefObject<HTMLDivElement> }>) => {
  const { resizing } = useGetSidebarResising();
  const collapsed = useSidebarCollapsed();

  const { startListen } = useSidebarResize({
    containerRef,
    minWidth: 185,
    maxWidth: 350,
    onResizeStart: () => {
      setSidebarResising({ resizing: true });
    },
    onResizeEnd: (newWidth: number) => {
      setSidebarResising({ resizing: false });
      setSidebarWidth({ width: newWidth });
    },
    onResizeMove: (newWidth: number) => {
      setSidebarWidth({ width: newWidth });
    },
    resizing,
    disabled: collapsed,
  });

  return (
    <HandleResize
      id={HANDLE_RESIZE_DIV_ID}
      resizingDisabled={collapsed}
      resizing={resizing}
      onKeyDown={e => e.preventDefault()}
      onMouseDown={e => {
        if (collapsed) {
          toggleSidebar();
        } else {
          startListen(e);
        }
      }}
    >
      {children}
    </HandleResize>
  );
};
