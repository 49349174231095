import { format, parseISO } from 'date-fns';

import { useDocBase } from 'src/hooks/api/useDoc';

import { useQuoteId } from './QuoteContext';

export const QuotePanelCreationDate = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  const createdAt = doc?.createdAt;
  if (!createdAt) return null;
  return (
    <div className="flex items-center gap-2 px-2 font-medium text-primary">
      {format(parseISO(createdAt), 'MMMM d, yyyy')}
    </div>
  );
};
