import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import { DocTargetsDocument } from '@cycle-app/graphql-codegen';
import { isPresent } from 'ts-is-present';

export const useDocTargetsSubscription = (
  sourceDocId: string | null | undefined,
  options?: Pick<SubscriptionHookOptions, 'skip'>,
) => {
  useSubscription(DocTargetsDocument, {
    ignoreResults: true,
    skip: !sourceDocId || options?.skip,
    variables: {
      sourceDocId: sourceDocId as string,
    },
    onData: ({
      data: { data },
      client: { cache }, 
    }) => {
      if (!data) return;

      // Remove temp quotes (skeletons) and add verified quotes
      cache.modify({
        id: cache.identify({ id: sourceDocId }),
        fields: {
          docTargets: (refs, { toReference }) => ({
            ...refs,
            edges: [
              ...refs.edges.filter((edge: { node: { id?: string } }) => !edge.node.id?.includes('temp')),
              ...data.docTargets.filter(isPresent).map(docTarget => ({
                __typename: 'DocTargetEdge',
                node: {
                  __typename: 'DocTarget',
                  id: docTarget.id,
                  content: docTarget.doc?.docSource?.content,
                  blockId: docTarget.doc?.docSource?.blockId,
                  doc: docTarget.doc ? toReference(docTarget.doc.id) : null,
                },
              })),
            ],
          }),
        },
      });
    },
  });
};
