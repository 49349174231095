import { Icon } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { memo } from 'react';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useFeatureFlag } from 'src/hooks';
import { openCommandBar } from 'src/hooks/modals/useCommandBarModal';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { openAsk } from 'src/reactives/ask.reactive';
import { ShortcutCommandbar } from 'src/types/shortcuts.types';

export const SidebarActions = memo(() => {
  const isChangelogOnly = useIsChangelogOnly();
  const collapsed = useSidebarCollapsed();
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  return (
    <>
      <CollapsableNavigationItem
        icon={<Icon name="search" className="size-4" />}
        label="Search"
        isSidebarColor
        shortcut={shortcuts[ShortcutCommandbar.ToggleCommandBar]}
        onClick={() => openCommandBar()}
        animate
        collapsed={collapsed}
      />

      {isAskEnabled && !isChangelogOnly && (
        <CollapsableNavigationItem
          icon={<AiIcon hasGradient />}
          label="Ask Cycle"
          isSidebarColor
          onClick={() => openAsk('cycle')}
          animate
          collapsed={collapsed}
        />
      )}
    </>
  );
});
