import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { addFakeAttributes } from '@cycle-app/front/tests/fakes/customerAttributes';
import { SearchProductCompaniesDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo, useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { defaultCompaniesPagination } from 'src/utils/pagination.util';

interface Options {
  defaultSearch?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useCompanies = (options?: Options) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(SearchProductCompaniesDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: options?.skip,
    variables: {
      id: productId,
      ...options?.defaultSearch && { searchText: options.defaultSearch },
      ...defaultCompaniesPagination,
    },
  });

  const companies = useMemo(() => {
    if (data?.node?.__typename !== 'Product') return [];
    if (addFakeAttributes) {
      return nodeToArray(data.node.companies).map(addFakeAttributes);
    }
    return nodeToArray(data.node.companies);
  }, [data]);

  const pageInfo = data?.node?.__typename === 'Product' ? data.node.companies.pageInfo : null;

  const [fetchingMore, setFetchingMore] = useState(false);

  const fetchNextPage = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return;
    setFetchingMore(true);
    await fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
    setFetchingMore(false);
  }, [pageInfo, fetchMore]);

  return {
    companies,
    isLoading: loading,
    fetchingMore,
    fetchNextPage,
    pageInfo,
    refetch,
  };
};
