import { DoctypeFragment } from '@cycle-app/graphql-codegen';

import { useIsMobile } from 'src/reactives/responsive.reactive';

import QuickActions, { QuickActionsProps } from '../QuickActions/QuickActions';

export const EditorQuickActions = ({
  docType, showAddTemplate, showIntegrations, onHidePreviewTemplate, onShowPreviewTemplate, ...props
}: Omit<QuickActionsProps, 'showTemplateAction'> & { docType?: DoctypeFragment }) => {
  const isMobile = useIsMobile();
  return (
    <QuickActions
      {...props}
      showAddTemplate={showAddTemplate}
      showIntegrations={showIntegrations}
      docType={docType}
      {...!isMobile && ({
        onHidePreviewTemplate,
        onShowPreviewTemplate,
      })}
    />
  );
};
