import { make } from 'src/utils/reactives.util';

export const {
  setValue: setLearningCenter,
  hookValue: useLearningCenter,
} = make<{ isVisible: boolean }>({
  defaultState: {
    isVisible: false,
  },
});

export const openLearningCenter = () => setLearningCenter({ isVisible: true });
export const closeLearningCenter = () => setLearningCenter({ isVisible: false });