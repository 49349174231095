import { DoctypeType } from '@cycle-app/graphql-codegen';

import { useInsightDocType } from 'src/hooks';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDocChildrenSubscription } from 'src/hooks/api/useDocChildrenSubscription';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useUnlinkedDocChildSubscription } from 'src/hooks/api/useUnlinkedDocChildSubscription';
import { useGetDocType } from 'src/reactives/docTypes.reactive';

import { Container } from './DocHierarchy.styles';
import { DocHierarchyGroupInsight } from './DocHierarchyGroup/DocHierarchyGroupInsight';

type Props = {
  className?: string;
  docId?: string | null;
  count: number;
  onClickInsight: VoidFunction;
  isReadOnly?: boolean;
};

export const DocHierarchyInsight = ({
  className,
  docId: docIdProps = null,
  count,
  onClickInsight,
  isReadOnly = false,
}: Props) => {
  const { doc } = useDoc(docIdProps);
  const docType = useGetDocType(doc?.doctype.id);
  useDocChildrenSubscription(docIdProps);
  useUnlinkedDocChildSubscription(docIdProps);

  const productInsightDocType = useInsightDocType();
  const insightDocType = useDoctype(productInsightDocType?.id);
  if (!insightDocType) return null;

  return (
    <Container className={className}>
      <DocHierarchyGroupInsight
        parentId={doc?.id}
        isFeedback={docType?.type === DoctypeType.Feedback}
        doctypeChildren={insightDocType}
        count={count}
        onClickItem={onClickInsight}
        isReadOnly={isReadOnly}
      />
    </Container>
  );
};
