import { CompanyFragment } from '@cycle-app/graphql-codegen';
import { FC, useCallback } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { PageId } from 'src/constants/routing.constant';
import { useCompanyRemove } from 'src/hooks/api/mutations/customers/useCompanyRemove';
import { closeCompanyModal, getCompanyIdParam } from 'src/hooks/modals/useCompanyModal';
import { navigate } from 'src/hooks/useNavigate';
import { getPageId } from 'src/utils/routing.utils';

interface Props {
  onClose: () => void;
  company: CompanyFragment;
}

export const CompanyRemoveModal: FC<React.PropsWithChildren<Props>> = ({
  onClose, company,
}) => {
  const {
    deleteCompany, isLoading,
  } = useCompanyRemove();

  const onSubmit = useCallback(async () => {
    if (isLoading) return;

    if (getPageId() === PageId.Company) {
      navigate(PageId.Main);
    } else if (getCompanyIdParam()) {
      closeCompanyModal();
    }

    await deleteCompany({
      companyId: company.id,
    });
    onClose();
  }, [company, isLoading, onClose, deleteCompany]);

  return (
    <DialogModal
      title={`Are you sure you want to delete ${company.name}?`}
      hide={onClose}
      onConfirm={onSubmit}
      loading={isLoading}
    />
  );
};
