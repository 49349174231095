import { useFragment } from '@apollo/client';
import { DocDocSourceFragmentDoc } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui'; 
import { getDocSlug, getHighlightHash } from '@cycle-app/utilities';

import { PageId } from 'src/constants/routing.constant';
import { getUrl } from 'src/utils/routing.utils';

import { useQuoteId } from './QuoteContext';
import { QuotePanelLink } from './QuotePanelLink';


export const QuotePanelFeedback = () => {
  const quoteId = useQuoteId();
  
  const {
    data: doc, complete, 
  } = useFragment({
    fragment: DocDocSourceFragmentDoc,
    fragmentName: 'DocDocSource',
    from: quoteId,
  });

  if (!complete || !doc?.docSource?.doc) return null;

  return (
    <QuotePanelLink
      to={getUrl(PageId.DocFullPage, {
        docSlug: getDocSlug(doc.docSource.doc),
        hash: getHighlightHash({
          docId: doc.id,
          blockId: doc.docSource.blockId,
        }),
      })}
    >
      <Icon name="feedback" className="text-secondary" />
      <span className="truncate">
        {doc.docSource.doc.title.trim() || 'Untitled'}
      </span>
    </QuotePanelLink>
  );
};
