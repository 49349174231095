import { make } from 'src/utils/reactives.util';

import { LocalKey } from '../types/localStorage.types';

export type ChangelogBuilderResult = {
  isAdvancedOpen: boolean;
};

export const {
  hookState: useChangelogBuilder,
  hookValue: useGetChangelogBuilder,
  getValue: getChangelogBuilder,
  setValue: setChangelogBuilder,
  resetValue: resetChangelogBuilder,
} = make<ChangelogBuilderResult>({
  defaultState: {
    isAdvancedOpen: false,
  },
  localKey: LocalKey.ChangelogBuilder,
});

export const {
  hookState: useActiveChangelogId,
  getValue: getActiveChangelogId,
  setValue: setActiveChangelogId,
  resetValue: resetActiveChangelogId,
} = make<{ activeId?: string }>({
  defaultState: {
    activeId: undefined,
  },
});
