import { ActionButton, typo, SelectLine } from '@cycle-app/ui';
import { LineStart } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import styled, { css } from 'styled-components';

interface StyledActionButtonProps {
  hide: boolean;
  $context?: 'doc-item' | 'doc-panel';
  $isNewInbox?: boolean;
}
export const StyledActionButton = styled(ActionButton)<StyledActionButtonProps>`
  svg {
    width: 16px;
    height: 16px;
  }

  ${p => p.hide && css`
    opacity: 0;
    pointer-events: none;
    &:hover {
      opacity: 0;
    }
  `};

  ${p => p.$context === 'doc-item' && css`
    border-radius: 6px;
    --color: ${p.theme.colors.docItem.actionButton.color};
    --colorHover: ${p.theme.colors.docItem.actionButton.colorHover};
    --bgHover: ${p.theme.colors.docItem.actionButton.bgHover};
    --bgActive: ${p.theme.colors.docItem.actionButton.bgActive};
    --bg: ${p.theme.colors.card.bgHover};
  `}

  ${p => p.forceFocus && `
    opacity: 1 !important;
  `}

  ${p => p.$isNewInbox && `
    opacity: 1 !important;
    --bg: transparent
  `}
`;

export const StyledSelectLine = styled(SelectLine)`
  ${LineStart} {
    width: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

export const ActivityLine = styled(SelectLine)`
  height: auto;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption400}
`;

export const PanelContent = styled.div`
  padding: 8px 0;
`;

export const PanelFooter = styled.div`
  border-top: 1px solid ${p => p.theme.colors.dropdown.sep};
  padding: 6px 0;
`;
