import { useQuery } from '@apollo/client';
import { ProductActiveUsersDocument, ProductPendingUsersDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback, useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { extract } from 'src/types/graphql.types';

import { useProduct } from './useProduct';

export const PAGINATION_SIZE = 20;

export const getProductMembersQueryVariables = (productId: string) => ({
  productId,
  cursor: '',
  size: PAGINATION_SIZE,
});

export const useActiveUsers = (searchText?: string) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const {
    data, previousData, loading, fetchMore, 
  } = useQuery(ProductActiveUsersDocument, {
    notifyOnNetworkStatusChange: true,
    skip: !productId,
    variables: {
      ...getProductMembersQueryVariables(productId as string),
      searchText,
    },
  });

  const node = extract('Product', data?.node);
  const previousNode = extract('Product', previousData?.node);
  
  const users = useMemo(() => {
    return nodeToArray(node?.users ?? previousNode?.users);
  }, [node?.users, previousNode?.users]);

  const hasNextPage = !!node?.users?.pageInfo.hasNextPage;
  const endCursor = node?.users?.pageInfo.endCursor;
  const [loadingMore, setLoadingMore] = useState(false);

  const loadMore = async () => {
    if (!hasNextPage || !endCursor) return;
    setLoadingMore(true);
    await fetchMore({
      variables: {
        cursor: endCursor,
      },
    });
    setLoadingMore(false);
  };

  return {
    users,
    loading,
    hasNextPage,
    loadMore,
    loadingMore,
  };
};

export const usePendingUsers = (searchText?: string) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const {
    data, previousData, loading, fetchMore, 
  } = useQuery(ProductPendingUsersDocument, {
    notifyOnNetworkStatusChange: true,
    skip: !productId,
    variables: {
      ...getProductMembersQueryVariables(productId as string),
      searchText,
    },
  });

  const node = extract('Product', data?.node);
  const previousNode = extract('Product', previousData?.node);
  const users = nodeToArray(node?.notSignedUpUsers ?? previousNode?.notSignedUpUsers);
  const hasNextPage = !!node?.notSignedUpUsers?.pageInfo.hasNextPage;
  const endCursor = node?.notSignedUpUsers?.pageInfo.endCursor;
  const [loadingMore, setLoadingMore] = useState(false);

  const loadMore = async () => {
    if (!hasNextPage || !endCursor) return;
    setLoadingMore(true);
    await fetchMore({
      variables: {
        cursor: endCursor,
      },
    });
    setLoadingMore(false);
  };

  return {
    users,
    loading,
    hasNextPage,
    loadMore,
    loadingMore,
  };
};

export const useSearchUsers = ({
  searchText = '', paginationSize = PAGINATION_SIZE,
}: {
  searchText?: string;
  paginationSize?: number;
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { product } = useProduct('cache-only');

  const query = useQuery(ProductActiveUsersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      productId,
      cursor: '',
      size: paginationSize,
      searchText,
    },
  });

  const node = extract('Product', (query.data ?? query.previousData)?.node);

  const users = useMemo(() => {
    return nodeToArray(node?.users ?? product?.users).slice(0, paginationSize);
  }, [paginationSize, product?.users, node]);

  const hasMoreData = node?.users?.pageInfo?.hasNextPage ?? false;
  const cursor = node?.users?.pageInfo?.endCursor ?? '';

  const loadMore = useCallback(async () => {
    if (!hasMoreData) return;
    await query.fetchMore({
      variables: {
        cursor,
      },
    });
  }, [query, hasMoreData, cursor]);

  return {
    users,
    loadMore,
    hasMoreData,
    isLoading: query.loading,
  };
};
