import { typo, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddButton = styled(ActionButton).attrs({ variant: 'secondary' })`
  display: flex;
  width: 18px;
  height: 18px;
`;

export const DoctypeInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
`;

export const DoctypeLabel = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
`;

export const Items = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
