import {
  IntegrationType,
  SyncHubspotIntegrationDocument,
  SyncIntercomIntegrationDocument,
  SyncSlackIntegrationDocument,
} from '@cycle-app/graphql-codegen';
import { Spinner, ToasterAction } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';

import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useIntegration, useSafeMutation } from 'src/hooks';
import { navigate } from 'src/hooks/useNavigate';
import { setIntegrationSync, useGetIntegrationSync } from 'src/reactives/integrationSync.reactive';
import { Integration } from 'src/types/integrations.types';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { integrationNameTitles } from 'src/utils/integrations.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { usePeopleRefetch } from '../api/queries/customers/usePeopleRefetch';
import { PageId } from '../usePageId';

const SyncTitle = ({ type }: { type: Integration }) => {
  const title = integrationNameTitles[type];
  const integrations = useGetIntegrationSync();
  return integrations[type]?.isSyncing
    ? <>{`${title} customer sync in progress`}</>
    : <>{`${title} customer sync done`}</>;
};

const SyncIcon = ({ type }: { type: Integration }) => {
  const integrations = useGetIntegrationSync();
  return integrations[type]?.isSyncing
    ? <Spinner style={{ opacity: 0.5 }} />
    : <CheckIcon />;
};

const SyncActions = ({ type }: { type: Integration }) => {
  const integrations = useGetIntegrationSync();
  const { learnUrl } = integrationsDataMap[type];
  const isSyncing = integrations[type]?.isSyncing;
  if (isSyncing && !learnUrl) return null;
  return (
    <>
      {!isSyncing && (
        <ToasterAction onClick={() => navigate(PageId.Customers)}>
          View customers
        </ToasterAction>
      )}

      {learnUrl && (
        <ToasterAction onClick={() => window.open(learnUrl, '_blank')}>
          Learn more
        </ToasterAction>
      )}
    </>
  );
};

export const useIntegrationSync = () => {
  const slack = useIntegration({ type: IntegrationType.Slack });
  const intercom = useIntegration({ type: IntegrationType.Intercom });
  const hubspot = useIntegration({ type: IntegrationType.Hubspot });
  const [syncSlackMutation, syncSlackMutationState] = useSafeMutation(SyncSlackIntegrationDocument);
  const [syncIntercomMutation, syncIntercomMutationState] = useSafeMutation(SyncIntercomIntegrationDocument);
  const [syncHubspotMutation, syncHubspotMutationState] = useSafeMutation(SyncHubspotIntegrationDocument);
  const people = usePeopleRefetch();

  const customerSync = async (integrationType: Integration, sync = true) => {
    const title = integrationNameTitles[integrationType];
    let result: string | undefined;

    if (integrationType === IntegrationType.Slack) {
      if (!slack.integration?.provider) {
        addErrorToaster({ message: `${title} is not installed` });
        return;
      }
      setIntegrationSync({ [integrationType]: { isSyncing: sync } });
      const { data } = await syncSlackMutation({
        variables: {
          integrationId: slack.integration.id,
          customerSync: sync,
        },
      });
      result = data?.updateSlackIntegration?.id;
    } else if (integrationType === IntegrationType.Intercom) {
      if (!intercom.integration?.provider) {
        addErrorToaster({ message: `${title} is not installed` });
        return;
      }
      setIntegrationSync({ [integrationType]: { isSyncing: sync } });
      const { data } = await syncIntercomMutation({
        variables: {
          integrationId: intercom.integration.id,
          customerSync: sync,
        },
      });
      result = data?.updateIntercomIntegration?.id;
    } else if (integrationType === IntegrationType.Hubspot) {
      if (!hubspot.integration?.provider) {
        addErrorToaster({ message: `${title} is not installed` });
        return;
      }
      setIntegrationSync({ [integrationType]: { isSyncing: sync } });
      const { data } = await syncHubspotMutation({
        variables: {
          integrationId: hubspot.integration.id,
          customerSync: sync,
        },
      });
      result = data?.updateHubspotIntegration?.id;
    }

    if (!result) return;

    if (sync) {
      addToaster({
        id: `${integrationType}-sync`,
        title: <SyncTitle type={integrationType} />,
        icon: <SyncIcon type={integrationType} />,
        duration: 'infinite',
        isCompact: false,
        actions: <SyncActions type={integrationType} />,
      });
    } else {
      addToaster({
        id: `${integrationType}-unsync`,
        title: `${title} customers were successfully unsync`,
      });
      // unsync will remove some customers.
      // UX: this will avoid visual flash of the lists when the user navigates to the customers.
      // no need to wait for the refetch
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      people.refetch();
    }
  };

  const customerUnsync = (integrationType: Integration) => customerSync(integrationType, false);

  return {
    customerSync,
    customerUnsync,
    isLoading:
      syncSlackMutationState.loading ||
      syncIntercomMutationState.loading ||
      syncHubspotMutationState.loading,
  };
};
