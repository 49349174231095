import { Role } from '@cycle-app/graphql-codegen';
import { Button, SelectPanel, Input } from '@cycle-app/ui';
import { CloseIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { emailRegex, capitalize, ERROR_CODE } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { Select, Caret } from 'src/components/GithubIssueCreationForm/RepositoriesField/RepositoriesField.styles';
import useProductMembersMutations from 'src/hooks/api/mutations/useProductMembersMutations';
import { useIsStandardPlan, useProduct } from 'src/hooks/api/useProduct';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setAddMember, useGetAddMember } from 'src/reactives/addMember.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { Layer } from 'src/types/layers.types';
import { addToaster } from 'src/utils/toasters.utils';

interface AddUserFormData {
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
}

const mutationErrorsMap: ErrorMap<AddUserFormData>[] = [
  {
    fieldName: 'email',
    code: ERROR_CODE.USER_ALREADY_INVITED,
    renderMessage: () => 'This user was already invited',
  },
];

export const SettingsUserAddUserModal = () => {
  const {
    visible: isModalVisible, initialEmail,
  } = useGetAddMember();
  return (
    <AnimatePresence>
      {isModalVisible && (
        <SettingsUserAddUserModalContent initialEmail={initialEmail} />
      )}
    </AnimatePresence>
  );
};

const SettingsUserAddUserModalContent = ({ initialEmail }: { readonly initialEmail: string | null }) => {
  const { product } = useProduct();
  const {
    addUser,
    isAddUserLoading,
  } = useProductMembersMutations();
  const [isRolesDropdownOpen, { toggleCallback }] = useOptimizedBooleanState(false);
  const {
    canInviteCollaborator, canInviteMaker,
  } = useGetPermission();
  const isStandardPlan = useIsStandardPlan();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    displayFieldsErrors,
  } = useEnhancedForm<AddUserFormData>({
    defaultValues: {
      email: initialEmail || '',
      role: Role.Collaborator,
    },
  });

  const hideModal = () => setAddMember({
    visible: false,
    initialEmail: null,
  });

  if (!product) return null;

  const { role } = watch();

  return (
    <PortalModalStyled hide={hideModal}>
      <Header>
        <Title>
          {`Add a member to ${product.name}`}
        </Title>
        <CloseButtonStyled size="L" onClick={hideModal}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <form
        className="mt-4 flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          autoFocus
          type="email"
          label="Email address"
          placeholder="example@cycle.app"
          error={errors.email?.message}
          {...register('email', {
            required: 'required',
            pattern: {
              value: emailRegex,
              message: 'Email format is incorrect',
            },
          })}
        />
        <DropdownLayer
          layer={Layer.DropdownModal}
          visible={isRolesDropdownOpen}
          hide={toggleCallback}
          placement="bottom-start"
          content={(
            <SelectPanel
              selectedValue={role}
              options={[
                ...(canInviteMaker ? [{
                  label: 'Maker',
                  value: Role.Maker,
                }] : []),
                ...(canInviteCollaborator ? [{
                  label: 'Collaborator',
                  value: Role.Collaborator,
                }] : []),
              ]}
              onOptionChange={(newOption) => {
                setValue('role', newOption.value as Role);
                toggleCallback();
              }}
              hideSearch
            />
          )}
        >
          <label>
            <div className="mb-2 font-medium">
              Role
            </div>
            <Select
              onClick={toggleCallback}
              type="button"
              forceFocus={isRolesDropdownOpen}
            >
              {capitalize(role.toLowerCase())}
              <Caret />
            </Select>
          </label>
        </DropdownLayer>
        {isStandardPlan && (
          <p className="flex items-center gap-2 text-secondary">
            <InfoIconOutline />
            The billing will automatically update if you add a maker.
          </p>
        )}
        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={isAddUserLoading}
          >
            Add
          </Button>
        </Actions>
      </form>
    </PortalModalStyled>
  );

  async function onSubmit(values: AddUserFormData) {
    if (!isAddUserLoading) {
      const result = await addUser(values.email, values.role);
      if (result?.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap);
        return;
      }
      addToaster({
        message: `Invite was sent to ${values.email}`,
      });
      hideModal();
    }
  }
};
