import { atom } from 'jotai';

import { atomWithDebounce } from 'src/utils/jotai/atomWithDebounce';

export const tabAtom = atom<'people' | 'companies'>('companies');

export const modalAtom = atom<'customer' | 'company' | null>(null);

export const [inputAtom, searchAtom] = atomWithDebounce('');
searchAtom.onMount = (set) => () => set('');
