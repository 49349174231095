import { SourceType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import {
  EmailIcon,
  GongIcon,
  MicrosoftTeamsIcon,
  SalesforceIcon,
  ZendeskIcon,
  ZoomIcon,
  CycleStandardIcon,
  LoomIcon,
  ModjoIcon,
} from '@cycle-app/ui/icons';
import { FC } from 'react';

const sourceTypeIcons: Record<string, FC<{ size?: number }>> = ({
  [SourceType.Cycle]: ({ size }) => <CycleStandardIcon size={size} variant="primary" />,
  [SourceType.Gong]: GongIcon,
  [SourceType.GoogleMeet]:({ size }) => (
    <Icon
      name="brand/google-meet"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Hubspot]: ({ size }) => (
    <Icon
      name="brand/hubspot"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Intercom]: ({ size }) => (
    <Icon
      name="brand/intercom"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  'LOOM': LoomIcon,
  [SourceType.Mail]: EmailIcon,
  [SourceType.MicrosoftTeams]: MicrosoftTeamsIcon,
  'MODJO': ModjoIcon,
  'NOTION': ({ size }) => (
    <Icon
      name="brand/notion"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Salesforce]: SalesforceIcon,
  [SourceType.Slack]: ({ size }) => (
    <Icon
      name="brand/slack"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Web]: ({ size }) => (
    <Icon
      name="brand/chrome"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Zapier]: ({ size }) => (
    <Icon
      name="brand/zapier"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Zendesk]: ZendeskIcon,
  [SourceType.Zoom]: ZoomIcon,
});

const sourceCategoryIcons: Record<string, FC<{ size?: number }>> = sourceTypeIcons;

export const SourceCategoryIcon = ({
  value, size,
}: {
  value: string;
  size?: number;
}) => {
  const IconComponent = sourceCategoryIcons[value];
  return IconComponent ? <IconComponent size={size} /> : null;
};
