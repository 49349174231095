import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookState: useSections,
  getValue: getSections,
} = make<{ [K in string]: boolean }>({
  defaultState: {},
  localKey: LocalKey.Sections,
  crossTabSync: true,
});

export const {
  hookValue: useGetNewSectionForm,
  setValue: setNewSectionForm,
} = make({
  defaultState: {
    isOpen: false,
  },
});

export const openNewSectionForm = () => setNewSectionForm({ isOpen: true });
export const closeNewSectionForm = () => setNewSectionForm({ isOpen: false });

export const {
  hookValue: useGetEmptySections,
  getValue: getEmptySections,
  setValue: setEmptySections,
} = make({
  localKey: LocalKey.EmptySections,
  crossTabSync: true,
  defaultState: {
    isOpen: true,
  },
});

export const toggleEmptySections = () => {
  setEmptySections({ isOpen: !getEmptySections().isOpen });
};
