import { DoctypeType, MateFragment } from '@cycle-app/graphql-codegen';
import { PropsWithChildren } from 'react';

import DropdownLayer, { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { Layer } from 'src/types/layers.types';
import { assigneeLabel } from 'src/utils/users.util';

import { AssigneeTooltip } from '../AssigneeTooltip';

type Props = Omit<DropdownLayerProps, 'content'> & {
  assignee?: MateFragment | null;
  isDisabled?: boolean;
  onOpenChange?: VoidFunction;
  docTypeType?: DoctypeType;
};

export const AssigneePopover = ({
  assignee, docTypeType, isDisabled, onOpenChange, children, ...props
}: PropsWithChildren<Props>) => {
  const isFeedback = docTypeType !== DoctypeType.Custom;
  return (
    <DropdownLayer
      lazy
      placement="top"
      animation={false}
      layer={Layer.DropdownModalZ3}
      delay={[600, 0]}
      withWrapper={false}
      {...props}
      content={assignee && (
        <AssigneeTooltip
          assignee={assignee}
          isReadOnly={isDisabled}
          onUpdate={onOpenChange}
          docTypeType={docTypeType}
          tooltip={docTypeType === DoctypeType.Custom
            ? undefined
            : `${assigneeLabel({
              isFeedback,
              plural: true,
              uppercase: true,
            })} are responsible for closing the feedback loop with customers`}
        />
      )}
    >
      {children}
    </DropdownLayer>
  );
};