import { QueryFunctionOptions, useQuery } from '@apollo/client';
import { ProductCustomersCountDocument, ProductCustomersCountQuery, ProductCustomersCountQueryVariables } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { defaultCustomersPagination } from 'src/utils/pagination.util';

type Options = QueryFunctionOptions<ProductCustomersCountQuery, ProductCustomersCountQueryVariables>;

export const useCustomersCount = (options?: Options) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, loading, refetch,
  } = useQuery(ProductCustomersCountDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: options?.skip,
    variables: {
      productId: productId,
      ...defaultCustomersPagination,
    },
  });
  return {
    count: data?.node?.__typename === 'Product' ? data?.node.customers.count : null,
    isLoading: loading,
    refetch,
  };
};
