import { ImageSize, FileData } from '@cycle-app/utilities/src/types/editor.types';
import Image from '@tiptap/extension-image';

import { IMAGE_EXTENSION_NAME } from '../constants';
import { getAttributeParser } from '../utils/extension.utils';

export { ImageSize };

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    img: {
      setImage: (options: GetImageNodeParams) => ReturnType;
    };
  }
}

export type GetImageNodeParams = {
  size?: ImageSize;
  src: FileData['src'];
  title?: FileData['title'];
  dataId?: string;
  width?: number;
  height?: number;
};

export const getImageExtension = () => Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      size: {
        default: ImageSize.LARGE,
        ...getAttributeParser('size'),
      },
      dataId: {
        default: '',
        ...getAttributeParser('dataId'),
      },
      width: {
        default: 600
      },
      height: {
        default: 400
      }
    };
  },
  addCommands() {
    return {
      setImage: (options: Omit<FileData, 'dataId'>) => ({ commands }) => commands.insertContent([getEditorImageNode({
        size: ImageSize.SMALL,
        src: options.src,
        title: options.title,
        width: options.width,
        height: options.height,
      })]),
    };
  },
});

export const getEditorImageNode = (data: GetImageNodeParams) => ({
  type: IMAGE_EXTENSION_NAME,
  attrs: {
    ...data,
    size: data.size || ImageSize.SMALL,
    width: data.width,
    heigth: data.height,
  },
});
