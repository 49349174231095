import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { SelectPanel, SelectOption, ActionButtonProps, CompanyLogo } from '@cycle-app/ui';
import { useMemo, FC, ReactNode, useState } from 'react';

import { useFetchCompanyLogo } from 'src/hooks';
import { useCompanyCreate } from 'src/hooks/api/mutations/customers/useCompanyCreate';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { useCompanies } from 'src/hooks/api/queries/customers/useCompanies';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { getCompanyOption } from 'src/utils/companies.util';

import { Caret, StyledActionButton, StyledFloatingPenButtonContainer } from './CustomerCompanyAction.styles';
import DropdownLayer, { DropdownLayerProps } from '../DropdownLayer/DropdownLayer';
import { FloatingPenButton } from '../FloatingPenButton/FloatingPenButton';

export type CompanySelectOption = SelectOption<{ logo?: string | null }>;

type Props = Omit<DropdownLayerProps, 'content'> & {
  customer?: CustomerFragment;
  className?: string;
  onOptionChange?: (selectedOption: CompanySelectOption) => void;
  onCreateCompany?: (name: string) => void;
  onClearValue?: VoidFunction;
  size?: ActionButtonProps['size'];
  tooltip?: ReactNode;
  showCaret?: boolean;
  isReadOnly?: boolean;
  hasBg?: boolean;
  layer?: Layer;
  isCompact?: boolean;
  shouldCreate?: boolean;
  onCompanyClick?: (companyId: string) => void;
  productId?: string;
};

export const CustomerCompanyAction: FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  customer,
  size = 'L',
  onOptionChange,
  onClearValue,
  tooltip,
  showCaret,
  isReadOnly = false,
  layer = Layer.DropdownModalZ3,
  isCompact = true,
  onCompanyClick,
  shouldCreate = true,
  hasBg = false,
  productId,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const { companies } = useCompanies({ defaultSearch: searchText });

  const {
    createCompany, isLoading,
  } = useCompanyCreate(productId);

  const logoQuery = useFetchCompanyLogo();

  const companiesOptions: CompanySelectOption[] = useMemo(() => companies.map(getCompanyOption), [companies]);
  const { update } = useCustomerUpdate(customer, { displayErrorMessages: true });
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    setTrueCallback: showDropdown,
  }] = useOptimizedBooleanState(false);

  const hasCompany = !!customer?.company?.id;

  if (isReadOnly) {
    return renderContent();
  }

  return (
    <DropdownLayer
      layer={layer}
      visible={isDropdownVisible}
      hide={hideDropdown}
      placement="bottom-start"
      content={(
        companiesOptions && (
          <SelectPanel
            showCreateOption
            isLoading={isLoading}
            filterOptionsOnInputChange={false}
            options={companiesOptions}
            onSearchChange={setSearchText}
            debounceSearch
            onOptionChange={handleOnOptionChange}
            onClearValue={handleOnClearValue}
            onCreateOption={async (label) => {
              const logoResult = await logoQuery.fetch({ name: label });

              if (!shouldCreate) {
                await handleOnOptionChange({
                  label,
                  value: '',
                  data: {
                    logo: logoResult?.companyLogo,
                  },
                  icon: (
                    <CompanyLogo
                      size="S"
                      company={{
                        name: label,
                        logo: logoResult?.companyLogo,
                      }}
                    />
                  ),
                });
                return;
              }

              const result = await createCompany({
                name: label,
                logo: logoResult?.companyLogo,
              });

              if (result?.data?.createCompany) {
                await handleOnOptionChange(getCompanyOption(result.data.createCompany));
              }

              hideDropdown();
            }}
          />
        )
      )}
      {...props}
    >
      {renderContent()}
    </DropdownLayer>
  );

  function renderContent() {
    return isCompact ? (
      <StyledActionButton
        $withBg={hasBg}
        tooltip={tooltip}
        className={className}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          showDropdown();
        }}
        size={size}
        tooltipPlacement="top"
        disabled={isReadOnly}
      >
        {children}
        {showCaret && <Caret direction={isDropdownVisible ? 'top' : 'bottom'} />}
      </StyledActionButton>
    ) : (
      <StyledFloatingPenButtonContainer>
        <StyledActionButton
          className={className}
          tooltip={!isReadOnly && !hasCompany ? 'Edit company' : tooltip}
          size={size}
          tooltipPlacement="top"
          onClick={e => {
            e.stopPropagation();
            if (hasCompany) {
              onCompanyClick?.(customer?.company?.id || '');
            } else if (!isReadOnly) {
              showDropdown();
            }
          }}
        >
          {children}
        </StyledActionButton>
        {!isReadOnly && hasCompany && (
          <FloatingPenButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              showDropdown();
            }}
            tooltip="Edit company"
            tooltipPlacement="top"
          />
        )}
      </StyledFloatingPenButtonContainer>
    );
  }

  async function handleOnOptionChange(selectedOption: CompanySelectOption) {
    onOptionChange?.(selectedOption);
    if (customer) {
      await update({
        customerId: customer.id,
        name: customer.name ?? '',
        companyId: selectedOption.value,
      });
    }
    hideDropdown();
  }

  async function handleOnClearValue() {
    onClearValue?.();
    if (customer) {
      await update({
        customerId: customer.id,
        name: customer.name ?? '',
        companyId: null,
      });
    }
    hideDropdown();
  }
};
