import {
  Icon, TextHighlighter, Warning, SelectPanel, SelectPanelProps, SelectOption, CheckboxInput, Button,
} from '@cycle-app/ui';
import { WheelIcon } from '@cycle-app/ui/icons';
import { useCallback } from 'react';

import { InputStyled } from './ProductAreasManager.styles';
import { PageId } from '../../constants/routing.constant';
import { useProductAreasCategories } from '../../hooks/product/useProductAreasCategories';
import { useNavigateToSettings } from '../../hooks/useNavigateToSettings';

type Props = {
  compatibleIds: string[] | undefined;
  docTypeName: string | undefined;
  hideIncompatibleValues: boolean;
  isMulti: boolean;
  isRequired: boolean;
  onClearValue?: (data: { isIncompatible: boolean }) => void;
  onSelect: (data: { productAreaId: string; isMulti: boolean; isIncompatible: boolean }) => void;
  onUnselect?: (data: { productAreaId: string; isIncompatible: boolean }) => void;
  selectedValues: string[];
  showWarning?: boolean;
};

export const ProductAreasManager = ({
  compatibleIds,
  docTypeName,
  hideIncompatibleValues,
  isMulti,
  isRequired,
  onClearValue,
  onSelect,
  onUnselect,
  selectedValues,
  showWarning = true,
}: Props) => {
  const {
    categories, areas,
  } = useProductAreasCategories();
  const values = categories.flatMap(category => {
    const {
      productAreas, ...rest
    } = category;
    return [
      rest,
      ...productAreas,
    ];
  });

  const incompatibleIds = compatibleIds
    ? areas
      .filter(a => !compatibleIds.some(c => c === a.id))
      .map(a => a.id)
    : [];

  const willMutilisectOptionIncompatible = (areaId: string, isSelected: boolean) => {
    // For multiselect, anticipate a toggle and compare with incompatible set.
    const futureValues = !isSelected
      ? [areaId, ...selectedValues]
      : selectedValues.filter(value => value !== areaId);
    return !!incompatibleIds?.length && !!futureValues.length && futureValues.every(value => incompatibleIds.some(id => id === value));
  };

  const isCompatible = (areaId: string) => {
    return !compatibleIds || compatibleIds.includes(areaId);
  };

  const isIncompatible = (areaId: string) => {
    const isSelected = selectedValues.includes(areaId);
    if (!isMulti && incompatibleIds?.includes(areaId)) {
      return true;
    }
    if (
      // Warn if will violates required.
      isMulti && isRequired && isSelected && selectedValues.length === 1 ||
      // Warn if will violates incompatibles set of values.
      isMulti && willMutilisectOptionIncompatible(areaId, isSelected)
    ) {
      return true;
    }
    return false;
  };

  const doctypeDisplay = docTypeName ? docTypeName.toLowerCase() : 'doc';

  // "No category" id is null.
  const options: SelectOption[] = values
    .filter(area => area.__typename === 'ProductAreaCategory' || !hideIncompatibleValues || !!area.id && isCompatible(area.id))
    .map(area => {
      const areaId = area.id;
      if (!areaId || area.__typename === 'ProductAreaCategory') {
        return {
          value: areaId || '',
          label: area.value,
          disabled: true,
        };
      }
      const isSelected = selectedValues.includes(areaId);
      const isIncomp = isIncompatible(areaId);
      const end = isIncomp ? (
        <Warning
          tooltip={`The ${doctypeDisplay} will leave the view if you ${isMulti ? 'change' : 'choose'} this value`}
        />
      ) : null;
      return {
        value: areaId,
        label: area.value,
        selected: isSelected,
        disabled: !showWarning && isIncomp,
        end: showWarning && end,
        ...isMulti && {
          renderLabel: (filterText: string) => (
            <div className="flex items-center gap-2">
              <CheckboxInput readOnly id={areaId} checked={isSelected} />
              <TextHighlighter searchWords={[filterText]} textToHighlight={area.value} />
            </div>
          ),
        },
      };
    });

  const { navigate } = useNavigateToSettings({
    useLimitationModal: true,
  });

  const renderSearch: NonNullable<SelectPanelProps['renderSearch']> = useCallback((searchProps, inputRef) => (
    <div className="flex gap-2">
      <InputStyled
        ref={inputRef}
        type="text"
        iconBefore={<Icon name="search" />}
        placeholder={searchProps.searchPlaceholder}
        onChange={searchProps.onChange}
        defaultValue={searchProps.filterText}
        autoFocus={searchProps.autoFocusSearch}
        disabled={searchProps.isLoading}
      />
      <div className="flex-none">
        <Button
          onClick={() => navigate(PageId.SettingsProductHierarchy)}
          iconStart={<WheelIcon />}
          variant="outlined-alt"
          className="size-[30px] !p-0 !text-secondary"
        />
      </div>
    </div>
  ), [navigate]);

  return (
    <>
      {!isMulti && (
        <SelectPanel
          options={options}
          onClearValue={() => onClearValue?.({ isIncompatible: isRequired })}
          onOptionChange={option => {
            onSelect({
              productAreaId: option.value,
              isMulti,
              isIncompatible: isIncompatible(option.value),
            });
          }}
          selectedValue={selectedValues[0]}
          renderSearch={renderSearch}
        />
      )}
      {isMulti && (
        <SelectPanel
          isMulti
          options={options}
          hideChecks
          onUnselectOption={option => {
            onUnselect?.({
              productAreaId: option.value,
              isIncompatible: isIncompatible(option.value),
            });
          }}
          onSelectOption={option => {
            onSelect({
              productAreaId: option.value,
              isMulti,
              isIncompatible: isIncompatible(option.value),
            });
          }}
          renderSearch={renderSearch}
        />
      )}
    </>
  );
};