import { make } from 'src/utils/reactives.util';

interface SelectionState {
  selected: string[];
  lock: boolean;
}

export const {
  hookState: useSelection,
  hookValue: useGetSelection,
  hookWithSelector: useSelectSelection,
  getValue: getSelection,
  setValue: setSelection,
} = make<SelectionState>({
  defaultState: {
    selected: [],
    lock: false,
  },
});

export const clearSelection = () =>  setSelection({
  selected: [],
  lock: false,
});

export const {
  hookValue: useGetBulkLoading,
  setValue: setBulkLoading,
} = make({
  defaultState: {
    isLoading: false,
  },
});

export const useDocsSelectedLength = () => useSelectSelection(state => state.selected.length);
export const useIsDocSelected = (docId: string) => useSelectSelection(state => state.selected.includes(docId));
export const useIsSelectionLocked = () => useSelectSelection(state => state.lock);
