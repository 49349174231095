import { ImportFileIcon } from 'src/components/Dropzone/ImportFileIcon';
import { getFileSizeWithUnit, getFileExtension } from 'src/utils/files.util';

export type QuoteFileProps = {
  src: string;
  name: string | null;
  mimeType: string | null;
  size: number | null;
};

export const QuoteFile = ({
  src, name, mimeType, size,
}: QuoteFileProps) => {
  const extension = getFileExtension(src);
  const infos = [extension];
  if (size !== null) infos.push(getFileSizeWithUnit(size));
  return (
    <a
      href={src}
      target="_blank"
      rel="noreferrer"
      className="flex h-12 items-center gap-3 rounded-lg bg-grey-100 px-2 dark:bg-grey-800"
    >
      <div className="flex size-8 items-center justify-center rounded-md bg-grey-200 text-secondary dark:bg-grey-750">
        <ImportFileIcon
          mimeType={mimeType}
          extension={extension}
          size="M"
        />
      </div>
      <div className="overflow-hidden">
        <div className="truncate text-body font-medium leading-snug">
          {name}
        </div>
        <div className="text-caption uppercase leading-snug text-secondary">
          {infos.join(' · ')}
        </div>
      </div>
    </a>
  );
};
