import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useDebouncedCallback } from 'use-debounce';

import { EditProperty, OnValueSelectedParams } from 'src/components/EditProperty';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useGetDoctypeAttributeDefinitions } from 'src/hooks/api/cache/cacheDoctype';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { isAttributeScalar } from 'src/utils/attributes.util';

import { useDocProductAreaUpdate } from '../../hooks/doc/useDocProductAreaUpdate';

export const DocOptionsEditProperty = ({
  doc,
  hide,
  onSelectProperty,
  keepDropdownOnSelect,
  onParentUpdated,
  commonDoctypeParents,
  goBack,
}: {
  doc: DocBaseFragment;
  hide?: VoidFunction;
  goBack: VoidFunction;
  onSelectProperty?: (isNotCompatible: boolean) => void;
  onParentUpdated?: (parentDocId: string | undefined) => void;
  commonDoctypeParents?: string[];
  keepDropdownOnSelect?: boolean;
}) => {
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);

  const getDoctypeAttributes = useGetDoctypeAttributeDefinitions();
  const attributes = getDoctypeAttributes(doc.doctype.id);
  const attributeDefinitions = nodeToArray(attributes);
  const productAreaUpdate = useDocProductAreaUpdate();

  const onPropertyUpdated = async ({
    attributeDefinition,
    propertyValue,
    isValueRemoved,
    notCompatible,
  }: OnValueSelectedParams) => {
    onSelectProperty?.(!!notCompatible);

    if (isValueRemoved && !propertyValue) {
      // A singleSelect attribute is set to none
      const docAttribute = nodeToArray(doc.attributes).find(a => a.definition.id === attributeDefinition.id);
      if (docAttribute?.__typename !== 'DocAttributeSingleSelect' || !docAttribute.selectValue?.id) return;

      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: docAttribute.selectValue?.id,
        notCompatible,
      });
    }

    if (!propertyValue) return;

    if (isAttributeScalar(attributeDefinition)) {
      await changeDocAttributeValueDebounced({
        doc,
        attributeDefinition,
        value: propertyValue,
      });
    } else if (isValueRemoved) {
      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: propertyValue,
        notCompatible,
      });

      if (attributeDefinition.__typename !== 'AttributeMultiSelectDefinition') {
        if (!keepDropdownOnSelect) hide?.();
      }
    } else {
      await changeDocAttributeValue({
        doc,
        attributeDefinition,
        value: propertyValue,
        notCompatible,
      });

      if (attributeDefinition.__typename !== 'AttributeMultiSelectDefinition') {
        if (!keepDropdownOnSelect) hide?.();
      }
    }
  };

  return (
    <EditProperty
      possibleAttributes={attributeDefinitions}
      docId={doc.id}
      onValueUpdated={onPropertyUpdated}
      onAddNewAttributeToDoc={hide}
      onParentUpdated={onParentUpdated}
      commonDoctypeParents={commonDoctypeParents}
      goBack={goBack}
      productAreasSelected={nodeToArray(doc.productAreas).map(area => area.id)}
      onProductAreaRemove={removeData => productAreaUpdate.remove({
        docId: doc.id,
        productAreaId: removeData.productAreaId,
        isIncompatible: removeData.isIncompatible,
      })}
      onProductAreaUpdate={updateData => productAreaUpdate.update({
        docId: doc.id,
        productAreaId: updateData.productAreaId,
        isMulti: updateData.isMulti,
        isIncompatible: updateData.isIncompatible,
      })}
    />
  );
};
