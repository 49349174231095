import { ContentSection, HeroSection, Typography } from '@cycle-app/graphql-codegen';
import { typo, ShyScrollbar } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

interface RootProps {
  $heroSection: HeroSection;
  $contentSection: ContentSection;
  $typography: Typography;
}

export const Root = styled(ShyScrollbar)<RootProps>`
  margin: 24px 47px 77px;
  flex-grow: 1;
  background-color: var(--backgroundColor);
  color: var(--bodyColor);
  border-radius: 12px;
  padding-top: 50px;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0px 1px 5px 1px #0000000D;

  ${p => {
    switch (p.$typography) {
      case Typography.Montserrat: return css`font-family: "Montserrat", sans-serif;`;
      case Typography.SourceSans_3: return css`font-family: "Source Sans 3", sans-serif;`;
      case Typography.Roboto: return css`font-family: "Roboto", sans-serif;`;
      case Typography.WorkSans: return css`font-family: "Work Sans", sans-serif;`;
      case Typography.PlayfairDisplay: return css`font-family: "Playfair Display", sans-serif;`;
      case Typography.Mulish: return css`font-family: "Mulish", sans-serif;`;
      case Typography.Merriweather: return css`font-family: "Merriweather", sans-serif;`;
      case Typography.Manrope: return css`font-family: "Manrope", sans-serif;`;
      case Typography.Lora: return css`font-family: "Lora", sans-serif;`;
      case Typography.LibreBaskerville: return css`font-family: "Libre Baskerville", sans-serif;`;
      case Typography.JetbrainsMono: return css`font-family: "JetBrains Mono", sans-serif;`;
      case Typography.Hind: return css`font-family: "Hind", sans-serif;`;
      case Typography.DmSans: return css`font-family: "DM Sans", sans-serif;`;
      case Typography.Asap: return css`font-family: "Asap", sans-serif;`;
      case Typography.Sora: return css`font-family: "Sora", sans-serif;`;
      default:
      case Typography.Inter: return css`font-family: "Inter", sans-serif;`;
    }
  }}

  h1,
  h2,
  h3,
  h4 {
    color: var(--headersColor);
  }

  h1 {
    ${p => {
    switch (p.$heroSection) {
      case HeroSection.S: return css`font-size: 40px; line-height: 44px;`;
      default:
      case HeroSection.M: return css`font-size: 48px; line-height: 57.6px;`;
      case HeroSection.L: return css`font-size: 62px; line-height: 68px;`;
      case HeroSection.Xl: return css`font-size: 80px; line-height: 91px;`;
    }
  }}
  }
`;

export const Title = styled.h1`
  ${typo.headerLarge}
  font-size: 62px;
  font-weight: 700;
  text-align: center;
  margin-top: 48px;
  line-height: 82px;
  word-break: break-word;
`;

interface SubtitleProps {
  $heroSection: HeroSection;
}

export const Subtitle = styled.div<SubtitleProps>`
  ${typo.headerLarge}
  font-weight: 500;
  text-align: center;
  margin-top: 8px;

  ${p => {
    switch (p.$heroSection) {
      case HeroSection.S: return css`font-size: 16px;`;
      default:
      case HeroSection.M: return css`font-size: 18px;`;
      case HeroSection.L: return css`font-size: 20px;`;
      case HeroSection.Xl: return css`font-size: 24px;`;
    }
  }}
`;

export const SocialContainer = styled.div<{ $contentSection: ContentSection }>`
  margin-top: 16px;
  text-align: center;
  color: var(--linkColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  ${p => {
    switch (p.$contentSection) {
      default:
      case ContentSection.Classic:
      case ContentSection.Discreet:
      case ContentSection.Unique: return css`font-size: 14px;`;
      case ContentSection.Uniform:
      case ContentSection.Sober: return css`font-size: 16px;`;
      case ContentSection.ShowOff: return css`font-size: 18px;`;
    }
  }}
`;

export const SocialContainerSeparator = styled.div`
  background-color: var(--dividerColor);
  height: 4px;
  width: 4px;
  border-radius: 50%;
  flex-shrink: 0;
`;
