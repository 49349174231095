import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';
import '@fontsource/roboto-mono/latin-400.css';

import { AppRouter } from 'src/app/AppRouter';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { AppProviders } from 'src/providers';
import { isDevEnv } from 'src/utils/env.util';

import { Analytics } from './Analytics';
import { BodyClassList } from './BodyClassList';

export const App = () => (
  <>
    <BodyClassList />
    <AppProviders>
      <ErrorBoundary>
        {!isDevEnv() && <Analytics />}
        <AppRouter />
      </ErrorBoundary>
    </AppProviders>
  </>
);
