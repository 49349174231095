import { DuplicateIcon, AiIcon } from '@cycle-app/ui/icons';

import { usePrevious } from 'src/hooks/usePrevious';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { AnswerParameters } from './AnswerParameters';
import { AnswerText } from './AnswerText';
import { Answer, LoadingAnswer, CopyButtonContainer, CopyButton } from './Ask.styles';
import { AskItemAnswer } from './Ask.types';
import { DocsButton } from './DocsButton';

export const AnswerItem = ({
  answer, openDocs, loadingText,
}: {
  answer?: AskItemAnswer;
  openDocs: VoidFunction;
  loadingText: string;
}) => {
  const previousStatus = usePrevious(answer?.status);

  if (!answer || answer.status === 'pending') return null;

  if (answer.status === 'loading') {
    return (
      <LoadingAnswer>
        <AiIcon isAnimated />
        {loadingText}
      </LoadingAnswer>
    );
  }

  const docCount = answer.content.docs?.length ?? 0;
  const { parameters } = answer.content;
  return (
    <Answer>
      {(
        // Check if at least one parameters is present to avoid an useless sentence.
        parameters?.feature || !!parameters?.companies.length || parameters?.fromDate || parameters?.toDate
      ) && (
        <AnswerParameters parameters={parameters} />
      )}

      <AnswerText
        text={answer.content.answer}
        simulateTyping={previousStatus === 'loading'}
      />

      {docCount > 0 && (
        <DocsButton
          docCount={docCount}
          onClick={openDocs}
        />
      )}
      <CopyButtonContainer>
        <CopyButton
          tooltip="Copy"
          tooltipPlacement="top"
          onClick={() => {
            copyToClipboard({
              text: answer.content.answer,
              notification: 'Answer copied to clipboard',
            });
          }}
        >
          <DuplicateIcon size={14} />
        </CopyButton>
      </CopyButtonContainer>
    </Answer>
  );
};
