import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import { Editor } from '@tiptap/core';
import { FC, useMemo, useState } from 'react';

import { FullDocWithPublicId } from 'src/types/doc.types';

type DocPanelProviderProps = {
  docId: string;
  doc: FullDocWithPublicId | null;
};

type DocPanelContextValue = {
  docId: string;
  doc: FullDocWithPublicId | null;
  threadsCount?: number;
  setThreadsCount: (count: number) => void;
  editor?: Editor;
  setEditor: (editor: Editor) => void;
};

const DocPanelContext = createContext<DocPanelContextValue>({} as DocPanelContextValue);

export const DocPanelProvider: FC<React.PropsWithChildren<DocPanelProviderProps>> = ({
  docId, doc, children,
}) => {
  const [threadsCount, setThreadsCount] = useState<number>();
  const [editor, setEditor] = useState<Editor>();

  const value = useMemo(() => {
    return {
      docId,
      doc,
      threadsCount,
      setThreadsCount,
      editor,
      setEditor,
    };
  }, [doc, docId, editor, threadsCount]);

  return (
    <DocPanelContext.Provider value={value}>
      {children}
    </DocPanelContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useDocPanelContext = <T extends unknown = DocPanelContextValue>(selector?: ContextSelector<DocPanelContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(DocPanelContext);
  if (!isWrappedWithContext) throw new Error('useDocPanelContext must be used within a DocPanelProvider');
  return useContextSelector(DocPanelContext, selector ?? (ctx => ctx as T));
};
