import { UpdateSlackAllowEmptyDescriptionDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';
import { useSlackIntegration } from './useSlackIntegration';

export const useUpdateSlackAllowEmptyDescription = () => {
  const {
    integration, provider,
  } = useSlackIntegration();

  const [mutate] = useSafeMutation(UpdateSlackAllowEmptyDescriptionDocument);

  const updateAllowEmptyDescription = (allowEmptyDescription: boolean) => {
    if (!provider || !integration) return null;
    return mutate({
      variables: {
        integrationId: integration.id,
        allowEmptyDescription,
      },
      optimisticResponse: {
        updateSlackIntegration: {
          id: provider.id,
          allowEmptyDescription,
        },
      },
    });
  };

  return {
    updateAllowEmptyDescription,
  };
};
