import { Icon } from '@cycle-app/ui';
import { MoreHorizCircleIcon, DownIcon } from '@cycle-app/ui/icons';
import { memo } from 'react';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { SectionContextMenu } from 'src/components/SectionContextMenu';
import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { usePageId, useParentPage } from 'src/hooks/usePageId';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { toggleWorkspaceSection, useWorkspaceSection } from 'src/reactives/sidebar.reactive';
import { Shortcut } from 'src/types/shortcuts.types';
import { getUrl } from 'src/utils/routing.utils';

import { BoardSectionNavigationItem } from '../SidebarBoard/SidebarBoardSections/SidebarBoardSection/SidebarBoardSection.styles';

export const SidebarWorkspace = memo(() => {
  const isChangelogOnly = useIsChangelogOnly();
  const isReleasesEnabled = useIsReleasesEnabled();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const isWorkspaceSectionOpen = useWorkspaceSection();

  const isOnboarding = useIsOnboarding();
  const collapsed = useSidebarCollapsed();
  const { navigate } = useNavigateToSettings();
  const pageId = usePageId();
  const parentPage = useParentPage();

  const tryMoreOptions = [
    ...(!isRoadmapsEnabled && !isChangelogOnly ? [{
      label: 'Try features',
      value: 'features',
      icon: <Icon name="feature" />,
    }] : []),
    ...(!isReleasesEnabled ? [{
      label: 'Try releases',
      value: 'release',
      icon: <Icon name="release" />,
    }] : []),
  ];

  const showItems = collapsed || isWorkspaceSectionOpen;

  return (
    <div className="flex flex-col gap-0.5 pb-1">
      {!collapsed && !isChangelogOnly && (
        <BoardSectionNavigationItem
          onClick={toggleWorkspaceSection}
          $isOpen={isWorkspaceSectionOpen}
          label="Workspace"
          className="item--group"
          postName={(
            <DownIcon
              size={14}
              direction={isWorkspaceSectionOpen ? 'top' : 'bottom'}
            />
          )}
          isSidebarColor
        />
      )}

      {(isChangelogOnly || showItems || pageId === PageId.Main) && (
        <CollapsableNavigationItem
          linkId="home"
          asPlaceholder={isOnboarding}
          icon={<Icon name="home" />}
          label="Home"
          linkTo={getUrl(PageId.Main)}
          isSidebarColor
          placeholderScale={[0.5, 0.3]}
          activeAlpha={isOnboarding ? 1 : undefined}
          isActive={pageId === PageId.Main}
          shortcut={shortcuts[Shortcut.GoToHome]}
          isChild
          animate
          collapsed={collapsed}
          className="!flex-none !font-medium"
        />
      )}

      {!isChangelogOnly && (showItems || pageId === PageId.Inbox) && (
        <SectionContextMenu
          sectionParent="inbox"
          target={props => (
            <CollapsableNavigationItem
              linkId="inbox"
              asPlaceholder={isOnboarding}
              icon={<Icon name="feedback" />}
              label="Feedback"
              linkTo={getUrl(PageId.Inbox)}
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              activeAlpha={isOnboarding ? 1 : undefined}
              isActive={pageId === PageId.Inbox}
              shortcut={shortcuts[Shortcut.GoToFeedback]}
              isChild
              animate
              collapsed={collapsed}
              className="!flex-none !font-medium"
              {...props}
            />
          )}
        />
      )}

      {!isChangelogOnly && (showItems || pageId === PageId.Roadmap) && (
        <SectionContextMenu
          sectionParent="roadmap"
          target={props => (
            <CollapsableNavigationItem
              linkId="roadmap"
              icon={<Icon name="feature" />}
              label="Features"
              linkTo={getUrl(PageId.Roadmap)}
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              isActive={pageId === PageId.Roadmap}
              shortcut={shortcuts[Shortcut.GoToRoadmap]}
              isChild
              animate
              collapsed={collapsed}
              className="!flex-none !font-medium"
              {...props}
            />
          )}
        />
      )}

      {isReleasesEnabled && (isChangelogOnly || showItems || parentPage === 'releases') && (
        <CollapsableNavigationItem
          linkId="releases"
          asPlaceholder={isOnboarding}
          icon={<Icon name="release" />}
          label="Releases"
          linkTo={getUrl(PageId.Releases)}
          isSidebarColor
          placeholderScale={[0.5, 0.3]}
          activeAlpha={isOnboarding ? 1 : undefined}
          isActive={parentPage === 'releases'}
          shortcut={shortcuts[Shortcut.GoToReleases]}
          isChild
          animate
          collapsed={collapsed}
          className="!flex-none !font-medium"
        />
      )}

      {!isChangelogOnly && (showItems || pageId === PageId.Customers) && (
        <CollapsableNavigationItem
          linkId="customers"
          asPlaceholder={isOnboarding}
          icon={<Icon name="user" />}
          label="Customers"
          linkTo={getUrl(PageId.Customers)}
          isSidebarColor
          placeholderScale={[0.5, 0.3]}
          isActive={pageId === PageId.Customers}
          isChild
          animate
          collapsed={collapsed}
          className="!flex-none !font-medium"
        />
      )}

      {showItems && !!tryMoreOptions.length && (
        <DropdownSelectLayer
          options={tryMoreOptions}
          hideSearch
          placement={collapsed ? 'right-start' : 'bottom-start'}
          triggerStyle={{ width: '100%' }}
          panelStyles={{ minWidth: '170px' }}
          onChange={(newOption) => {
            if (!getPermission().canReadSettings) {
              setLimitationsModal({ action: 'SETTINGS_READ' });
              return;
            }
            const settingPage = newOption.value === 'features'
              ? PageId.SettingsFeatures
              : PageId.SettingsReleases;
            navigate(settingPage);
          }}
        >
          {(isVisible) => (
            <CollapsableNavigationItem
              isActive={isVisible}
              linkId="more"
              asPlaceholder={isOnboarding}
              icon={(<MoreHorizCircleIcon />)}
              label="More"
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              activeAlpha={isOnboarding ? 1 : undefined}
              isChild
              animate
            />
          )}
        </DropdownSelectLayer>
      )}
    </div>
  );
});
