import { Tooltip, Icon, Input } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { FormEvent, useRef, useState } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { Layer } from 'src/types/layers.types';

import { AddQuoteFromBlockButton } from './DocPanelBody.styles';
import { useQuoteFromBlock } from './useQuoteFromBlock';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
};

export const QuoteFromBlock = ({ containerRef }: Props) => {
  const editor = useDocPanelContext(ctx => ctx.editor);
  const {
    position, active, onClick, speaker,
  } = useQuoteFromBlock(containerRef.current);

  const [editSpeaker, setEditSpeaker] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  if (!editor || !position) return null;

  if (speaker) {
    return (
      <>
        <Tooltip
          content={`Edit ${speaker} name`}
          placement="top"
          withWrapper={false}
          withPortal
        >
          <AddQuoteFromBlockButton
            style={{
              left: position.left,
              top: position.top,
            }}
            onClick={() => setEditSpeaker(true)}
            forceFocus={active}
            ref={ref}
          >
            <Icon name="user-edit" className="size-3.5" />
          </AddQuoteFromBlockButton>
        </Tooltip>

        <DropdownLayer
          withPortal
          visible={editSpeaker}
          hide={() => setEditSpeaker(false)}
          layer={Layer.DropdownModalZ3}
          placement="bottom-start"
          reference={ref}
          content={(
            <form
              className="m-2"
              onSubmit={(e: FormEvent<HTMLFormElement & { speaker: HTMLInputElement }>) => {
                e.preventDefault();
                const name = e.currentTarget.speaker.value.trim();
                const content = editor.getHTML();
                const newContent = content.replaceAll(speaker, name);
                editor.commands.setContent(newContent);
                setEditSpeaker(false);
              }}
            >
              <label>
                <div className="mb-1 text-body font-medium">
                  {speaker} name
                </div>
                <Input
                  name="speaker"
                  autoFocus
                  inputSize="S"
                />
              </label>
            </form>
          )}
        />
      </>
    );
  }


  return (
    <Tooltip
      content="Extract quote"
      placement="top"
      withWrapper={false}
      withPortal
    >
      <AddQuoteFromBlockButton
        style={{
          left: position.left,
          top: position.top,
        }}
        onClick={onClick}
        forceFocus={active}
      >
        <QuoteIcon size={14} />
      </AddQuoteFromBlockButton>
    </Tooltip>
  );
};
