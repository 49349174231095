import { DocAttribute, DoctypeType } from '@cycle-app/graphql-codegen';
import { nodeToArray, getHighlightHash } from '@cycle-app/utilities';
import { useRef } from 'react';

import { AiStateTag } from 'src/components/AiStateTag';
import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocSource } from 'src/components/DocSource';
import { DocStatus } from 'src/components/DocStatus';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { InsightCardOptions } from 'src/components/InsightCardOptions';
import { CustomerContainer, FeatureQuoteCard } from 'src/components/InsightsList/InsightsList.styles';
import { QuoteCustomer } from 'src/components/QuoteCustomer/QuoteCustomer';
import { useProductBase, useOptimizedBooleanState } from 'src/hooks';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { Insight as InsightType } from 'src/hooks/insight/useDocInsights.types';
import { useInsightCardEditQuoteProps } from 'src/hooks/insight/useInsightCardEditQuoteProps';
import { openQuoteModal } from 'src/hooks/modals/useQuoteModal';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { useNavigate } from 'src/hooks/useNavigate';
import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { useGetPermission } from 'src/reactives';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocType, useGetDocTypeName } from 'src/reactives/docTypes.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { getDocKey, isCycleWithoutFileNorUrlSource } from 'src/utils/doc.util';
import { isInsight } from 'src/utils/docType.util';

type Props = {
  insightLink: InsightType;
  isSelected: boolean;
};

export const FeatureDocQuote = ({
  insightLink,
  isSelected,
}: Props) => {
  const { navigateToDocFullPage } = useNavigate();
  const product = useProductBase();

  const [isOptionFocus, {
    setTrueCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);

  const [isCoverUpdateLoading, {
    setTrueCallback: startCoverUpdate, setFalseCallback: stopCoverUpdate,
  }] = useOptimizedBooleanState(false);

  const {
    isClickOnEditForm,
    contextEl,
    contextUpdateEl,
    startEdit,
  } = useInsightCardEditQuoteProps({
    docTarget: insightLink,
  });

  const { canUpdateInsight } = useGetPermission();
  const insightLinkDocTypeName = useGetDocTypeName(insightLink?.doc?.doctype.id);
  const coverInputRef = useRef<HTMLInputElement | null>(null);

  const { updateDocCover } = useDocCoverMutations(insightLink?.doc?.id || '', {
    onUpdateStart: startCoverUpdate,
    onUpdateSuccess: stopCoverUpdate,
  });

  const { removeDocCover } = useDocCoverMutations(insightLink?.doc?.id || '');
  const { toggleSelectDoc } = useDocsSelection();
  const { selected } = useGetSelection();
  const setCoverInputClick = () => coverInputRef.current?.click();
  const getZoomableProps = useZoomableProps();

  if (!insightLink?.doc) return null;

  const {
    blockId, doc, content,
  } = insightLink;

  const quoteContent = doc.docSource?.content || content || doc.title;
  const hasProperties = !!nodeToArray(doc.attributes).length;
  const isParentWithSource = !!doc.docSource?.doc;
  const docType = getDocType(doc.doctype.id);
  const isDisabled = !doc || !(isParentWithSource || !isInsight(docType));

  return (
    <FeatureQuoteCard
      key={doc.id}
      coverUrl={doc.cover?.url}
      coverProps={getZoomableProps({
        src: insightLink?.doc?.cover?.url,
      })}
      title={doc.title}
      context={contextEl}
      contextText={quoteContent}
      isSelected={isSelected}
      hasSelection={!!selected.length}
      hasSmallMaxHeight
      blockId={blockId}
      isCoverLoading={isCoverUpdateLoading}
      onCoverRemove={removeDocCover}
      onCoverUpdate={setCoverInputClick}
      $isDisabled={isDisabled}
      onClick={(e) => {
        if (!doc) return;
        if (selected.length) {
          toggleSelectDoc(doc.id);
          return;
        }

        // We do not want to redirect if user is clicking inside the editing quote area
        if (isClickOnEditForm(e)) return;

        if (import.meta.env.VITE_QUOTE_PAGE === 'on') {
          openQuoteModal(doc.id, null, e.metaKey);
          return;
        }

        // If the link have a source -> Go to the source
        // Most of the time coming from insight parent (initiative for instance) and going to a feedback
        if (!!doc.docSource?.doc) {
          navigateToDocFullPage({
            title: doc.docSource.doc.title,
            id: doc.docSource.doc.id,
            // use hash as the user can open url in new tab with metaKey.
            hash: getHighlightHash({
              docId: doc.id,
              blockId,
            }),
          }, {}, e.metaKey);
          return;
        }

        // We don't want to open an insight from the insight list
        if (isInsight(docType)) return;

        navigateToDocFullPage({
          title: doc.title,
          id: doc.id,
        }, {}, e.metaKey);
      }}
      onMouseEnter={() => {
        setDocItemHoverId(doc.id);
      }}
      onMouseLeave={() => {
        setDocItemHoverId(null);
      }}
      onContextCopied={context => copyToClipboard({
        text: context,
        notification: 'Text copied to clipboard!',
      })}
      onSelect={() => toggleSelectDoc(doc.id)}
      properties={(
        <div className="flex flex-wrap items-center gap-x-2 gap-y-3">
          {!!doc.docSource?.doc?.id && !isCycleWithoutFileNorUrlSource(doc.docSource.doc.source) && (
            <DocSource
              doctypeId={doc.doctype.id}
              docId={doc.docSource?.doc?.id}
              source={doc.docSource.doc.source}
              color="greyAlt"
            />
          )}
          {!!doc.status?.id && (
            <DocStatus
              docTypeId={doc.doctype.id}
              statusId={doc.status.id}
              isDisabled
              hideLabel
            />
          )}
          {hasProperties && (
            <DocTagProperties
              properties={nodeToArray(doc.attributes) as DocAttribute[]}
            />
          )}
        </div>
      )}
      options={(
        <div className="flex gap-2">
          <AiStateTag
            docId={doc.id}
            docTypeId={doc.doctype.id}
            aiState={doc.aiState}
          />
          {!!doc.assignee?.id && insightLinkDocTypeName && (
            <DocAssignee
              assignee={doc.assignee}
              showLabel={false}
              tooltipPlacement="top"
              isDisabled={!!insightLink.id}
              isRemovable={false}
              docId={doc.id}
              context="doc-item"
              docTypeName={insightLinkDocTypeName}
              docTypeType={DoctypeType.Insight}
            />
          )}
          <InsightCardOptions
            blockId={insightLink.blockId}
            doc={{
              ...doc,
              _docKey: getDocKey(product?.key, doc.publicId) || '',
            }}
            editParent
            isOpen={isOptionFocus}
            onOpen={setTrueCallback}
            onClose={setFalseCallback}
            onUpdateQuoteSelect={() => {
              if (canUpdateInsight) {
                startEdit();
              } else {
                setLimitationsModal({ action: 'INSIGHT_UPDATE' });
              }
            }}
            onAddCoverClicked={setCoverInputClick}
          />
          <CoverImageInputFile
            ref={coverInputRef}
            onCoverChanged={updateDocCover}
          />
        </div>
      )}
      contextFooter={!!doc.customer && (
        <CustomerContainer>
          <DocCompanyCustomer
            size="S"
            doc={doc}
            isDisabled
            showCompanyName
          >
            <QuoteCustomer customer={doc.customer} />
          </DocCompanyCustomer>
        </CustomerContainer>
      )}
      contextUpdate={contextUpdateEl}
    />
  );
};
