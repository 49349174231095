import { Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { ReleaseModal } from 'src/components/ReleaseModals';
import { navigateToRelease } from 'src/hooks/useNavigate';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPermission } from 'src/reactives/permission.reactive';

export const NewReleaseButton = () => {
  const collapsed = useSidebarCollapsed();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Tooltip
        content="New release"
        placement={collapsed ? 'right' : 'bottom'}
        withPortal
        withWrapper={false}
      >
        <button
          className={twJoin(
            'btn btn-tertiary btn-square',
            !collapsed && 'text-disabled hover:text-secondary',
          )}
          onClick={() => {
            if (getPermission().canCreateRelease) {
              setModalOpen(true);
            } else {
              setLimitationsModal({ action: 'RELEASE_UPDATE' });
            }
          }}
        >
          <AddIcon size={14} />
        </button>
      </Tooltip>

      {modalOpen && (
        <ReleaseModal
          title="New release"
          hide={() => setModalOpen(false)}
          onReleaseCreated={releaseId => {
            setModalOpen(false);
            navigateToRelease(releaseId);
          }}
        />
      )}
    </>
  );
};
