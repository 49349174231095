/**
 * We should use properties.util.tsx
 * `Attribute` is renamed to `Property`
 */

import {
  AddNewDocAttributeValue,
  AttributeDefinitionTypeInput,
  AttributeTextValue,
  ChangeDocAttributeValueMutationVariables,
  CustomAttributeDefinition,
  CustomAttributeDefinitionFragment,
  DocAttributeFragment,
  DocBaseFragment,
  Property,
  PropertyValueFragment,
  ScalarAttributes,
  SelectAttributes,
} from '@cycle-app/graphql-codegen';
import { PropertyInputType, SelectOption, Icon } from '@cycle-app/ui';
import {
  ArrowRightDownIcon,
  CalendarIcon,
  CheckIcon,
  ChecklistIcon,
  CommentOutlineIcon,
  EnvelopeIcon,
  GeneralOutlineIcon,
  IdIcon,
  ImageIcon,
  LetterAIcon,
  LinkIcon,
  ParentIcon,
  PhoneIcon,
  SingleSelectIcon,
  TextIcon,
  StatusIcon,
  QuoteIcon,
  LinearIcon,
  AiIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { ReactNode } from 'react';
import { isPresent } from 'ts-is-present';

import {
  DocAttributeType,
  CustomAttributeType,
  Attribute,
  UseAttributesFromDocReturn,
  GetCustomAttributesFormValueValueReturn,
} from 'src/types/attribute.types';
import type { CustomPropertyFormData } from 'src/types/property.types';

import { CompanyAttributeIcon } from './attributes.util.styles';
import { getCustomPropertyValue } from './properties.util';
import { assigneeLabel } from './users.util';


// TODO: remove reference to claps once backend is ready
export const ATTRIBUTE_ICON_MAPPING: Record<NonNullable<Property['__typename']>, ReactNode> = {
  ClapDefinition: null,
  DoctypeDefinition: <GeneralOutlineIcon />,
  CreatorDefinition: <Icon name="user" />,
  AssigneeDefinition: <Icon name="user" />,
  AttributeCheckboxDefinition: <CheckIcon />,
  AttributeDateDefinition: <CalendarIcon />,
  AttributeEmailDefinition: <EnvelopeIcon />,
  AttributeSingleSelectDefinition: <SingleSelectIcon />,
  AttributeMultiSelectDefinition: <ChecklistIcon />,
  AttributeNumberDefinition: <IdIcon />,
  AttributePhoneDefinition: <PhoneIcon />,
  AttributeTextDefinition: <LetterAIcon />,
  AttributeUrlDefinition: <LinkIcon />,
  CreatedatDefinition: <CalendarIcon />,
  CoverDefinition: <ImageIcon />,
  DocidDefinition: <IdIcon />,
  DoctitleDefinition: <TextIcon />,
  ParentDefinition: <ParentIcon />,
  ChildrenDefinition: <ParentIcon />,
  BuiltInCustomerDefinition: <Icon name="user" />,
  StatusDefinition: <StatusIcon />,
  BuiltInCompanyDefinition: <CompanyAttributeIcon />,
  BuiltInInsightDefinition: <QuoteIcon />,
  CommentDefinition: <CommentOutlineIcon />,
  SourceDefinition: <ArrowRightDownIcon />,
  BuiltInReleaseDefinition: <Icon name="release" />,
  LinearAutomationDefinition: <LinearIcon style={{ color: 'currentcolor' }} />,
  BuiltInAiStateDefinition: <AiIcon />,
  ProductAreaDefinition: <Icon name="tri-shapes" />,
  ProductAreaCategoryDefinition: <Icon name="tri-shapes" />,
};

export interface AttributeTypeData {
  docAttributeTypename: DocAttributeType;
  icon: ReactNode;
  label: string;
  input: PropertyInputType;
  typeInput: AttributeDefinitionTypeInput;
  inputValue: (value: string) => ChangeDocAttributeValueMutationVariables['value'];
  optimistic?: (value: string,
    id?: string,
    currentValues?: AttributeTextValue[],
    attributeDefinition?: CustomAttributeDefinitionFragment,
  ) => any; // @todo replace `any` by type generated from graphql-codegen
}

export const customAttributeTypeData: Record<CustomAttributeType, AttributeTypeData> = {
  AttributeCheckboxDefinition: {
    docAttributeTypename: 'DocAttributeCheckbox',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeCheckboxDefinition,
    label: 'Checkbox',
    input: 'checkbox',
    typeInput: { scalar: { type: ScalarAttributes.Boolean } },
    inputValue: (value) => ({ checkbox: value === 'checked' }),
    optimistic: (value) => ({
      checkboxValue: {
        __typename: 'AttributeCheckboxValue',
        value: value === 'checked',
      },
    }),
  },
  AttributeDateDefinition: {
    docAttributeTypename: 'DocAttributeDate',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeDateDefinition,
    label: 'Date',
    input: 'date',
    typeInput: { scalar: { type: ScalarAttributes.Date } },
    inputValue: (value) => ({ date: new Date(value).toISOString() }),
    optimistic: (value) => ({
      dateValue: {
        __typename: 'AttributeDateValue',
        value,
      },
    }),
  },
  AttributeEmailDefinition: {
    docAttributeTypename: 'DocAttributeEmail',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeEmailDefinition,
    label: 'Email',
    input: 'email',
    typeInput: { scalar: { type: ScalarAttributes.Email } },
    inputValue: (value) => ({ email: value }),
    optimistic: (value) => ({
      emailValue: {
        __typename: 'AttributeEmailValue',
        value,
      },
    }),
  },
  AttributeSingleSelectDefinition: {
    docAttributeTypename: 'DocAttributeSingleSelect',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeSingleSelectDefinition,
    label: 'Single select',
    input: 'select',
    inputValue: (value) => ({ select: value }),
    typeInput: {
      select: {
        type: SelectAttributes.SingleSelect,
        values: [],
      },
    },
    optimistic: (id, text) => ({
      selectValue: {
        __typename: 'AttributeTextValue',
        id,
        value: text ?? '',
      },
    }),
  },
  AttributeMultiSelectDefinition: {
    docAttributeTypename: 'DocAttributeMultiSelect',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeMultiSelectDefinition,
    label: 'Multi select',
    input: 'select',
    typeInput: {
      select: {
        type: SelectAttributes.MultiSelect,
        values: [],
      },
    },
    inputValue: (value) => ({ select: value }),
    optimistic: (id, text, currentValues) => ({
      selectValues: (currentValues || []).concat({
        __typename: 'AttributeTextValue',
        id,
        value: text ?? '',
      }),
    }),
    // TODO: Use this optimistic function instead once the api fixes the value order
    // optimistic: (id, _, currentValues, attributeDefinition) => {
    //   if (!currentValues || attributeDefinition?.__typename !== 'AttributeMultiSelectDefinition') return null;
    //   const currentValuesIds = currentValues.map(v => v.id).concat(id);

    //   return ({
    //     selectValues: attributeDefinition.values.edges
    //       .filter(a => currentValuesIds.includes(a.node.id))
    //       .map(a => ({
    //         __typename: 'AttributeTextValue',
    //         id: a.node.id,
    //         value: a.node.value,
    //       })),
    //   });
    // },
  },
  AttributeNumberDefinition: {
    docAttributeTypename: 'DocAttributeNumber',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeNumberDefinition,
    label: 'Number',
    input: 'number',
    typeInput: { scalar: { type: ScalarAttributes.Number } },
    inputValue: (value) => ({ number: Number(value) }),
    optimistic: (value) => ({
      numberValue: {
        __typename: 'AttributeNumberValue',
        value: Number(value),
      },
    }),
  },
  AttributePhoneDefinition: {
    docAttributeTypename: 'DocAttributePhone',
    icon: ATTRIBUTE_ICON_MAPPING.AttributePhoneDefinition,
    label: 'Phone',
    input: 'phone',
    typeInput: { scalar: { type: ScalarAttributes.Phone } },
    inputValue: (value) => ({ phone: value }),
    optimistic: (value) => ({
      phoneValue: {
        __typename: 'AttributePhoneValue',
        value,
      },
    }),
  },
  AttributeTextDefinition: {
    docAttributeTypename: 'DocAttributeText',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeTextDefinition,
    label: 'Text',
    input: 'text',
    typeInput: { scalar: { type: ScalarAttributes.Text } },
    inputValue: (value) => ({ text: value }),
    optimistic: (value) => ({
      textValue: {
        __typename: 'AttributeTextValue',
        value,
      },
    }),
  },
  AttributeUrlDefinition: {
    docAttributeTypename: 'DocAttributeUrl',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeUrlDefinition,
    label: 'Url',
    input: 'url',
    typeInput: { scalar: { type: ScalarAttributes.Url } },
    inputValue: (value) => ({ url: value }),
    optimistic: (value) => ({
      urlValue: {
        __typename: 'AttributeUrlValue',
        value,
      },
    }),
  },
};

export function getCustomAttributeTypeData(type: CustomAttributeType): AttributeTypeData {
  return customAttributeTypeData[type];
}

export type AttributeValue = number | string | string[] | undefined | null;
export const getDocAttributeValue = (docAttribute: DocAttributeFragment): AttributeValue => {
  if (docAttribute.__typename === 'DocAttributeCheckbox') {
    return docAttribute.checkboxValue?.value != null ? docAttribute.definition.name : null;
  }
  if (docAttribute.__typename === 'DocAttributeDate') {
    return docAttribute.dateValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeEmail') {
    return docAttribute.emailValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeSingleSelect') {
    return docAttribute.selectValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeMultiSelect') {
    return docAttribute.selectValues?.map(({ value }) => value);
  }
  if (docAttribute.__typename === 'DocAttributeNumber') {
    return docAttribute.numberValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributePhone') {
    return docAttribute.phoneValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeText') {
    return docAttribute.textValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeUrl') {
    return docAttribute.urlValue?.value;
  }

  return null;
};

export const getDocAttributeInput = (attribute: DocAttributeFragment): AddNewDocAttributeValue | null => {
  const definitionId = {
    attributeDefinitionId: attribute.definition.id,
  };
  if (attribute.__typename === 'DocAttributeCheckbox') {
    return {
      ...definitionId,
      value: {
        checkbox: !!attribute.checkboxValue?.value,
      },
    };
  }
  if (attribute.__typename === 'DocAttributeSingleSelect') {
    return attribute.selectValue?.id ? {
      ...definitionId,
      value: {
        select: attribute.selectValue.id,
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributeDate') {
    return attribute.dateValue?.value ? {
      ...definitionId,
      value: {
        date: new Date(attribute.dateValue.value).toISOString(),
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributeEmail') {
    return attribute.emailValue?.value ? {
      ...definitionId,
      value: {
        email: attribute.emailValue.value,
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributeNumber') {
    return typeof attribute.numberValue?.value !== 'undefined' ? {
      ...definitionId,
      value: {
        number: attribute.numberValue.value,
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributePhone') {
    return typeof attribute.phoneValue?.value !== 'undefined' ? {
      ...definitionId,
      value: {
        phone: attribute.phoneValue.value,
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributeText') {
    return typeof attribute.textValue?.value !== 'undefined' ? {
      ...definitionId,
      value: {
        text: attribute.textValue.value,
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributeUrl') {
    return typeof attribute.urlValue?.value !== 'undefined' ? {
      ...definitionId,
      value: {
        url: attribute.urlValue.value,
      },
    } : null;
  }
  if (attribute.__typename === 'DocAttributeMultiSelect') {
    return attribute.selectValues ? {
      ...definitionId,
      value: {
        selects: attribute.selectValues.map(v => v.id),
      },
    } : null;
  }
  return null;
};

export const getDocAttributeCount = (attributes: DocAttributeFragment[]) => {
  return attributes.reduce((count, attribute) => {
    const input = getDocAttributeInput(attribute);
    if (input?.value) {
      if ('checkbox' in input.value) return input.value.checkbox ? count + 1 : count;
      return count + 1;
    }
    return count;
  }, 0);
};

export const getDocAttributeId = (docAttribute: DocAttributeFragment): string | undefined | null => {
  if (docAttribute.__typename === 'DocAttributeSingleSelect') {
    return docAttribute.selectValue?.id;
  }
  return null;
};

export const getPropertyValue = (property: PropertyValueFragment): number | string | string[] | boolean | undefined | null => {
  if (property.__typename === 'AttributeTextValue') {
    return property.text;
  }
  if (property.__typename === 'AttributeSelectValue') {
    return property.select;
  }
  if (property.__typename === 'AttributeCheckboxValue') {
    return property.checkbox;
  }
  if (property.__typename === 'AttributeDateValue') {
    return property.date;
  }
  if (property.__typename === 'AttributeEmailValue') {
    return property.email;
  }
  if (property.__typename === 'AttributeNumberValue') {
    return property.number;
  }
  if (property.__typename === 'AttributePhoneValue') {
    return property.phone;
  }
  if (property.__typename === 'AttributeUrlValue') {
    return property.url;
  }

  return null;
};

export const isAttribute = (propertyValue: PropertyValueFragment | null | undefined) => propertyValue?.__typename?.includes('Attribute');

export const getAttributeOptions = (attributeDefinition: CustomAttributeDefinitionFragment): SelectOption[] => {
  if (!('values' in attributeDefinition)) return [];

  return nodeToArray(attributeDefinition.values).map(v => ({
    label: v.value,
    value: v.id,
  }));
};

export function getAttributeName(attribute: Partial<Attribute>, isFeedback = false): string {
  switch (attribute.__typename) {
    case 'AttributeCheckboxDefinition':
    case 'AttributeDateDefinition':
    case 'AttributeEmailDefinition':
    case 'AttributeMultiSelectDefinition':
    case 'AttributeNumberDefinition':
    case 'AttributePhoneDefinition':
    case 'AttributeSingleSelectDefinition':
    case 'AttributeTextDefinition':
    case 'AttributeUrlDefinition':
    case 'ProductAreaDefinition':
      return attribute.name ?? '';

    case 'AssigneeDefinition': {
      return assigneeLabel({
        isFeedback,
        uppercase: true,
      });
    }

    case 'CreatorDefinition':
      return 'Creator';
    case 'DoctypeDefinition':
      return 'Feature type';
    case 'CoverDefinition':
      return 'Cover';
    case 'DoctitleDefinition':
      return 'Title';
    case 'DocidDefinition':
      return 'Feature ID';
    case 'CreatedatDefinition':
      return 'Creation date';
    case 'ParentDefinition':
      return 'Parent';
    case 'ChildrenDefinition':
      return 'Children';
    case 'BuiltInCustomerDefinition':
      return 'Customer';
    case 'SourceDefinition':
      return 'Source';
    case 'CommentDefinition':
      return 'Comments';
    case 'StatusDefinition':
      return 'Status';
    case 'BuiltInInsightDefinition':
      return 'Quotes';
    case 'BuiltInReleaseDefinition':
      return 'Release';
    case 'LinearAutomationDefinition':
      return 'Linear';
    case 'BuiltInAiStateDefinition':
      return 'AI tag';
    default:
      return '';
  }
}

const SCALAR_ATTRIBUTE_DEFINITIONS = [
  'AttributeTextDefinition',
  'AttributeDateDefinition',
  'AttributeEmailDefinition',
  'AttributeUrlDefinition',
  'AttributeNumberDefinition',
  'AttributePhoneDefinition',
];

export function isAttributeScalar(attribute: CustomAttributeDefinition | CustomAttributeDefinitionFragment): boolean {
  if (!attribute.__typename) return false;
  return SCALAR_ATTRIBUTE_DEFINITIONS.includes(attribute.__typename);
}

// TODO: to remove once the api has implemented attributes order
export interface AttributeIdsOrder {
  [attributeDefinitionId: string]: number;
}
export function getAttributeIdsOrder(attributeDefinitions: Array<{ id: string }>): AttributeIdsOrder {
  return attributeDefinitions.reduce(
    (result, { id }, index) => ({
      ...result,
      [id]: index,
    }),
    {},
  );
}

export function getCurrentSelectedValuesFromDocMulti(
  doc: DocBaseFragment,
  attributeDefinition: CustomAttributeDefinitionFragment,
) {
  if (attributeDefinition.__typename !== 'AttributeMultiSelectDefinition') return [];

  const docAttribute = doc.attributes.edges.find(e => e.node.definition.id === attributeDefinition.id);
  if (!docAttribute || docAttribute.node.__typename !== 'DocAttributeMultiSelect') return [];

  return docAttribute.node.selectValues || [];
}

export const getCustomAttributesFormValueValue = (
  attributeValue: UseAttributesFromDocReturn['value'],
  attributeDefinition: CustomAttributeDefinitionFragment,
): GetCustomAttributesFormValueValueReturn => {
  if ('checkbox' in attributeValue) return !!attributeValue.checkbox;
  if ('date' in attributeValue) return attributeValue.date;
  if ('email' in attributeValue) return attributeValue.email;
  if ('number' in attributeValue) return attributeValue.number;
  if ('phone' in attributeValue) return attributeValue.phone;
  if ('text' in attributeValue) return attributeValue.text;
  if ('url' in attributeValue) return attributeValue.url;

  if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition' && 'select' in attributeValue) {
    const attribute = attributeDefinition.values.edges.find(value => attributeValue.select === value.node.id)?.node;
    return attribute ? {
      label: attribute.value,
      value: attribute.id,
    } : undefined;
  }

  if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
    // TODO: take into account the multi select when it will be possible to create doc with that schema
    return undefined;
  }

  return undefined;
};

type GetInheritedAttributeFromDocParams = {
  targetAttributesDefinitions: CustomAttributeDefinitionFragment[];
  originAttributes: DocAttributeFragment[];
};

export const getInheritedAttributeFromDoc = ({
  targetAttributesDefinitions,
  originAttributes,
}: GetInheritedAttributeFromDocParams) => {
  const common: CustomPropertyFormData[] = [];
  const specific: CustomPropertyFormData[] = [];

  targetAttributesDefinitions.forEach(targetAttributesDefinition => {
    const correspondingDocProperty = originAttributes?.find(e => e.definition?.id === targetAttributesDefinition.id);

    if (correspondingDocProperty) {
      common.push({
        id: targetAttributesDefinition.id,
        definition: targetAttributesDefinition,
        inheritValue: getCustomPropertyValue(correspondingDocProperty),
      });
    } else {
      specific.push({
        id: targetAttributesDefinition.id,
        definition: targetAttributesDefinition,
        inheritValue: null,
      });
    }
  });

  return {
    common,
    specific,
  };
};

export const getSubmittableAttributeValues = (
  attributeFormData: CustomPropertyFormData[],
) => attributeFormData.map((attributeData) => {
  if (
    attributeData.definition.__typename === 'AttributeDateDefinition' &&
    typeof attributeData.inheritValue === 'string'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { date: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributeEmailDefinition' &&
    typeof attributeData.inheritValue === 'string'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { email: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributeNumberDefinition' &&
    typeof attributeData.inheritValue === 'number'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { number: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributePhoneDefinition' &&
    typeof attributeData.inheritValue === 'string'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { phone: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributeSingleSelectDefinition' &&
    typeof attributeData.inheritValue === 'string'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { select: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributeTextDefinition' &&
    typeof attributeData.inheritValue === 'string'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { text: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributeUrlDefinition' &&
    typeof attributeData.inheritValue === 'string'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { url: attributeData.inheritValue },
    };
  }
  if (
    attributeData.definition.__typename === 'AttributeCheckboxDefinition' &&
    typeof attributeData.inheritValue === 'boolean'
  ) {
    return {
      attributeDefinitionId: attributeData.definition.id,
      value: { checkbox: !!attributeData.inheritValue || false },
    };
  }

  return null;
}).filter(isPresent);

export const getIncompatibleAttributeOptionWarningMessage = (
  name: string,
) => `This will hide the ${name || 'doc'} from current view`;
