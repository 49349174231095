import { CycleLogo, Tag, Tooltip } from '@cycle-app/ui';
import { ExportIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { SourceIcon } from 'src/components/DocSource/DocSource';
import { getLabel } from 'src/components/DocSource/DocSource.utils';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { getLinkTo } from 'src/components/QuotePage/QuotePanelSource';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';

export const QuoteSource = ({ docId }: { docId: string }) => {
  const { doc } = useDocBase(docId);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const source = doc?.source;
  if (!source) return null;
  const linkTo = getLinkTo(source.url);

  return (
    <DropdownLayer
      layer={Layer.DropdownModalZ3}
      withPortal
      placement="bottom-start"
      visible={dropdownOpen}
      hide={() => setDropdownOpen(false)}
      content={(
        <div className="min-w-44">
          <div className="flex flex-col py-1">
            {linkTo ? (
              <Link
                className={SELECT_LINE_CLASSES}
                to={linkTo}
                autoFocus
              >
                <ExportIcon size={12} className="mx-px" />
                Open original
              </Link>
            ) : (
              <a
                className={SELECT_LINE_CLASSES}
                href={source.__typename === 'SourceMail' ? `mailto:${source.url}` : source.url}
                autoFocus
              >
                <ExportIcon size={12} className="mx-px" />
                Open original
              </a>
            )}
            
            <button
              className={SELECT_LINE_CLASSES}
              onClick={() => {
                copyToClipboard({
                  text: source.url,
                  notification: 'Link successfully copied to clipboard',
                });
              }}
            >
              <DuplicateIcon size={14} />
              Copy link
            </button>
          </div>
          
          <div className="select-text border-t border-grey-200 p-2 text-[13px] leading-5 text-secondary dark:border-grey-800">
            {getLabel(doc.source)}
            <br />
            Captured on {new Date(doc.createdAt).toLocaleDateString('en-us', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </div>
        </div>
      )}
    >
      <Tooltip
        content={getLabel(doc.source)}
        placement="top"
        withWrapper={false}
        withPortal
      >
        <Tag
          color="grey"
          data-active={dropdownOpen}
          tabIndex={0}
          role="button"
          onClick={e => {
            e.stopPropagation();
            setDropdownOpen(true);
          }}
          className="size-5"
          icon={(
            <SourceIcon
              source={source}
              size={12}
              defaultIcon={<CycleLogo size={12} color="blue" />}
            />
          )}
        />
      </Tooltip>
    </DropdownLayer>
  );
};

// eslint-disable-next-line max-len
const SELECT_LINE_CLASSES = 'flex cursor-pointer items-center gap-2 px-3 py-0.5 text-body outline-none hover:bg-grey-100 focus:bg-grey-100 dark:hover:bg-grey-800 dark:focus:bg-grey-800';