import { AiIcon } from '@cycle-app/ui/icons';

import { useFeatureFlag } from 'src/hooks';
import { openAsk } from 'src/reactives/ask.reactive';

import { Container, Title, StyledNewBadge } from './HomeViewAsk.styles';

export const HomeViewAsk = () => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  if (!isAskEnabled) return null;
  return (
    <Container
      onClick={() => openAsk('cycle')}
    >
      <Title>
        <AiIcon size={18} hasGradient />
        Ask Cycle
      </Title>

      <StyledNewBadge />
    </Container>
  );
};
