import { Popover, PopoverProps } from '@cycle-app/ui';
import { AiIcon, CloseIcon } from '@cycle-app/ui/icons';
import { PropsWithChildren } from 'react';


import { ButtonsList, PopoverContainer, PopoverContent } from 'src/components/QuotePopover/QuotePopover.styles';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { useUpdateDocAiState } from 'src/hooks/doc/useUpdateDocAiState';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { Layer } from 'src/types/layers.types';

type Props = Omit<PopoverProps, 'content'> & {
  docId: string;
  onVerify?: () => void;
  onDiscard?: () => void;
};

export const AiStatePopover = ({
  docId, onVerify, onDiscard, children, ...props 
}: PropsWithChildren<Props>) => {
  const { removeDoc } = useRemoveDoc();
  const { updateDocAiState } = useUpdateDocAiState();
  const { canDeleteInsight } = useGetPermission();
  return (
    <Popover
      placement="top"
      controlled
      interactive
      withPortal
      withWrapper={false}
      zIndex={mappingZindex[Layer.DropdownModalZ3]}
      animation
      delay={[200, 0]}
      {...props}
      content={(
        <PopoverContainer>
          <PopoverContent>
            <ButtonsList>
              <button
                // eslint-disable-next-line max-len
                className="relative flex h-7 flex-none cursor-pointer items-center gap-1.5 px-2 py-0.5 text-caption font-medium outline-none hover:bg-grey-100 focus-visible:bg-blue-400/20 dark:hover:bg-grey-800"
                onClick={() => {
                  onVerify?.();
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  updateDocAiState(docId);
                }}
              >
                <AiIcon hasGradient size={14} />
                Verify
              </button>

              <button
                // eslint-disable-next-line max-len
                className="relative flex h-7 flex-none cursor-pointer items-center gap-1.5 px-2 py-0.5 text-caption font-medium outline-none hover:bg-grey-100 focus-visible:bg-blue-400/20 dark:hover:bg-grey-800"
                onClick={() => {
                  if (!canDeleteInsight) {
                    setLimitationsModal({ action: 'INSIGHT_DELETE' });
                    return;
                  }
                  onDiscard?.();
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  removeDoc(docId);
                }}
              >
                <CloseIcon size={10} />
                Discard
              </button>
            </ButtonsList>
          </PopoverContent>
        </PopoverContainer>
      )}
    >
      {children}
    </Popover>
  );
};
