import {
  NavigationSection, Input, InputComponents, NavigationItemContainer, typo, ShyScrollbarCss,
} from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import SidebarItemBoard from './SidebarBoardSections/SidebarBoardSection/SidebarItemBoard/SidebarItemBoard';

export const ActionsSection = styled(NavigationSection)`
  gap: 2px;

  > * {
    display: flex;
    min-width: 0;
  }

  ${NavigationItemContainer} {
    ${typo.body500}
  }
`;

type BoardsSectionProps = {
  $collapsed: boolean;
  $hasBorderTop: boolean;
  $hasBorderBottom: boolean;
  $grow?: boolean;
};

export const BoardsSection = styled.div <BoardsSectionProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  ${p => p.$grow && css`
    flex-grow: 1;
  `}

  ${p => p.$hasBorderTop && css`
    border-top-color: ${p.theme.colors.sidebar.separator};
  `}

  ${p => p.$hasBorderBottom && css`
    border-bottom-color: ${p.theme.colors.sidebar.separator};
  `}
`;

export const ScrollableSectionStyled = styled(NavigationSection) <BoardsSectionProps>`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${ShyScrollbarCss}
  border-top: 1px solid transparent;
  width: ${p => (p.$collapsed ? 'calc(100% + 32px)' : 'calc(100% + 16px)')};
  margin-left: ${p => (p.$collapsed ? '-16px' : '-8px')};
  padding: ${p => (p.$collapsed ? '8px 16px 0 16px' : '0 8px')};

  ${p => p.$hasBorderTop && css`
    border-top-color: ${p.theme.colors.sidebar.separator};
  `}
`;

export const BoardSectionInput = styled(Input)`
  & input {
    height: 34px;
    background: ${p => p.theme.colors.sidebar.newSectionInput.bg};
    border-color: ${p => p.theme.colors.sidebar.newSectionInput.border};
    color: ${p => p.theme.colors.sidebar.newSectionInput.color};
    border-radius: 6px;
  }

  ${InputComponents.ActionIcon} {
    top: 0;
    bottom: 0;
    
    svg {
      width: 7px;
      height: 7px;
    }

    &:hover, &:focus, &.force-focus {
      color: ${p => p.theme.colors.sidebar.newSectionInput.color};
    }
  }
`;

export const SidebarFooter = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SidebarItemStarredBoard = styled(SidebarItemBoard)<{ $isCollapsed: boolean }>`
  flex-grow: 0;
  ${p => p.$isCollapsed && css`
    width: 28px;
    height: 28px;
  `}
`;
