import { useQuery } from '@apollo/client';
import { BoardConfigFiltersDocument } from '@cycle-app/graphql-codegen';

import { extract } from '../../types/graphql.types';
import { isFilterDoctypePropertyRuleByInsight } from '../../utils/boardConfig/boardConfig.util';

export function useBoardConfigFilters(boardConfigId?: string | null) {
  const {
    data, loading,
  } = useQuery(BoardConfigFiltersDocument, {
    variables: {
      id: boardConfigId as string,
    },
    skip: !boardConfigId,
  });
  const boardConfig = extract('BoardConfig', data?.node);
  const insightDoctypeFilter = isFilterDoctypePropertyRuleByInsight(boardConfig?.filterProperties);
  const insightDocType = {
    isTheOnlyOne: insightDoctypeFilter?.isOnlyInsightSelected,
    isInBoard: insightDoctypeFilter?.isInsightSelected,
  };
  return {
    boardConfig,
    isLoading: loading,
    insightDocType,
  };
}
