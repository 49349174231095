import { forwardRef, ComponentProps, ReactNode } from 'react';

type Props = ComponentProps<'input'> & {
  label: ReactNode;
  support?: ReactNode;
};

export const ChangelogRadioInput = forwardRef<HTMLInputElement, Props>(({
  label,
  support,
  ...props
}, ref) => {

  return (
    <label
      // eslint-disable-next-line max-len
      className="-mx-2 flex items-center gap-2 rounded px-2 py-1 hover:bg-grey-100 has-[:focus-visible]:bg-grey-100 dark:hover:bg-grey-800 dark:has-[:focus-visible]:bg-grey-800"
    >
      <input
        ref={ref}
        type="radio"
        className="size-4 text-cycle accent-current focus:outline-none"
        {...props}
      />
      <div className="mr-auto">{label}</div>
      {support}
    </label>
  );
});