import { MateWithProductRoleFragment } from '@cycle-app/graphql-codegen';
import { Avatar } from '@cycle-app/ui';
import { FC } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { Body } from 'src/components/DialogModal/DialogModal.styles';
import useProductMembersMutations from 'src/hooks/api/mutations/useProductMembersMutations';
import { useProduct } from 'src/hooks/api/useProduct';

interface SettingsUserRemoveUserModalProps {
  user: MateWithProductRoleFragment;
  onClose: () => void;
}

const SettingsUserRemoveUserModal: FC<SettingsUserRemoveUserModalProps> = ({
  user, onClose,
}) => {
  const { product } = useProduct();
  const {
    removeUser,
    isRemoveUserLoading,
  } = useProductMembersMutations();

  if (!product) return null;

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <DialogModal
      confirmLabel="Remove"
      hide={onClose}
      loading={isRemoveUserLoading}
      onConfirm={onConfirmRemove}
      title="Remove user"
      width=""
      info={(
        <Body>
          <div className="flex h-16 items-center gap-2.5 rounded-md bg-grey-100 px-4 dark:bg-grey-850">
            <Avatar
              user={user}
              className="size-6"
            />
            <div>
              <div className="text-body font-medium">
                {`${user.firstName} ${user.lastName}`}
              </div>
              <div className="text-body font-medium text-secondary">
                {user.email}
              </div>
            </div>
          </div>
          
          <div className="mt-4">
            <span className="inline-flex flex-wrap items-center gap-1">
              Are you sure you want to remove
              <Avatar
                className="size-4 self-center"
                size={16}
                user={user}
              />
              <span className="font-medium">
                {fullName.trim() ? fullName : user.email}
              </span>
              ?
            </span>
            <br />
            <span className="inline-flex items-center gap-1">
              (S)he will lose access to
              {product.logo && (
                <img
                  className="size-4 self-center rounded-full object-contain"
                  src={product.logo.url}
                />
              )}
              <span className="font-medium">
                {product.name}
              </span>
            </span>
          </div>
        </Body>
      )}
    />
  );

  async function onConfirmRemove() {
    if (!isRemoveUserLoading && user.productRole) {
      await removeUser(user.id, user.productRole);
      onClose();
    }
  }
};

export default SettingsUserRemoveUserModal;
