import { Tooltip, Shortcut } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useCallback } from 'react';
import { twJoin } from 'tailwind-merge';

import { ShortcutBoard, shortcuts } from 'src/constants/shortcuts.constants';
import { useFeedbackDocType } from 'src/hooks';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { getBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { getBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { setCreateDoc, getCreateDoc } from 'src/reactives/createDoc.reactive';
import { Layer } from 'src/types/layers.types';

export const NewFeedbackButton = () => {
  const feedback = useFeedbackDocType();
  const collapsed = useSidebarCollapsed();

  const createDoc = useCallback(() => {
    if (!feedback?.id) return;
    setCreateDoc({
      modalVisible: true,
      doctypeId: feedback?.id,
    });
  }, [feedback?.id]);

  useHotkeyListener({
    callbacks: {
      [ShortcutBoard.CreateDoc]: () => {
        if (getBoardGroupState().hoverGroupId) return;
        createDoc();
      },
    },
    shortcuts: Object.values(ShortcutBoard),
    disableOnLayers: [
      Layer.DocPanel,
      Layer.Dropdown,
      Layer.Modal,
      Layer.ModalZ2,
      Layer.ModalZ3,
      Layer.DialogModal,
      Layer.MaskCommandbar,
    ],
    preventCallback: () => (
      getBoardNewDocPositionState().draftPosition !== null ||
      getCreateDoc().modalVisible
    ),
  });

  return (
    <Tooltip
      content={(
        <div className="flex items-center gap-2">
          New feedback
          <Shortcut keys={shortcuts[ShortcutBoard.CreateDoc]} />
        </div>
      )}
      placement={collapsed ? 'right' : 'bottom'}
      withPortal
      withWrapper={false}
    >
      <button
        className={twJoin(
          'btn btn-tertiary btn-square',
          !collapsed && 'text-disabled hover:text-secondary',
        )}
        onClick={createDoc}
      >
        <AddIcon size={14} />
      </button>
    </Tooltip>
  );
};
