import { Tooltip } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { ProductsMenu } from 'src/components/ProductsMenu/ProductsMenu';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';

export const SidebarHeaderProduct = () => {
  const product = useProductBase();
  const collapsed = useSidebarCollapsed();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <button
        ref={btnRef}
        onClick={() => setDropdownOpen(b => !b)}
        className={twJoin(
          'btn btn-tertiary h-7 max-w-full gap-2',
          collapsed && 'btn-square size-7',
          dropdownOpen && 'btn-hover',
        )}
      >
        {product?.logo && (
          <div
            className="size-5 flex-none rounded-[4px] bg-cover bg-center"
            style={{
              backgroundImage: `url(${product.logo.url})`,
            }}
          />
        )}

        {!collapsed && (
          <>
            <h1 className="select-none truncate text-body font-medium leading-tight text-primary">
              {product?.name}
            </h1>

            <DownIcon
              size={14} 
              direction={dropdownOpen  ? 'top' : 'bottom'}
            />
          </>
        )}
      </button>

      {collapsed && (
        <Tooltip
          content={product?.name}
          placement="right"
          withPortal
          reference={btnRef}
        />
      )}

      <DropdownLayer
        reference={btnRef}
        visible={dropdownOpen}
        hide={() => setDropdownOpen(false)}
        content={(
          <ProductsMenu
            hide={() => setDropdownOpen(false)}
          />
        )}
      />
    </>
  );
};