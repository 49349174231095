import { truncate, ActionButton, typo, ShyScrollbarCss, Input } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { BoardStarButton } from 'src/components/BoardHeader/BoardStarButton';
import BoardOptions from 'src/components/BoardOptions/BoardOptions';

export const Container = styled.div`
  ${ShyScrollbarCss}
  overflow-x: hidden;
  
  --border-color: ${p => (p.theme.isDark ? ColorTheme.Grey900 : ColorTheme.Grey200)};
  border-top: 1px solid var(--border-color);
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  >:first-child {
    flex: none;
  }
`;

export const View = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    flex: none;
  }
  span {
    ${truncate}
  }
`;

export const ViewTypeLabel = styled.div``;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  overflow: hidden;
  flex-shrink: 1;
  max-width: 100%;
`;

const actionCss = css<{
  forceFocus?: boolean;
  $visible?: boolean;
}>`
  height: 28px;
  min-width: 28px;
  padding: 6px;
  gap: 8px;
  color: ${p => p.theme.colors.text.disabled};

  svg {
    flex: none;
  }

  > span {
    ${truncate}
  }

  :hover, :focus {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey200)};
    color: ${p => p.theme.colors.text.secondary};
  }
  :active {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey300)};
    color: ${p => p.theme.colors.text.secondary};
  }

  opacity: 0;
  ${p => (p.forceFocus || p.$visible) && `
    opacity: 1;
    color: ${p.theme.colors.text.secondary};
  `}
`;

export const ViewAction = styled(ActionButton).attrs({ size: 'L' })`
  ${actionCss}
  ${typo.caption400}
  border-radius: 3px;
  padding: 0 4px;
  height: 18px;
  white-space: nowrap;
`;

export const StyledBoardOptions = styled(BoardOptions)`
  ${actionCss}
`;

export const StyledBoardStarButton = styled(BoardStarButton)`
  ${actionCss}
`;

export const TextButton = styled(ViewAction) <{ $visible: boolean }>`  
  justify-self: flex-start;
  max-width: 100%;
  height: 24px;
  ${typo.body400}

  color: ${p => p.theme.colors.text.disabled};
  ${p => p.$visible && `
    color: ${p.theme.colors.text.secondary};
  `}

  > span {
    ${truncate}
  }
`;

export const Total = styled.div`
  padding: 16px 24px;
  ${typo.caption400}
  color: ${p => p.theme.colors.text.disabled};
`;

export const Item = styled.div<{
  $isDragging?: boolean;
  $isOverlay?: boolean;
  $isSmall?: boolean;
  $isInbox?: boolean;
}>`
  height: 56px;
  padding: 0 24px;
  display: grid;
  border-bottom: 1px solid var(--border-color);
  grid-template-columns: ${p => (p.$isSmall
    ? 'minmax(250px, 1fr) minmax(0, 20px) minmax(0, 1.2fr) 30px'
    : 'minmax(300px, 1fr) minmax(0, 120px) minmax(0, 1.2fr) 65px')};
  align-items: center;
  gap: ${p => (p.$isSmall ? '8px' : '16px')};
  cursor: pointer;
  :hover, :focus, :focus-within {
    background: ${p => (p.theme.isDark ? darken(0.01, ColorTheme.Grey900) : ColorTheme.Grey100)};
  }
  :active {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey900 : ColorTheme.Grey200)};
  }

  ${p => p.$isInbox && `
  grid-template-columns: ${p.$isSmall
    ? 'minmax(250px, 1fr) minmax(0, 20px) minmax(0, 20px) minmax(0, 1.2fr) 30px'
    : 'minmax(300px, 1fr) minmax(0, 120px) minmax(0, 230px) minmax(0, 1.2fr) 65px'};    
  `}

  ${p => p.$isDragging && `
    &, :hover, :active {
      background: inherit;
    }
  `}

  ${p => p.$isOverlay && `
    &, :hover, :active {
      cursor: grabbing;
      background: ${p.theme.colors.background.primary};
      border: none;
      border-radius: 12px;
      box-shadow:
        0px 3px 11px 0px #0000001A,
        0px 1px 4px 1px #0000000D;
    }
  `}

  ${p => p.$isOverlay && p.theme.isDark && `
    &, :hover, :active {
      box-shadow: none;
      background: ${p.theme.colors.background.secondary};
    }
  `}

  ${p => !p.$isOverlay && `
    :hover ${ViewAction},
    :hover ${StyledBoardOptions},
    :hover ${StyledBoardStarButton},
    :hover ${TextButton},
    :focus ${ViewAction},
    :focus ${StyledBoardOptions},
    :focus ${StyledBoardStarButton},
    :focus ${TextButton} {
      opacity: 1;
    }
  `};

  ${p => p.$isSmall && `
    ${ViewTypeLabel} {
      display: none;
    }
  `};
`;

export const StyledInput = styled(Input)`
  input {
    height: 36px;
  }
`;
